import React from 'react';
import { Field } from 'formik';

import {TextInput, SubHeading} from '../../../../shared';

export const PhdForm3 = () => {
  const view = (
    <React.Fragment>
      <SubHeading heading="Post Graduation Details" />
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="mtech_university"
            component={TextInput}
            label="University"
            required={true}
            placeholder="UNIVERSITY"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="mtech_percentage"
            component={TextInput}
            label="Percentage"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="mtech_year"
            component={TextInput}
            label="Year"
            type="text"
            required={true}
            placeholder="YEAR"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="mtech_specialisation"
            component={TextInput}
            label="Specialisation"
            type="text"
            required={true}
            placeholder="SPECIALISATION"
          />
        </div>
      </div>
      <SubHeading heading="Graduation Details" />
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="btech_university"
            component={TextInput}
            label="University"
            type="text"
            required={true}
            placeholder="UNIVERSITY"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="btech_year"
            component={TextInput}
            label="Year"
            type="text"
            required={true}
            placeholder="YEAR"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="btech_percentage"
            component={TextInput}
            label="Percentage"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
      </div>
    </React.Fragment>
  );
  return view;
};
