/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import { LayoutSplashScreen, MaterialThemeProvider, Layout } from "../_metronic/layout";
import Routes from "./Routes";

import { GlobalContext } from "../global-context/GlobalContext";
import {SimpleModal} from "./pages/shared";

export default function App({ basename }) {
  const { error, info }  = useContext(GlobalContext);
  return (
    <React.Suspense fallback={<LayoutSplashScreen />}>
      {/* Override `basename` (e.g: `homepage` in `package.json`) */}
      <BrowserRouter basename={basename}>
        {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
        <MaterialThemeProvider>
              <Layout>
                  <Routes/>
                  {
                    (error || info) && <SimpleModal />
                  }
              </Layout>
        </MaterialThemeProvider>
      </BrowserRouter>
    </React.Suspense>
  );
}
