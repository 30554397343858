import React from 'react';
import { Field } from 'formik';

import {TextInput, SelectInput, DateInput, SubHeading} from '../../../../shared';

export const BtechLetForm5 = () => {

  const view = (
    <React.Fragment>
      <SubHeading heading="Secondary School Details" />
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="secondary_board"
            component={TextInput}
            label="BOARD"
            type="text"
            required={true}
            placeholder="BOARD"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="secondary_year"
            component={SelectInput}
            label="YEAR"
            required={true}
            options={["2000", "2001", "2002", "2003", "2004", "2005"]}
          />
        </div>

        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="secondary_regno"
            component={TextInput}
            label="REG NO"
            type="text"
            required={true}
            placeholder=" REG NO"
          />
        </div>
      </div>
      
      <hr/>
      <SubHeading heading="TC Details" />
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="diploma_college"
            component={TextInput}
            label="LAST COLLEGE"
            type="text"
            required={true}
            placeholder="LAST COLLEGE"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="diploma_tc_no"
            component={TextInput}
            label="TC NO"
            type="text"
            required={true}
            placeholder="TC NO"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="diploma_tc_date"
            component={DateInput}
            label="TC DATE"
            required={true}
          />
        </div>
      </div>
    </React.Fragment>
  );
  return view;
};