import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const ManageButtonDrop = () => {
  const view = (
    <div className="d-flex flex-column p-2 content-layout-btn-drop">
        <button type="button" className="btn btn-link">
          <span className="section-header-icon pr-1">
            <FontAwesomeIcon icon={faDownload} />
          </span>
          Download
        </button>
    </div>
  );
  return view;
};

export default ManageButtonDrop;
