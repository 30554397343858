import React from 'react';

import './CheckBoxInput.scss';

export const CheckBoxInput = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    disabled,
    ...props
    })=>{
    const view = <div className="input-container et-checkbox">
                    <input 
                       type="checkbox"
                       id={`input-${field.name}`}
                       className= {`checkbox ${touched[field.name] && errors[field.name]? 'border-red' : ''}`}
                       checked = {field.value}
                       disabled={disabled}
                       {...field}
                       {...props} />
                    {label && <label id={field.name}>{label}</label>}
                    {
                        touched[field.name] && errors[field.name] ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{errors[field.name]}</div>
                            </div>
                        ) : null
                    }
                </div>
    return view;
};