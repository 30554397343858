import React from "react";
import { Field } from "formik";

import {TextInput, SelectInput, SubHeading} from '../../../../shared';

export const MtechForm4 = () => {
  const view = (
    <React.Fragment>
      <SubHeading heading="Higher Secondary School Details" />
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="highersecondary_board"
            component={SelectInput}
            label="Board"
            options={[
              "HSE Kerala",
              "CBSE",
              "ICSE",
              "VHSE Kerala",
              "Tamil Nadu Board",
              "Cambridge University",
              "Calicut University",
              "Telangana State Board",
              "Maharshtra Board",
              "Andhra Pradesh Board",
              "Jammu & Kashmir Board",
            ]}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="highersecondary_regno"
            component={TextInput}
            label="Registration No"
            type="text"
            required={true}
            placeholder="REG NO"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="highersecondary_year"
            component={SelectInput}
            label="Year"
            options={["2001", "2002", "2003"]}
          />
        </div>
      </div>
    </React.Fragment>
  );
  return view;
};
