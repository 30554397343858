const globalReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "SET_LOADER":
      return {
        ...state,
        loader: action.payload,
      };
    case "SET_SPINNER":
        return {
          ...state,
          spinner: action.payload,
        };
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
      };
    case "SET_INFO":
        return {
          ...state,
          info: action.payload,
        };
    case "SET_SHARED_DATA":
      return {
        ...state,
        sharedData: action.payload,
      };
    default:
      return state;
  }
};
export default globalReducer;
