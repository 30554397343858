import React, { useContext, useState } from 'react';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { Switch, Route } from "react-router-dom";

import './AdSlip.scss';
import {
    ContentLayout,
    Table,
    UtilityService,
    ApiService,
  } from "../../shared";

import { RegContext } from "../RegContext";
import { GlobalContext } from "../../../../global-context/GlobalContext";
import AppConstants from "../../../AppContants";

const headerData = [
    {"text": "Reference No","sort": true, "filter": true, "filterKey": "ref_no", "filterValue": ""},
    {"text": "Admission No","sort": true,"filter": true, "filterKey": "admission_no", "filterValue": ""},
    {"text": "Full Name","sort": true,"filter": true, "filterKey": "first_name", "filterValue": ""},
    {"text": "Batch","sort": true,"filter": true, "filterKey": "batch_id", "filterValue": ""},
    {"text": "Status","sort": true,"filter": true, "filterKey": "status", "filterValue": ""},
    {"text": "Registration Time","sort": true,"filter": true, "filterKey": "create_time", "filterValue": ""}
];



const AdSlip = () => {
const { setStudent } = useContext(RegContext);
const { setError, setSpinner } = useContext(GlobalContext);

const initialTableData = {
    list: [],
    totalCount: 0,
    currentPage: 1,
    pageSize: 10
}
const [tableData, setTableData] = useState(initialTableData);
// fetch all student registers form backend based on query
const fetchRegisters = async (query) => {
    setSpinner(true);
    let data = await ApiService.getData("/registration/registers" + query);
    if (data.error) {
      setError({
        type: "Error",
        data: [data.message],
      });
    } else if (data && data.grid && data._meta) {
      // data = dummyData;
      tableData.list = UtilityService.concatProperties(data.grid, ['first_name', 'middle_name', 'last_name'], 'full_name');
      tableData.totalCount  =data._meta.totalCount;
      setTableData(tableData);
    }
    setSpinner(false);
};

/**
 * pagination event from table
 * @param query 
 */
const pageChange = (query)=>{
    fetchRegisters(query);
}
    const view = <div className="et-admission-slip card">
        <Switch>
            <Route exact path="">

            <ContentLayout
              headerText="Admission Slip"
              icon={faList}
            >
            {
                        tableData && tableData.list &&
                        <Table 
                            headerData = {headerData}
                            tableData = {tableData}
                            links = {AppConstants.registration.adslip.links}
                            linkUrlKey = {AppConstants.registration.adslip.linkUrlKey}
                            baseUrl = {AppConstants.registration.adslip.baseUrl}
                            keyList = {AppConstants.registration.adslip.keyList}
                            studentHandler = {(student)=>{
                                setStudent(student)
                            }}
                            pageHandler = {pageChange}
                            action={{ download: true }}
                        />
                    }
          </ContentLayout>
        </Route>
      </Switch>
    </div>;
    return view;
}
export default AdSlip;
