import React, { useEffect, useContext } from 'react';
import { Field, useFormikContext } from 'formik';

import { TextInput, CheckBoxInput, SubHeading, ApiService } from '../../../../shared';
import { GlobalContext } from '../../../../../../global-context/GlobalContext';

export const BtechLetForm6 = () => {
  const { values, setFieldValue } = useFormikContext();
  const { setError, setSpinner, } = useContext(GlobalContext);

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (values.sameaspresent) {
        setFieldValue("permanent_address_1", values.present_address_1);
        setFieldValue("permanent_address_2", values.present_address_2);
        setFieldValue("permanent_address_4", values.present_address_4);
        setFieldValue("permanent_address_5", values.present_address_5);
        setFieldValue("permanent_address_3", values.present_address_3);
        setFieldValue("state", values.present_state);
      }
    }, 1000);
    return () => {
      clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, values.sameaspresent]);

  // State & District fetching using present pincode
  useEffect(() => {
    const fetchApiData = async () => {

      if (values.present_address_5 &&
          values.present_address_5 !== "" &&
          values.present_address_5.toString().length === 6) {
        setSpinner(true);
        const data = await ApiService.getFromPublicUrl( + values.present_address_5);
        if (data.error) {
          setError({
            type: "Error",
            data: [data.message],
          });
        }
        else if (data && Array.isArray(data) && data[0] && data[0].Status === 'Success') {
          values.present_state = data[0].PostOffice[0].State;
          values.present_address_3 = data[0].PostOffice[0].District;
          setFieldValue("present_state", values.present_state, true);
          setFieldValue("present_address_3", values.present_address_3, true);

        }
        setSpinner(false);
      }
    }
    fetchApiData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.present_address_5]);

  // State & District fetching using permanent pincode
  useEffect(() => {
    const fetchApiData = async () => {

      if (values.permanent_address_5 && 
          values.permanent_address_5 !== " " &&
          values.permanent_address_5.toString().length === 6) {
        setSpinner(true);
        const data = await ApiService.getFromPublicUrl( + values.permanent_address_5);
        if (data.error) {
          setError({
            type: "Error",
            data: [data.message],
          });
        }
        else if (data && Array.isArray(data) && data[0] && data[0].Status === 'Success') {
          values.state = data[0].PostOffice[0].State;
          values.permanent_address_3 = data[0].PostOffice[0].District;
          setFieldValue("state", values.state, true);
          setFieldValue("permanent_address_3", values.permanent_address_3, true);

        }
        setSpinner(false);
      }
    }
    fetchApiData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.permanent_address_5]);

  const view = (
    <React.Fragment>
      <SubHeading heading="Contact Details" />
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <div className="form-sub-heading">Present Address</div>
          <Field
            name="present_address_1"
            component={TextInput}
            type="text"
            label="HOUSE NAME"
            required={true}
            placeholder="HOUSE NAME"
          />

          <Field name="present_address_2"
            component={TextInput}
            label="STREET"
            required={true}
            placeholder="STREET" />

          <Field
            name="present_address_4"
            component={TextInput}
            label="POST/STREET 2"
            required={true}
            placeholder="POST/STREET 2"
          />

          <Field name="present_address_5"
            type="number" component={TextInput}
            label="PIN"
            required={true}
            placeholder="PIN" />
          <Field
            name="present_address_3"
            component={TextInput}
            label="DISTRICT"
            placeholder="DISTRICT"
            required={true}

          />
          <Field name="present_state"
            component={TextInput}
            label="STATE"
            required={true}
            placeholder="STATE" />

        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <div className="form-sub-heading">
            <span>Permanent Address</span>
            <span>
              <Field name="sameaspresent"
                component={CheckBoxInput}
                label="Same as Present" />
            </span>
          </div>
          <Field
            name="permanent_address_1"
            component={TextInput}
            label="HOUSE NAME"
            type="text"
            required={true}
            placeholder="HOUSE NAME"
          />

          <Field name="permanent_address_2"
            component={TextInput}
            label="STREET"
            required={true}
            placeholder="STREET" />

          <Field
            name="permanent_address_4"
            component={TextInput}
            label="POST/STREET 2"
            required={true}
            placeholder="POST/STREET 2"
          />

          <Field name="permanent_address_5"
            component={TextInput}
            label="PIN"
            required={true}
            placeholder="PIN" />
          <Field
            name="permanent_address_3"
            component={TextInput}
            label="DISTRICT"
            required={true}
            placeholder="DISTRICT"
          />
          <Field
            name="state"
            component={TextInput}
            label="STATE"
            required={true}
            placeholder="STATE"
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <Field
            name="phone_no"
            component={TextInput}
            label="STUDENT PHONE NO"
            type="number"
            required={true}
            placeholder="STUDENT PHONE NO"
          />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <Field
            name="email"
            component={TextInput}
            label="EMAIL"
            type="email"
            required={true}
            placeholder="EMAIL"
          />
        </div>

        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <Field
            name="phone_home"
            component={TextInput}
            label="LAND PHONE"
            type="number"
            placeholder="0000-0000000"
          />
        </div>
      </div>
    </React.Fragment>
  );
  return view;
};

