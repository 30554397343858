import React, { useContext, useState } from 'react';
import { faList } from '@fortawesome/free-solid-svg-icons';

import './DocSubmit.scss';
import { ContentLayout, Table, ApiService, UtilityService } from '../../shared';
import { Switch, Route } from 'react-router-dom';
import StudentDocumentDetails from './StudentDocumentDetails';
import { RegContext } from '../RegContext';
import AppConstants from '../../../AppContants';
import { GlobalContext } from '../../../../global-context/GlobalContext';
import DocSubmitButtuonDrop from './DocSubmitButtonDrop';

const headerData = [
    { "text": "Reference No", "sort": true, "filter": true, "filterKey": "refference_no", "filterValue": "" },
    { "text": "Admission No", "sort": true, "filter": true, "filterKey": "admission_no", "filterValue": "" },
    { "text": "Full Name", "sort": true, "filter": true, "filterKey": "first_name", "filterValue": "" },
    { "text": "Course Opted", "sort": true, "filter": true, "filterKey": "course_name", "filterValue": "" },
    { "text": "Status", "sort": true, "filter": true, "filterKey": "status", "filterValue": "" },
    { "text": "Document Submission", "sort": true, "filter": true, "filterKey": "docSub", "filterValue": "" }
];
const DocSubmit = () => {
    const { setSpinner, setError } = useContext(GlobalContext);
    const initialTableData = {
        list: [],
        totalCount: 0,
        currentPage: 1,
        pageSize: 10
    }
    const [tableData, setTableData] = useState(initialTableData);
    // fetch all student registers form backend based on query
    const fetchRegisters = async (query) => {
        setSpinner(true);
        let data = await ApiService.getData("/registration/registers" + query);
        if (data.error) {
            setError({
                type: "Error",
                data: [data.message],
            });
        } else if (data && data.grid && data._meta) {
            // data = dummyData;
            tableData.list = UtilityService.concatProperties(data.grid, ['first_name', 'middle_name', 'last_name'], 'full_name');
            tableData.totalCount = data._meta.totalCount;
            setTableData(tableData);
        }
        setSpinner(false);
    };

    /**
    * pagination event from table
    * @param query 
    */
    const pageChange = (query) => {
        fetchRegisters(query);
    }
    const { setStudent } = useContext(RegContext);
    const view = <div className="et-doc-submit">
        <Switch>
            <Route exact path="/registration/document-submission">
                <ContentLayout
                    headerText="Document Submission"
                    icon={faList}
                    buttonDrop={<DocSubmitButtuonDrop />}
                >
                    {
                        tableData && tableData.list &&
                        <Table
                            headerData={headerData}
                            tableData={tableData}
                            links={AppConstants.registration.docSubmit.links}
                            linkUrlKey={AppConstants.registration.docSubmit.linkUrlKey}
                            baseUrl={AppConstants.registration.docSubmit.baseUrl}
                            keyList={AppConstants.registration.docSubmit.keyList}
                            studentHandler={(student) => {
                                setStudent(student)
                            }}
                            pageHandler={pageChange}
                        />
                    }
                </ContentLayout>
            </Route>
            <Route exact path="/registration/document-submission/:refNo">
                <StudentDocumentDetails />
            </Route>
        </Switch>
    </div>;
    return view;
}
export default DocSubmit;
