import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SVG from "react-inlinesvg";

import './ContentLayout.scss';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

export const ContentLayout = (props)=>{
    const [showBtns, setShowBtns] = useState(false);
    const view = <div className="et-content-layout card mb-2">
        <div className="card-header text-bold text-success d-flex justify-content-between">
            <div>
                <span className="section-header-icon">
                    <FontAwesomeIcon icon={props.icon} />
                </span>
                <span className="section-header-text">
                    {props.headerText}
                </span>
            </div>
            {
                props.buttonDrop && 
                <div className="btn-group-icon">
                    <span 
                        className="svg-icon svg-icon-primary"
                        onClick={()=>setShowBtns(!showBtns)}
                        >
                        <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/Layout/Layout-3d.svg"
                        )}
                        />
                    </span>
                    { showBtns && props.buttonDrop}
                </div>
            }
        </div>
        <div className="card-body">
            {props.children}
        </div>
    </div>;
    return view;
};