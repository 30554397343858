import React, { useContext, useState, useEffect } from "react";
import {
  ApiService,
  TextInput,
  Accordion,
  CheckBoxInput,
  SelectInput,
} from "../../../shared";
import { GlobalContext } from "../../../../../global-context/GlobalContext";
import { Formik, Form, Field, FieldArray, useFormikContext } from "formik";
import * as Yup from "yup";
import "./DocumentSettings.scss";

const initialValues = {
  id: null,
  course_type: "",
  documents: [
    {
      doc_name: "",
      doc_max_size: "",
      doc_min_size: "",
      mandatory: false,
      softcopy: false,
    },
  ],
};

const DocumentSettings = () => {
  const [selectedAccIndex, setSelectedAccIndex] = useState(0);
  const [mode, setMode] = useState("read");
  const { spinner, setError, setSpinner, setInfo } = useContext(
    GlobalContext
  );
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    fetchDocsData();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  // fetch saved documents data from backend
  const fetchDocsData = async () => {
    setSpinner(true);
    const data = await ApiService.getData("/registration/documents");
    if (data.error) {
      setError({
        type: "Error",
        data: [data.message],
      });
    } else if (data && Array.isArray(data)) {
      setCourses(data);
    }
    setSpinner(false);
  };

  const documentSchema = Yup.object().shape({
    id: Yup.number().nullable(true),
    course_type: Yup.string().required("Course Type is required"),
    documents: Yup.array().of(
      Yup.object().shape({
        doc_name: Yup.string().required("Document Name is required"),
        doc_max_size: Yup.number().required("Document Max Size is required"),
        doc_min_size: Yup.number().required("Document Min Size is required"),
        mandatory: Yup.boolean(),
        softcopy: Yup.boolean(),
      })
    ),
  });

  const onSubmit = async (values) => {
    setSpinner(true);
    const method = mode === "create" ? "postData" : "putData";
    const url =
      mode === "create"
        ? "/registration/documents"
        : "/registration/documents/" + values.id;
    const data = await ApiService[method](url, values);
    if (data.error) {
      setError({
        type: "Error",
        data: [data.message],
      });
    } else if (data) {
      setMode("read");
      fetchDocsData();
      setInfo({
        type: "Info",
        data: ["Document saved successfully"],
      });
    }
    setSpinner(false);
  };

  /**
   * on click of create course button
   */
  const createCourse = () => {
    courses.push(initialValues);
    setCourses(courses);
    setMode("create");
    setSelectedAccIndex(courses.length - 1);
  };

  /**
   * cancel edit/create
   * on click of delete from paratially filled course in read mode
   */
  const cancel = () => {
    if (mode === "create" || mode === "read") {
      courses.splice(courses.length - 1, 1);
      setCourses(courses);
      setSelectedAccIndex(null);
    }
    setMode("read");
  };

  const view = (
    <div className="et-registration-settings">
      <div className="pb-2 text-bold text-uppercase">Documents</div>
      <Formik
        initialValues={initialValues}
        validationSchema={documentSchema}
        onSubmit={onSubmit}
      >
        { ({resetForm})=>
          <Form>
          {courses.map((item, index) => {
            return (
              <Accordion
                key={index}
                title={item.course_type}
                expandHandler={(i) => {
                  setMode('read');
                  setSelectedAccIndex(i);
                }}
                expanded={index === selectedAccIndex}
                index={index}
              >
                {mode === "read" && (
                  <ReadOnlyDoc
                    item={item}
                    modeHandler={() => setMode("edit")}
                    deleteHandler = {()=>{
                      fetchDocsData();
                      setSelectedAccIndex(null)
                    }}
                    cancelHandler = {cancel}
                  />
                )}
                {(mode === "edit" || mode === "create") && (
                  <DocForm cancelHandler={cancel} />
                )}
              </Accordion>
            );
          })}
          <div className="pt-2">
            <button
              type="button"
              className="btn btn-primary"
              disabled = {mode === 'create' || spinner }
              onClick={()=>{
                resetForm({})
                createCourse()
              }}
            >
              Create Course
            </button>
          </div>
        </Form>

        }
        </Formik>
    </div>
  );
  return view;
};
export default DocumentSettings;
const ReadOnlyDoc = ({ item, modeHandler, deleteHandler, cancelHandler }) => {
  const { setValues } = useFormikContext();
  const { setInfo, setSpinner, setError } = useContext(GlobalContext);
  /**
   * 
   * @param course 
   */
  const editDocument = (course) => {
    setValues(course, true);
    modeHandler();
  };

  /**
   * on click of delete button
   * @param course 
   */
  const deleteCourse = async(course)=>{
    if(course.id && window.confirm("Are you sure that you want to delete --> " + course.course_type)){
      setSpinner(true);
      const data = await ApiService.deleteData('/registration/documents/' + course.id);
      if (data.error) {
        setError({
          type: "Error",
          data: [data.message],
        });
      } else if (data) {
        deleteHandler();
        setInfo({
          type: "Info",
          data: ["Board Deleted Successfully"]
        });
      }
      setSpinner(false);
    } else if(!course.id){
      cancelHandler();
    }
  }

  const view = (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Document Name</th>
            <th>Document Min Size(KB)</th>
            <th>Document Max Size(KB)</th>
            <th>Mandatory Document?</th>
            <th>Softcopy Needed?</th>
          </tr>
        </thead>
        <tbody>
          {item &&
            Array.isArray(item.documents) &&
            item.documents.map((document, index) => {
              return (
                <tr key={index}>
                  <td>{document.doc_name}</td>
                  <td>{document.doc_min_size}</td>
                  <td>{document.doc_max_size}</td>
                  <td className="">
                    <Field
                      name={`documents[${index}].mandatory`}
                      component={CheckBoxInput}
                      disabled={true}
                    />
                  </td>
                  <td className="">
                    <Field
                      name={`documents[${index}].softcopy`}
                      component={CheckBoxInput}
                      disabled={true}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="pt-2">
        <button
          type="button"
          className="btn btn-primary mr-2"
          onClick={() => editDocument(item)}>
          Edit
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => deleteCourse(item)}>
          Delete
        </button>
      </div>
    </div>
  );
  return view;
};
const DocForm = ({ cancelHandler }) => {
  const { values, errors } = useFormikContext();

  const save = () => {
    console.log(errors);
  };

  const view = (
    <>
      <div className="row">
        <div className="col-xs-12 col-sm-8 col-md-6 col-lg-4">
        <Field
          name="course_type"
          label="Course Type"
          component={SelectInput}
          required={true}
          options={["BTECH", "BTECH(Lateral Entry)", "MTECH", "PhD"]}
        />
      </div>
      </div>
      <FieldArray
        name="documents"
        render={(arrayHelpers) => (
          <>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Document Name</th>
                    <th>Document Min Size(KB)</th>
                    <th>Document Max Size(KB)</th>
                    <th>Mandatory Document?</th>
                    <th>Softcopy Needed?</th>
                    <th>Remove Document</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(values.documents) &&
                    values.documents.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <Field
                              name={`documents[${index}].doc_name`}
                              component={TextInput}
                              arrayItem={true}
                            />
                          </td>
                          <td>
                            <Field
                              name={`documents[${index}].doc_min_size`}
                              component={TextInput}
                              type="number"
                              arrayItem={true}
                            />
                          </td>
                          <td>
                            <Field
                              name={`documents[${index}].doc_max_size`}
                              component={TextInput}
                              type="number"
                              arrayItem={true}
                            />
                          </td>
                          <td className="">
                            <Field
                              name={`documents[${index}].mandatory`}
                              component={CheckBoxInput}
                            />
                          </td>
                          <td className="">
                            <Field
                              name={`documents[${index}].softcopy`}
                              component={CheckBoxInput}
                            />
                          </td>
                          <td>
                            <button
                              type="button"
                              disabled={values.documents.length === 1}
                              className="form-control btn btn-secondary"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              -
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-between">
              <div className="doc-plus">
                <button
                  type="button"
                  className="form-control btn btn-primary"
                  onClick={() =>
                    arrayHelpers.push({
                      doc_name: "",
                      doc_min_size: "",
                      doc_max_size: "",
                      mandatory: false,
                      softcopy: false,
                    })
                  }
                >
                  +
                </button>
              </div>
              <div className="doc-save-btns">
                <button
                  type="submit"
                  className="btn btn-success mr-2"
                  onClick={save}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={cancelHandler}
                >
                  Cancel
                </button>
              </div>
            </div>
          </>
        )}
      />
    </>
  );
  return view;
};
