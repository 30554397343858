import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';

import {TextInput, SelectInput, TextAreaInput, SubHeading} from '../../../../shared';

export const BtechRegForm7 = () => {
  const { values } = useFormikContext();
  const [hideGuardianFields, setHideGuardianFields] = useState(true);

  useEffect(() => {
    if (values.who_is_guardian === "Other") {
      setHideGuardianFields(false);
    } else {
      setHideGuardianFields(true);
    }
  }, [values.who_is_guardian]);

  let guardianFields = "";
  if (!hideGuardianFields) {
    guardianFields = (
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
        <Field name="guardian_name"
                       component={TextInput}
                       label="NAME OF GUARDIAN"
                       type="text" 
                       required={true}
                       placeholder = "NAME OF GUARDIAN" />
                       

            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <Field name="guardian_relation"
                       component={TextInput}
                       label="GUARDIAN RELATIONSHIP WITH STUDENT"
                       type="text" 
                       required={true}
                       placeholder = "GUARDIAN RELATIONSHIP WITH STUDENT" />
                       

            </div>

            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <Field name="phone_guardian"
                       component={TextInput}
                       label="GUARDIAN NO"
                       required={true}
                       type="number" 
                       placeholder="GUARDIAN PHONE NO" />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <Field name="guardian_address"
                       component={TextAreaInput}
                       label="GUARDIAN ADDRESS"
                       type="text" />
        </div>
      </div>
    );
  }

  const view = (
    <React.Fragment>
      <SubHeading heading="Parent Details" />
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="father_name"
            component={TextInput}
            label="FATHER'S NAME"
            type="text"
            required={true}
            placeholder="FATHER'S NAME"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="father_occupation"
            component={TextInput}
            label="FATHER'S OCCUPATION"
            type="text"
            required={true}
            placeholder="FATHER'S OCCUPATION"
          />
        </div>

        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="phone_father"
            component={TextInput}
            label="FATHER'S / GUARDIAN'S MOBILE NO "
            type="number"
            required={true}
            placeholder="FATHER'S / GUARDIAN'S MOBILE NO  "
          />
        </div>
      </div>
      <hr/>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="mother_name"
            component={TextInput}
            label="MOTHER'S NAME"
            type="text"
            required={true}
            placeholder="MOTHER'S NAME"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="mother_occupation"
            component={TextInput}
            label="MOTHER'S OCCUPATION"
            type="text"
            required={true}
            placeholder="MOTHER'S OCCUPATION"
          />
        </div>

        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="phone_mother"
            component={TextInput}
            label="MOTHER'S MOBILE NO "
            type="number"
            required={true}
            placeholder="MOTHER'S MOBILE NO "
          />
        </div>
      </div>
      <hr/>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="parent_email"
            component={TextInput}
            label="PARENT EMAIL"
            type="email"
            required={true}
            placeholder="PARENT EMAIL"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="phone_office"
            component={TextInput}
            label="PHONE OFFICE"
            type="number"
            required={true}
            placeholder="PHONE OFFICE"
          />
        </div>

        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="who_is_guardian"
            component={SelectInput}
            label="SELECT GUARDIAN"
            options={[{value: "Father", id: 0}, {value: "Mother", id: 1},{value: "Other", id: 2}]}
            optionKey="value"
          />
        </div>
     
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="annual_income"
            component={TextInput}
            label="ANNUAL INCOME"
            type="number"
            required={true}
            placeholder="ANNUAL INCOME"
          />
        </div>
      </div>
      {guardianFields}
    </React.Fragment>
  );
  return view;
};

