export const RegistrationService = {
    validateFileRequired: (value, location, student)=>{
        if(student && location.indexOf('edit-details') > -1){
            return true;
        } else if(value && value !== ''){
            return true;
        }
        return false;
    },
    validateFileSize: (value, location, fileSize, student)=>{
        if(student && location.indexOf('edit-details') > -1){
            return true;
        } else if(value && value.size <= fileSize){
            return true;
        }
        return false;
    },
    validateFileFormat: (value, location, fileFormats, student)=>{
        if(student && location.indexOf('edit-details') > -1){
            return true;
        } else if(value && fileFormats.includes(value.type)){
            return true;
        }
        return false;
    }
}