import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from "@fortawesome/free-solid-svg-icons";

import { toAbsoluteUrl } from '../../../../_helpers';

export function UserDrpDown ({user}){
    const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
    return (
        <>
            <div className="d-flex flex-column pt-5 pl-6 pr-6 pb-5 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}>
                <h4 className="d-flex flex-center rounded-top text-white" style={{color: "black"}}>
                    <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                    <span className="pl-3 pr-6">{user}</span>
                    <span className="btn btn-text btn-primary btn-sm font-weight-bold btn-font-md ml-2">
                     Logout
                    </span>
                </h4>
            </div>
            <div className="d-flex flex-column pt-6 pb-8 rounded-bottom header-drop-content">           
                <a target="_self" href="https://mea.etlab.in/user/todo">
                    <div className="pl-6 pr-6 pb-4">To Do</div>
                </a>
                <a target="_self" href="https://mea.etlab.in/user/changepassword">
                    <div className="pl-6 pr-6">Change Password</div>
                </a>
            </div>
        </>
    );
}