import React, { useContext, useState } from 'react';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { Switch, Route } from "react-router-dom";

import './VerifyAssign.scss';
import {ContentLayout, Table, ApiService, UtilityService} from '../../shared';
import { RegContext } from '../RegContext';
import { GlobalContext } from '../../../../global-context/GlobalContext';
import AppConstants from '../../../AppContants';

const headerData = [
    {"text": "Reference No","sort": true, "filter": true, "filterKey": "refference_no", "filterValue": ""},
    {"text": "Full Name","sort": true,"filter": true, "filterKey": "first_name", "filterValue": ""},
    {"text": "Course Opted","sort": true,"filter": true, "filterKey": "course_name", "filterValue": ""},
    {"text": "Status","sort": true,"filter": true, "filterKey": "status", "filterValue": ""}
];
const VerifyAssign = () => {
    const { setStudent } = useContext(RegContext);
    const { setSpinner, setError } = useContext(GlobalContext);
    const initialTableData = {
        list: [],
        totalCount: 0,
        currentPage: 1,
        pageSize: 10
    }
    const [tableData, setTableData] = useState(initialTableData);
    // fetch all student registers form backend based on query
    const fetchRegisters = async (query) => {
        setSpinner(true);
        const status = AppConstants.registration.status;
        const fixedQuery = "&status=" + status.studentJustRegistered + "&status=" + status.studentVerified;
        let data = await ApiService.getData("/registration/registers" + query + fixedQuery );
        if (data.error) {
          setError({
            type: "Error",
            data: [data.message],
          });
        } else if (data && data.grid && data._meta) {
          // data = dummyData;
          tableData.list = UtilityService.concatProperties(data.grid, ['first_name', 'middle_name', 'last_name'], 'full_name');
          tableData.totalCount  =data._meta.totalCount;
          setTableData(tableData);
        }
        setSpinner(false);
    };

    /**
     * pagination event from table
     * @param query 
     */
    const pageChange = (query)=>{
        fetchRegisters(query);
    }
    const view = <div className="et-verify-assign">
        <Switch>
            <Route exact path="/registration/verification">
                <ContentLayout 
                    headerText="Verification"
                    icon={faList}
                    >
                    {
                        tableData && tableData.list &&
                        <Table 
                            headerData = {headerData}
                            tableData = {tableData}
                            links = {AppConstants.registration.verify.links}
                            linkUrlKey = {AppConstants.registration.verify.linkUrlKey}
                            baseUrl = {AppConstants.registration.verify.baseUrl}
                            keyList = {AppConstants.registration.verify.keyList}
                            studentHandler = {(student)=>{
                                setStudent(student)
                            }}
                            pageHandler = {pageChange}
                        />
                    }
                </ContentLayout>
            </Route>
        </Switch>
    </div>;
    return view;
}
export default VerifyAssign;


