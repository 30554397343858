import React, { useEffect, useContext } from "react";
import { useFormikContext, Field } from "formik";

import "./BoardSettings.scss";
import Subjects from "./Subjects";
import { GlobalContext } from "../../../../../global-context/GlobalContext";
import { TextInput } from "../../../shared";

const BoardSubjects = ({cancelHandler}) => {
  const { values, errors, setValues } = useFormikContext();
  const { setError } = useContext(GlobalContext);
  
  useEffect(() => {
    if (values.course_option === "Plus Two Only") {
      if(values.plustwo_subjects && values.plustwo_subjects.length === 0){
        values.plustwo_subjects = [{
          subject_name: "",
          subject_max_mark: ""
        }];
      }
      values.plusone_subjects = [];
      setValues(values, false);
    } else if (values.course_option === "Plus One & Plus Two") {
      if(values.plustwo_subjects && values.plustwo_subjects.length === 0){
        values.plustwo_subjects = [{
          subject_name: "",
          subject_max_mark: ""
        }];
      }
      if(values.plusone_subjects && values.plusone_subjects.length === 0){
        values.plusone_subjects = [{
          subject_name: "",
          subject_max_mark: ""
        }];
      }
      setValues(values, true);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [values.course_option]);

  const save = () => {
    console.log(errors);
    if (Object.entries(errors).length === 0) {
      // props.registerHandler(values);
    } else {
      setError({
        type: "Error",
        data: ["Please enter all required fileds marked as red"],
      });
    }
  };
  
  if (values.course_option === "") {
    return <div>Please Select a Course Option to add subjects</div>;
  }

  const view = (
    <>
      <div className="board-subjects">
        <div className="row">
          {
              values.course_option === "Plus One & Plus Two" && 
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <Subjects
                  course="Plus One"
                  subjectArrayModel="plusone_subjects"
                  
                />
              </div>
          }
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <Subjects
              course="Plus Two"
              subjectArrayModel="plustwo_subjects"
            />
          </div>
        </div>
        <hr />
        <div className="row">
          {
              values.course_option === "Plus One & Plus Two" &&
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <Field
                    name="plusone_max_mark"
                    component={TextInput}
                    label="Plus One Total Maximum Mark"
                    placeholder="Grand Total of All Subjects"
                    required={true}
                    type="number" />
              </div>
            }
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <Field
                    name="plustwo_max_mark"
                    component={TextInput}
                    label="Plus Two Total Maximum Mark"
                    placeholder="Grand Total of All Subjects"
                    required={true}
                    type="number" />
            </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        {
          values &&
          Array.isArray(values.plustwo_subjects) &&
          values.plustwo_subjects.length > 0  && 
          <button type="submit" className="btn btn-success mr-2" onClick={save}>
            Save
          </button>
          
        }
        <button type="btn" className="btn btn-secondary" onClick={cancelHandler}>
            Cancel
          </button>
      </div>
    </>
  );
  return view;
};
export default BoardSubjects;
