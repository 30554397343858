import React from 'react';
import DatePicker from "react-datepicker";

export const DateInput = ({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        label,
        startDate,
        endDate,
        required,
        ...props
    })=>{
    const view = <div className="input-container">
                    {label && <label id={field.name}>{label}</label>}
                    {required && <span className="pl-1 asterisk">*</span>}
                    <DatePicker
                        className= {`form-control ${touched[field.name] && errors[field.name]? 'border-red' : ''}`}
                        minDate={startDate ? new Date(startDate): null}
                        maxDate={endDate ? new Date(endDate): null}
                        {...field}
                        {...props}
                        selected={(field.value && new Date(field.value)) || null}
                        onChange={d => {
                            if(d){
                                const day = d.getDate();
                                const month = d.getMonth() + 1;
                                const year = d.getFullYear();
                                //const date = month + '/' + day + '/' + year;
                                const date = year + '/' + month + '/' + day;
                                setFieldValue(field.name, date);
                            }
                        }}
                        showMonthDropdown
                        showYearDropdown
                    />
                    {errors[field.name] && touched[field.name] ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors[field.name]}</div>
                        </div>
                    ) : null}
                </div>
    return view;
};