import React, { useState, useRef, useEffect } from "react";
import Clock from 'react-live-clock';
import SVG from "react-inlinesvg";

import { toAbsoluteUrl } from "../../../_helpers";
import { NotifyDrpDown } from "./dropdown/NotifyDrpDown";
// import { ThemeDrpDown } from "./dropdown/ThemeDrpDown";
import { UserDrpDown } from "./dropdown/UserDrpDown";

export function Topbar() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdown, setDropdown] = useState(null);
  const node = useRef();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click 
    setShowDropdown(null);
  };
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const toggleDropdown = ()=>{
    setShowDropdown(!showDropdown);
  }

  let userDrp = '';
  let notifyDrp = '';
  // let themeDrp = '';
  if(showDropdown){
    if(dropdown === 'user'){
      userDrp =  <div className="et-header-drpdown">
              <UserDrpDown user="Admin" />
            </div>;
    }else if(dropdown === 'notify'){
      notifyDrp =  <div className="et-header-drpdown">
              <NotifyDrpDown />
            </div>;
    }// else if(dropdown === 'theme'){
    //   themeDrp =  <div className="et-header-drpdown">
    //           <ThemeDrpDown />
    //         </div>;
    // }
  }
  return (
    <div className="topbar">
      <div className="et-date-time d-flex align-items-center">
        <Clock format={'ddd, M MMM  YYYY, h:mm:ss A'} /> 
      </div>
      <div className="d-flex" ref={node}>
        <div className="topbar-item">
            <div className="btn btn-icon btn-clean  mr-1 pulse pulse-primary"
                id="kt_quick_notifications_toggle"
                onClick={()=>{
                  setDropdown('notify')
                  toggleDropdown();
                }}>
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}
                  />
                </span>
                <span className="pulse-ring"></span>
                <span className="pulse-ring" />
            </div>
            {notifyDrp}
        </div>
        {/* <div className="topbar-item">
            <div className="btn btn-icon btn-clean mr-1"
                id="theme-select-icon"
                onClick={()=>{
                  setDropdown('theme');
                  toggleDropdown();
                }}>
                  <span className="svg-icon svg-icon-xl svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Layout/Layout-4-blocks.svg"
                      )}
                    />
                  </span>
            </div>
            {themeDrp}
        </div> */}
        <div className="topbar-item">
            <div className="btn btn-icon w-auto btn-clean d-flex align-items-center px-2"
                id="user-name"
                onClick={()=>{
                  setDropdown('user');
                  toggleDropdown();
                }}>
                <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                  Hi,
                </span>
                <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                  Admin
                </span>
                <span className="symbol symbol-35 symbol-light-success">            
                  <span className="symbol-label font-size-h5 font-weight-bold">A</span>
                </span>
            </div>
            {userDrp}
        </div>
      </div>
    </div>
  );
}
