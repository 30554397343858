import React, { useContext, useEffect } from "react";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import "./BtechLet.scss";
import {
  ContentLayout,
  SwitchPage,
  UtilityService,
  ApiService,
} from "../../../shared";
import {
  BtechLetForm1,
  BtechLetForm2,
  BtechLetForm3,
  BtechLetForm4,
  BtechLetForm5,
  BtechLetForm6,
  BtechLetForm7,
  BtechLetForm8,
  BtechLetForm9,
  BtechLetForm10,
  BtechLetForm11,
} from "./btech-let-forms";
import AppConstants from "../../../../AppContants";
import { GlobalContext } from "../../../../../global-context/GlobalContext";
import { RegContext } from "../../RegContext";
import { useLocation, useParams } from "react-router-dom";

const pageList = UtilityService.getFormsList(11);
const initialValues = {
  first_name: "",
  middle_name: "",
  last_name: "",
  gender: "",
  blood_group: "unknow",
  pob: "",
  dob: "",
  mother_tongue: "MALAYALAM",
  nationality: "INDIAN",
  religion_id: "",
  caste_id: "",
  eligible_reservation_category: "General",
  adharno: "",
  eco_backward: "No",
  differently_abled: "No",
  reg_type: "BTECH(Lateral Entry)",
  academic_year: "",
  admission_type: "LET Admission",
  course_id: "",
  adm_type: "Regular",
  admission_category: "General",
  fee_conces_eligibility: "General(State Merit)",
  income_category: "APL",
  let_rank: "",
  let_no: "",
  community: "",
  nativity: "Keralite",
  qualification: "Plus Two",
  diploma_university: "",
  diploma_year: "",
  diploma_percentage: "",
  highersecondary_board: "HSE KERALA",
  highersecondary_year: "",
  highersecondary_regno: "",
  secondary_board: "",
  secondary_year: "",
  secondary_regno: "",
  diploma_tc_no: "",
  diploma_tc_date: "",
  diploma_college: "",
  present_address_1: "",
  present_address_2: "",
  present_address_4: "",
  present_address_5: "",
  present_address_3: "",
  present_state: "",
  permanent_address_1: "",
  permanent_address_2: "",
  permanent_address_4: "",
  permanent_address_5: "",
  permanent_address_3: "",
  state: "",
  phone_no: "",
  email: "",
  phone_home: "",
  personal_marks_1: "",
  personal_marks_2: "",
  bank_name: "",
  bank_branch: "",
  bank_ifsc_code: "",
  bank_accno: "",
  is_hostler: 0,
  father_name: "",
  father_occupation: "",
  phone_father: "",
  mother_name: "",
  mother_occupation: "",
  phone_mother: "",
  parent_email: "",
  phone_office: "",
  annual_income: "",
  guardian_name: "",
  who_is_guardian: 0,
  guardian_relation: "",
  phone_guardian: "",
  guardian_address: "",
  sameaspresent: false,
  sign: "",
  image: "",
  parent_sign: "",
  documents: [],
};
const FILE_SIZE = AppConstants.fileSize;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const BtechLet = () => {
  const { setLoader, setError, setInfo } = useContext(GlobalContext);
  const { student, setStudent } = useContext(RegContext);
  const location = useLocation();
  const { refNo } = useParams();
  useEffect(() => {
    const fetchStudent = async () => {
      setLoader(true);
      const data = await ApiService.getData(
        "/registration/registers?refference_no=" + refNo
      );
      if (data.error) {
        setError({
          type: "Error",
          data: [data.message],
        });
      } else if (
        data &&
        data.grid &&
        Array.isArray(data.grid) &&
        data.grid.length > 0
      ) {
        setStudent(data.grid[0]); // set reg context
      }
      setLoader(false);
    };
    if (location.pathname.indexOf("edit-details") > -1) {
      if (!student) {
        fetchStudent();
      }
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const registrationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    middle_name: Yup.string().required("Middle Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    gender: Yup.string().required("Gender is required"),
    religion_id: Yup.string().required("Religion is required"),
    mother_tongue: Yup.string(),
    blood_group: Yup.string(),
    caste_id: Yup.string().required("Caste is required"),
    eligible_reservation_category: Yup.string().required(
      "Eligible Reservation Category is required"
    ),
    eco_backward: Yup.string(),
    differently_abled: Yup.string(),
    reg_type: Yup.string(),
    academic_year: Yup.string().required("Academic Year is required"),
    admission_type: Yup.string().required("Admission Type is required"),
    adm_type: Yup.string().required("Type is required"),
    admission_category: Yup.string().required("Admission Category is required"),
    fee_conces_eligibility: Yup.string().required(
      "Fee Concession Eligibility is required"
    ),
    income_category: Yup.string().required("Income Category is required"),
    nativity: Yup.string().required("Nativity is required"),
    community: Yup.string().required("Community is required"),
    nationality: Yup.string().required("Nationality is required"),
    pob: Yup.string().required("Place of Birth is required"),
    adharno: Yup.string()
      .min(12, "Minimum 12 digits")
      .max(12, "Maximum 12 digits")
      .required("Aadhar No. is required"),
    dob: Yup.mixed().required("DOB is required"),
    let_rank: Yup.string()
      .min(1, "Minimum 1 digits")
      .max(7, "Maximum 7 digits")
      .required("Let Rank is required"),
    let_no: Yup.string()
      .min(1, "Minimum 1 digits")
      .max(7, "Maximum 7 digits")
      .required("Let Roll No is required"),
    qualification: Yup.string().required("Qualification is required"),
    course_id: Yup.string().required("Course Opted is required"),
    diploma_university: Yup.string().required("Diploma Board is required"),
    diploma_year: Yup.string().required("Diploma Year is required"),
    diploma_percentage: Yup.string()
      .min(2, "Minimum 2 digits")
      .max(5, "Maximum 5 digits")
      .required("Diploma Percentage is required"),
    highersecondary_board: Yup.string().required(
      "Higher Secondary Board is required"
    ),
    highersecondary_year: Yup.string().required(
      "Higher Secondary Year is required"
    ),
    secondary_year: Yup.string().required("Secondary Year is required"),
    is_hostler: Yup.number(),
    highersecondary_regno: Yup.string().required("Reg No is required"),
    secondary_board: Yup.string().required("Board is required"),
    secondary_regno: Yup.string().required("Reg No is required"),
    diploma_tc_no: Yup.string().required("Tc No is required"),
    diploma_college: Yup.string().required("Last College is required"),
    diploma_tc_date: Yup.mixed().required("Tc Date is required"),
    present_address_1: Yup.string().required("Present House Name is required"),
    present_address_2: Yup.string().required("Present Street is required"),
    present_address_3: Yup.string().required("Present District is required"),
    present_address_4: Yup.string().required("Present Post is required"),
    present_address_5: Yup.string()
      .min(6, "Minimum 6 digits")
      .max(6, "Maximum 6 digits")
      .required("Present Pin is required"),
    present_state: Yup.string().required("Present State is required"),
    permanent_address_1: Yup.string().required(
      "Permanent House Name is required"
    ),
    permanent_address_2: Yup.string().required("Permanent Street is required"),
    permanent_address_3: Yup.string().required(
      "Permanent District is required"
    ),
    permanent_address_4: Yup.string().required("Permanent Post is required"),
    permanent_address_5: Yup.string()
      .min(6, "Minimum 6 digits")
      .max(6, "Maximum 6 digits")
      .required("Permanent Pin is required"),
    state: Yup.string().required("Permanent State is required"),
    phone_no: Yup.string()
      .min(10, "Minimum 10 digits")
      .max(15, "Maximum 15 digits")
      .required("Phone No is required"),
    phone_home: Yup.string()
      .min(10, "Minimum 10 digits")
      .max(15, "Maximum 15 digits"),
    personal_marks_1: Yup.string().required("Personal Mark  is required"),
    personal_marks_2: Yup.string().required("Personal Mark is required"),
    bank_name: Yup.string().required("Bank Name is required"),
    bank_accno: Yup.string().required("Account No is required"),
    bank_branch: Yup.string().required("Branch is required"),
    bank_ifsc_code: Yup.string().required("IFSC Code is required"),
    father_name: Yup.string().required("Father's Name is required"),
    father_occupation: Yup.string().required("Father's Occupation is required"),
    phone_father: Yup.string()
      .min(10, "Minimum 10 digits")
      .max(15, "Maximum 15 digits")
      .required("Father's Phone is required"),
    mother_name: Yup.string().required("Mother's Name is required"),
    mother_occupation: Yup.string().required("Mother's Occupation is required"),
    phone_mother: Yup.string().required("Mother's Phone is required"),
    parent_email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Parent Email is required"),
    phone_office: Yup.string()
      .min(10, "Minimum 10 digits")
      .max(15, "Maximum 15 digits"),
    who_is_guardian: Yup.number().required("Guardian is required"),
    guardian_name: Yup.string().when("who_is_guardian", {
      is: "Other",
      then: Yup.string().required("Guardian Name is required"),
      otherwise: Yup.string().notRequired(),
    }),
    guardian_relation: Yup.string().when("who_is_guardian", {
      is: "Other",
      then: Yup.string().required("Guardian Relation is required"),
      otherwise: Yup.string().notRequired(),
    }),
    phone_guardian: Yup.string()
      .min(10, "Minimum 10 digits")
      .max(15, "Maximum 15 digits")
      .when("who_is_guardian", {
        is: "Other",
        then: Yup.string().required("Guardian Phone No is required"),
        otherwise: Yup.string().notRequired(),
      }),
    guardian_address: Yup.string().when("who_is_guardian", {
      is: "Other",
      then: Yup.string().required("Guardian Address is required"),
      otherwise: Yup.string().notRequired(),
    }),
    annual_income: Yup.string().required("Annual Income is required"),
    sameaspresent: Yup.boolean(),
    sign: Yup.mixed()
      .required("Student Signature is required")
      .test("fileSize", "File too large", (value) => {
        return value && value.size <= FILE_SIZE * 30;
      })
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    parent_sign: Yup.mixed()
      .required("Parent Signature is required")
      .test("fileSize", "File too large", (value) => {
        return value && value.size <= FILE_SIZE * 30;
      })
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    image: Yup.mixed()
      .required("Student Image is required")
      .test("fileSize", "File too large", (value) => {
        return value && value.size <= FILE_SIZE * 100;
      })
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    documents: Yup.array().of(
      Yup.object().shape({
        doc_name: Yup.string(),
        doc_min_size: Yup.number(),
        doc_max_size: Yup.number(),
        file: Yup.mixed()
          .required("Select Document")
          .test("fileSize", "File too large", (value) => {
            return value && value.size <= FILE_SIZE * 30;
          })
          .test(
            "fileFormat",
            "Unsupported Format",
            (value) => value && SUPPORTED_FORMATS.includes(value.type)
          ),
      })
    ),
  });
  const changeForm = (formId) => {
    const id = "btech-form-" + formId;
    let el = document.getElementById(id);
    if (el) {
      el.classList.add("show");
    }
    for (const page of pageList) {
      if (page.id !== formId) {
        el = document.getElementById("btech-form-" + page.id);
        if (el) {
          el.classList.remove("show");
        }
      }
    }
  };
  const onSubmit = async (values) => {
    const payload = new FormData();
    for (const key in values) {
      // remove properties which is not required for backend
      if (key !== "captcha" && key !== "sameaspresent") {
        if (Array.isArray(values[key])) {
          payload.append(
            "Registration[" + key + "]",
            JSON.stringify(values[key])
          );
        } else {
          payload.append("Registration[" + key + "]", values[key]);
        }
      }
    }
    setLoader(true);
    const data = await ApiService.postData("/registration/registers", payload);
    if (data.error) {
      setError({
        type: "Error",
        data: [data.message],
      });
    } else if (data) {
      setInfo({
        type: "Info",
        data: ["Student Data Saved Successfully"],
      });
    }
    setLoader(false);
  };
  const view = (
    <div className="et-new-reg-forms">
      <ContentLayout
        headerText={`BTECH Student Registration ${new Date().getFullYear()}`}
        icon={faUser}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={registrationSchema}
          onSubmit={onSubmit}
        >
          {(location.pathname.indexOf("btech-let") > -1 ||
            (location.pathname.indexOf("edit-details") > -1 && student)) && (
            <Form>
              <div id="btech-form-1" className="btech-form show">
                <BtechLetForm1 />
              </div>
              <div id="btech-form-2" className="btech-form">
                <BtechLetForm2 />
              </div>
              <div id="btech-form-3" className="btech-form">
                <BtechLetForm3 />
              </div>
              <div id="btech-form-4" className="btech-form">
                <BtechLetForm4 />
              </div>
              <div id="btech-form-5" className="btech-form">
                <BtechLetForm5 />
              </div>
              <div id="btech-form-6" className="btech-form">
                <BtechLetForm6 />
              </div>
              <div id="btech-form-7" className="btech-form">
                <BtechLetForm7 />
              </div>
              <div id="btech-form-8" className="btech-form">
                <BtechLetForm8 />
              </div>
              <div id="btech-form-9" className="btech-form">
                <BtechLetForm9 />
              </div>
              <div id="btech-form-10" className="btech-form">
                <BtechLetForm10 />
              </div>
              <div id="btech-form-11" className="btech-form">
                <BtechLetForm11 />
              </div>
              <SwitchPage
                pageList={pageList}
                switchHandler={changeForm}
                registerHandler={onSubmit}
              />
            </Form>
          )}
        </Formik>
      </ContentLayout>
    </div>
  );
  return view;
};
export default BtechLet;
