import React, { useContext, useState, useEffect } from "react";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { Switch, Route, useParams, useHistory } from "react-router-dom";

import "./Assign.scss";
import {
  ContentLayout,
  Table,
  DetailGrid,
  UtilityService,
  ApiService,
} from "../../shared";

import { StudentDetailsService } from "../student-details/StudentDetailsService";
import { RegContext } from "../RegContext";
import { GlobalContext } from "../../../../global-context/GlobalContext";
import AppConstants from "../../../AppContants";
import { Button } from "react-bootstrap";

const backendData = {
  refference_no: "19R2015",
  first_name: "DARSANA SABU SABU",
  ref_no: "19R2015",
  course_id: "Electronics & Communication Engineering",
  status: "Student Verified",
};

const headerData = [
  {
    text: "Reference No",
    sort: true,
    filter: true,
    filterKey: "ref_no",
    filterValue: "",
  },
  {
    text: "Full Name",
    sort: true,
    filter: true,
    filterKey: "first_name",
    filterValue: "",
  },
  {
    text: "Batch",
    sort: true,
    filter: true,
    filterKey: "batch",
    filterValue: "",
  },
  {
    text: "Status",
    sort: true,
    filter: true,
    filterKey: "status",
    filterValue: "",
  },
];

const Assign = () => {
  const { setStudent } = useContext(RegContext);
  const { setError, setInfo, setSpinner } = useContext(GlobalContext);
  const { refNo } = useParams();
  const history = useHistory();
  const initialTableData = {
    list: [],
    totalCount: 0,
    currentPage: 1,
    pageSize: 10,
  };
  const [selectedBatch, setSelectedBatch] = useState("");
  const [batches, setBatches] = useState([]);

  useEffect(() => {
    fetchBatches();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * on load fetch batch list for a studenty and show in dropdown
   */
  const fetchBatches = async () => {
    setSpinner(true);
    const data = await ApiService.getData(
      "/registration/registers/batch-list?refference_no=" + refNo
    );
    if (data.error) {
      setError({
        type: "Error",
        data: [data.message],
      });
    } else if (data) {
      setBatches(data);
    }
    setSpinner(false);
  };

  const [tableData, setTableData] = useState(initialTableData);
  // fetch all student registers form backend based on query
  const fetchRegisters = async (query) => {
    setSpinner(true);
    let data = await ApiService.getData("/registration/registers" + query);
    if (data.error) {
      setError({
        type: "Error",
        data: [data.message],
      });
    } else if (data && data.grid && data._meta) {
      // data = dummyData;
      tableData.list = UtilityService.concatProperties(
        data.grid,
        ["first_name", "middle_name", "last_name"],
        "full_name"
      );
      tableData.totalCount = data._meta.totalCount;
      setTableData(tableData);
    }
    setSpinner(false);
  };
  const selectBatch = (event) => {
    setSelectedBatch(event.target.value);
  };

  /**
   * on click of assign button
   */
  const assignBatch = async () => {
    if (selectedBatch === "") {
      setError({
        type: "Error",
        data: ["Select a batch first"],
      });
    } else {
      setSpinner(true);
      let data = await ApiService.getData(
        "/registration/registers/batch-assign?refference_no=" +
          refNo +
          "&batch_id=" +
          selectedBatch
      );
      if (data.error) {
        setError({
          type: "Error",
          data: [data.message],
        });
      } else if (data) {
        setInfo({
          type: "Info",
          data: ["Batch assigned"],
        });
        setStudent(data);
        history.push("/registration/"+refNo)
      }
      setSpinner(false);
    }
  };

  /**
   * pagination event from table
   * this will be called on page number, size, filter text, sort direction changes
   * @param query
   */
  const pageChange = (query) => {
    fetchRegisters(query);
  };
  const view = (
    <div className="et-assign">
      <Switch>
        <Route exact path="">
          <ContentLayout headerText="Assign Batch for 19R2015" icon={faList}>
            <DetailGrid
              data={UtilityService.getAccItems(
                backendData,
                StudentDetailsService.summaryMapping
              )}
            />

            <div className="row pt-6">
              <div className="col-xs-12 col-sm-8 col-md-6 col-lg-4 d-flex align-items-center">
                <span className="pr-2">Assign&nbsp;Batch</span>
                <select
                  className="form-control"
                  value={selectedBatch}
                  onChange={selectBatch}
                >
                  <option value="">Select Batch</option>
                  {batches.map((batch, i) => {
                    return (
                      <option key={i} value={batch.id}>
                        {batch.batch_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1">
                <Button type="button" onClick={assignBatch}>
                  Assign
                </Button>
              </div>
            </div>
          </ContentLayout>

          <ContentLayout
            headerText="Registrations of Electronics & Communication Engineering"
            icon={faList}
            //buttonDrop = {}
          >
            {tableData && tableData.list && (
              <Table
                headerData={headerData}
                tableData={tableData}
                links={AppConstants.registration.verify.links}
                linkUrlKey={AppConstants.registration.verify.linkUrlKey}
                baseUrl={AppConstants.registration.verify.baseUrl}
                keyList={AppConstants.registration.verify.keyList}
                studentHandler={(student) => {
                  setStudent(student);
                }}
                pageHandler={pageChange}
              />
            )}
          </ContentLayout>
        </Route>
      </Switch>
    </div>
  );
  return view;
};
export default Assign;
