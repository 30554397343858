
import Axios from "axios";
import AppConstants from "../../../AppContants";

/* eslint-disable  no-unused-vars */
const endPoint = AppConstants.endPoint;
const publicUrl = AppConstants.publicUrl;

export const ApiService = {
    getData: async(url)=>{
        const apiUrl = endPoint + url;
        try{
            const response = await Axios.get(apiUrl);
            return response.data;
        } catch(err){
            return {
                error: err,
                message: err && err.message ? "Fetch Failed: " + url + "-->" + err.message : "Load Failed: " + url
            }
        }
    },
    getFromPublicUrl: async(url)=>{
        const apiUrl = publicUrl + url;
        try{
            const response = await Axios.get(apiUrl);
            return response.data;
        } catch(err){
            return {
                error: err,
                message: err && err.message ? "Fetch Failed: " + url + "-->" + err.message : "Load Failed: " + url
            }
        }
    },
    postData: async(url, payload)=>{
        const apiUrl = endPoint + url;
        try{
            return await Axios.post(apiUrl, payload);
        } catch(err){
            return {
                error: err,
                message: err && err.message ? "Post Failed: " + url + "-->" + err.message : "Load Failed: " + url
            }
        }
    },
    putData: async(url, payload)=>{
        const apiUrl = endPoint + url;
        try{
            return await Axios.put(apiUrl, payload);
        } catch(err){
            return {
                error: err,
                message: err && err.message ? "Edit Failed: " + url + "-->" + err.message : "Load Failed: " + url
            }
        }
    },
    deleteData: async(url, payload)=>{
        const apiUrl = endPoint + url;
        try{
            return await Axios.delete(apiUrl, payload);
        } catch(err){
            return {
                error: err,
                message: err && err.message ? "Delete Failed: " + url + "-->" + err.message : "Load Failed: " + url
            }
        }
    }
}