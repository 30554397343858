import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useFormikContext, Field } from "formik";

import "./CropImage.scss";

// Setting a high pixel ratio avoids blurriness in the canvas crop preview.
const pixelRatio = 4;
// We resize the canvas down when saving on retina devices otherwise the image
// will be double or triple the preview size.
function getResizedCanvas(canvas, newWidth, newHeight) {
  const tmpCanvas = document.createElement("canvas");
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx = tmpCanvas.getContext("2d");
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );

  return tmpCanvas;
}

export const CropImage = ({
  name,
  label,
  required,
  note,
  height,
  width,
  fileName,
}) => {
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: "px",
    height: height,
    width: width,
    aspect: width / height,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const [preview, setPreview] = useState(false);
  const generateDownload = (previewCanvas, crop) => {
    if (!crop || !previewCanvas) {
      return;
    }

    const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);

    canvas.toBlob(
      (blob) => {
        const file = new File([blob], fileName, {
          type: "image/jpeg",
          lastModified: Date.now(),
        });
        setFieldValue(name, file, false);
        setFieldTouched(name, true, true);
        console.log(file);
      },
      "image/jpeg",
      0.75
    );
    togglePreview(false);
  };
  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (preview) {
      setFieldTouched(name, false, true);
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(values[name]);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [values[name]]);
  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);
  const togglePreview = (value) => {
    setPreview(value);
  };
  return (
    <div className="App">
      <div>
        <Field
          name={name}
          component={CropFileInput}
          label={label}
          required={required}
          note={note}
          handler={togglePreview}
        />
      </div>
      {preview && (
        <>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c) => {
                    c.width = width;
                    c.height = height;
                    setCrop(c);
                  }
                }
                onComplete={(c) => setCompletedCrop(c)} />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <canvas
                ref={previewCanvasRef}
                style={{
                  width: completedCrop?.width ?? 0,
                  height: completedCrop?.height ?? 0,
                }}
              />
            </div>
          </div>
          <button
            type="button"
            disabled={!completedCrop?.width || !completedCrop?.height}
            onClick={() =>
              generateDownload(previewCanvasRef.current, completedCrop)
            }
          >
            Save
          </button>
        </>
      )}
    </div>
  );
};

const CropFileInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  note,
  required,
  handler,
}) => {
  const node = useRef();
  const [toggleInput, setToggleInput] = useState(true);
  const [fileName, setFileName] = useState("");
  const onFileChange = (e) => {
    handler(true);
    setFieldValue(field.name, node.current.files[0], true);
    setFileName(node.current.files[0].name);
    setToggleInput(!toggleInput);
  };
  const view = (
    <div className="crop-input-container input-container">
      <div>
        {label && <label id={field.name}>{label}</label>}
        {required && <span className="pl-1 asterisk">*</span>}
      </div>
      {toggleInput && (
        <label className="crop-file-upload">
          <input
            type="file"
            ref={node}
            id={`input-${field.name}`}
            className={`form-control ${
              touched[field.name] && errors[field.name] ? "border-red" : ""
            }`}
            onChange={onFileChange}
          />
          Browse File -
          {!fileName || fileName === ""
            ? "No File Selected"
            : `Selected File - ${fileName}`}
        </label>
      )}
      {!toggleInput && (
        <label className="crop-file-upload">
          <input
            type="file"
            ref={node}
            id={`input-${field.name}`}
            className={`form-control ${
              touched[field.name] && errors[field.name] ? "border-red" : ""
            }`}
            onChange={onFileChange}
          />
          Browse File -
          {!fileName || fileName === ""
            ? "No File Selected"
            : `Selected File - ${fileName}`}
        </label>
      )}
      {touched[field.name] && errors[field.name] ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{errors[field.name]}</div>
        </div>
      ) : null}
      {note}
    </div>
  );
  return view;
};
