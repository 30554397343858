import React, { useEffect, useState } from "react";
import { Field, useFormikContext } from "formik";

import {TextInput, SelectInput, TextAreaInput, SubHeading} from '../../../../shared';

export const PhdForm8 = () => {
  const { values } = useFormikContext();
  const [hideGuardianFields, setHideGuardianFields] = useState(true);

  useEffect(() => {
    if (values.who_is_guardian === "Other") {
      setHideGuardianFields(false);
    } else {
      setHideGuardianFields(true);
    }
  }, [values.who_is_guardian]);

  let guardianFields = "";
  if (!hideGuardianFields) {
    guardianFields = (
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="guardian_name"
            component={TextInput}
            label="Name of Guardian"
            required={true}
            placeholder="NAME OF GUARDIAN"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="guardian_relation"
            component={TextInput}
            label="Guardian Relationship With Student"
            required={true}
            placeholder="GUARDIAN RELATIONSHIP WITH STUDENT"
          />
        </div>

        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="phone_guardian"
            component={TextInput}
            label="Guardian NO"
            type="text"
            required={true}
            placeholder="GUARDIAN NO"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="guardian_address"
            component={TextAreaInput}
            label="Guardian Address"
            placeholder="GUARDIAN ADDRESS"
          />
        </div>
      </div>
    );
  }

  const view = (
    <React.Fragment>
      <SubHeading heading="Parent Details" />
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="father_name"
            component={TextInput}
            label="Father's Name"
            required={true}
            placeholder="FATHER'S NAME"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="father_occupation"
            component={TextInput}
            label="Father's Occupation"
            required={true}
            placeholder="FATHER'S OCCUPATION"
          />
        </div>

        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="phone_father"
            component={TextInput}
            label="Father's \ Guardian's Mobile No "
            type="text"
            required={true}
            placeholder="FATHER'S\GUARDIANS NO"
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="mother_name"
            component={TextInput}
            label="Mother's Name"
            required={true}
            placeholder="MOTHER'S NAME"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="mother_occupation"
            component={TextInput}
            label="Mother's Occupation"
            required={true}
            placeholder="MOTHER'S OCCUPATION"
          />
        </div>

        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="phone_mother"
            component={TextInput}
            label="Mother's Mobile No "
            type="text"
            required={true}
            placeholder="MOTHER'S NO"
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="parent_email"
            component={TextInput}
            label="Parent Email"
            type="email"
            required={true}
            placeholder="PARENT EMAIL"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="phone_office"
            component={TextInput}
            label="Phone Office"
            required={true}
            type="text"
            placeholder="PHONE OFFICE"
          />
        </div>

        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="who_is_guardian"
            component={SelectInput}
            label="Select Guardian"
            options={[{value: "Father", id: 0}, {value: "Mother", id: 1},{value: "Other", id: 2}]}
            optionKey="value"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="annual_income"
            component={TextInput}
            label="Annual Income"
            required={true}
            type="text"
            placeholder="ANNUAL INCOME"
          />
        </div>
      </div>
      {guardianFields}
    </React.Fragment>
  );
  return view;
};
