import React, { useContext, useEffect } from "react";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import "./PhdAll.scss";
import {
  ContentLayout,
  SwitchPage,
  UtilityService,
  ApiService,
} from "../../../shared";
import {
  PhdForm1,
  PhdForm2,
  PhdForm3,
  PhdForm4,
  PhdForm5,
  PhdForm6,
  PhdForm7,
  PhdForm8,
  PhdForm9,
  PhdForm10,
  PhdForm11,
} from "./phd-forms";
import AppConstants from "../../../../AppContants";
import { GlobalContext } from "../../../../../global-context/GlobalContext";
import { RegContext } from "../../RegContext";
import { useLocation, useParams } from "react-router-dom";

const pageList = UtilityService.getFormsList(11);
const initialValues = {
  first_name: "",
  middle_name: "",
  last_name: "",
  gender: "",
  blood_group: "Unknown",
  pob: "",
  dob: "",
  mother_tongue: "MALAYALAM",
  nationality: "INDIAN",
  religion_id: "",
  caste_id: "",
  eligible_reservation_category: "General",
  adharno: "",
  eco_backward: "No",
  differently_abled: "No",
  academic_year: "",
  admission_type: "",
  course_id: "",
  adm_type: "Regular",
  admission_category: "General",
  fee_conces_eligibility: "General(State Merit)",
  income_category: "APL",
  community: "",
  nativity: "Keralite",
  image: "",
  sign: "",
  parent_sign: "",
  father_name: "",
  father_occupation: "",
  phone_father: "",
  mother_name: "",
  mother_occupation: "",
  phone_mother: "",
  parent_email: "",
  phone_office: "",
  annual_income: "",
  guardian_name: "",
  who_is_guardian: 0,
  guardian_relation: "",
  phone_guardian: "",
  guardian_address: "",
  personal_marks_1: "",
  personal_marks_2: "",
  bank_name: "",
  bank_branch: "",
  bank_ifsc_code: "",
  bank_accno: "",
  is_hostler: 0,
  present_address_1: "",
  present_address_2: "",
  present_address_4: "",
  present_address_5: "",
  present_address_3: "",
  present_state: "",
  permanent_address_1: "",
  permanent_address_2: "",
  permanent_address_4: "",
  permanent_address_5: "",
  permanent_address_3: "",
  state: "",
  phone_no: "",
  email: "",
  phone_home: "",
  secondary_board: "",
  secondary_regno: "",
  secondary_year: "",
  mtech_college: "",
  mtech_tc_no: "",
  mtech_tc_date: "",
  highersecondary_board: "",
  highersecondary_regno: "",
  highersecondary_year: "",
  mtech_university: "",
  mtech_percentage: "",
  mtech_year: "",
  mtech_specialisation: "",
  btech_university: "",
  btech_year: "",
  btech_percentage: "",
  reg_type: "PhD",
  btech_gate_rank: "",
  btech_gate_score: "",
  qualification: "Plus Two",
  differenty_abled: "No",
  documents: [],
};
const FILE_SIZE = AppConstants.fileSize;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const PhdAll = () => {
  const { setLoader, setError, setInfo } = useContext(GlobalContext);
  const { student, setStudent } = useContext(RegContext);
  const location = useLocation();
  const { refNo } = useParams();
  useEffect(() => {
    const fetchStudent = async () => {
      setLoader(true);
      const data = await ApiService.getData(
        "/registration/registers?refference_no=" + refNo
      );
      if (data.error) {
        setError({
          type: "Error",
          data: [data.message],
        });
      } else if (
        data &&
        data.grid &&
        Array.isArray(data.grid) &&
        data.grid.length > 0
      ) {
        setStudent(data.grid[0]); // set reg context
      }
      setLoader(false);
    };
    if (location.pathname.indexOf("edit-details") > -1) {
      if (!student) {
        fetchStudent();
      }
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const registrationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    middle_name: Yup.string().required("Middle Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    gender: Yup.string().required("Gender is required"),
    blood_group: Yup.string().required("Blood Group is required"),
    dob: Yup.mixed().required("DOB is required"),
    religion_id: Yup.mixed().required("Religion is required"),
    caste_id: Yup.mixed().required("Caste is required"),
    adharno: Yup.string()
      .min(12, "Minimum 12 digits")
      .max(12, "Maximum 12 digits")
      .required("Aadhar No. is required"),
    reg_type: Yup.mixed().required("Admission for is required"),
    income_category: Yup.mixed().required("Income Category is required"),
    course_id: Yup.string().required("Course Opted is required"),
    fee_conces_eligibility: Yup.mixed().required("Field is required"),
    community: Yup.mixed().required("Community is required"),
    admission_type: Yup.mixed().required("Quota is required"),
    academic_year: Yup.mixed().required("Academic Year is required"),
    btech_university: Yup.mixed().required("University is required"),
    btech_year: Yup.mixed().required("Year is required"),
    mtech_specialisation: Yup.mixed().required("Specialisation is required"),
    btech_percentage: Yup.mixed().required("Percentage is required"),
    secondary_board: Yup.mixed().required("Board is required"),
    secondary_regno: Yup.mixed().required("Reg No is required"),
    secondary_year: Yup.mixed().required("Year is required"),
    mtech_college: Yup.mixed().required("Last College is required"),
    mtech_tc_no: Yup.mixed().required("TC No is required"),
    mtech_tc_date: Yup.mixed().required("TC Date is required"),
    mtech_university: Yup.mixed().required("PG University is required"),
    mtech_percentage: Yup.mixed().required("PG Percentage is required"),
    mtech_year: Yup.mixed().required("PG Year is required"),
    is_hostler: Yup.mixed().required("Please choose an option"),
    btech_gate_rank: Yup.number().required("Rank is Required"),
    btech_gate_score: Yup.number().required("Rank is Required"),
    present_address_1: Yup.string().required("Present House Name is required"),
    present_address_2: Yup.string().required("Present Street is required"),
    present_address_3: Yup.string().required("Present Post is required"),
    present_address_4: Yup.string().required("Present District is required"),
    present_address_5: Yup.string()
      .min(6, "Minimum 6 digits")
      .max(6, "Maximum 6 digits")
      .required("Present Pin is required"),
    present_state: Yup.string().required("Present State is required"),
    permanent_address_1: Yup.string().required(
      "Permanent House Name is required"
    ),
    permanent_address_2: Yup.string().required("Permanent Street is required"),
    permanent_address_3: Yup.string().required("Permanent Post is required"),
    permanent_address_4: Yup.string().required(
      "Permanent District is required"
    ),
    permanent_address_5: Yup.string()
      .min(6, "Minimum 6 digits")
      .max(6, "Maximum 6 digits")
      .required("Pin is required"),
    state: Yup.string().required("Permanent State is required"),
    phone_no: Yup.string()
      .min(10, "Minimum 10 digits")
      .max(15, "Maximum 15 digits")
      .required("Phone No is required"),
    phone_home: Yup.string()
      .min(10, "Minimum 10 digits")
      .max(15, "Maximum 15 digits"),
    same_address: Yup.boolean(),
    personal_marks_1: Yup.string().required("Personal Mark  is required"),
    personal_marks_2: Yup.string().required("Personal Mark is required"),
    bank_name: Yup.string().required("Bank Name is required"),
    bank_accno: Yup.string().required("Account No is required"),
    bank_branch: Yup.string().required("Branch is required"),
    bank_ifsc_code: Yup.string().required("IFSC Code is required"),
    father_name: Yup.string().required("Father's Name is required"),
    father_occupation: Yup.string().required("Father's Occupation is required"),
    phone_father: Yup.string()
      .min(10, "Minimum 10 digits")
      .max(15, "Maximum 15 digits")
      .required("Father's Phone is required"),
    pob: Yup.string().required("Mother's Name is required"),
    mother_name: Yup.string().required("Mother's Name is required"),
    mother_tongue: Yup.string().required("Mother's Name is required"),
    mother_occupation: Yup.string().required("Mother's Occupation is required"),
    phone_mother: Yup.string()
      .min(10, "Minimum 10 digits")
      .max(15, "Maximum 15 digits")
      .required("Mother's Phone is required"),
    parent_email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    phone_office: Yup.string()
      .min(10, "Minimum 10 digits")
      .max(15, "Maximum 15 digits"),
    who_is_guardian: Yup.string().required("Guardian is required"),
    guardian_name: Yup.string().when("who_is_guardian", {
      is: "Other",
      then: Yup.string().required("Guardian Name is required"),
      otherwise: Yup.string().notRequired(),
    }),
    guardian_relation: Yup.string().when("who_is_guardian", {
      is: "Other",
      then: Yup.string().required("Guardian Relation is required"),
      otherwise: Yup.string().notRequired(),
    }),
    phone_guardian: Yup.string()
      .min(10, "Minimum 10 digits")
      .max(15, "Maximum 15 digits")
      .when("who_is_guardian", {
        is: "Other",
        then: Yup.string().required("Guardian Phone No is required"),
        otherwise: Yup.string().notRequired(),
      }),
    guardian_address: Yup.string().when("who_is_guardian", {
      is: "Other",
      then: Yup.string().required("Guardian Address is required"),
      otherwise: Yup.string().notRequired(),
    }),
    annual_income: Yup.string().required("Annual Income is required"),
    highersecondary_board: Yup.string().required("Board is required"),
    highersecondary_regno: Yup.string().required("Reg No is required"),
    highersecondary_year: Yup.string().required("Year is required"),
    image: Yup.mixed()
      .required("Photo is required")
      .test("fileSize", "File too large", (value) => {
        return value && value.size <= FILE_SIZE * 100;
      })
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    sign: Yup.mixed()
      .required("Student Signature is required")
      .test("fileSize", "File too large", (value) => {
        return value && value.size <= FILE_SIZE * 30;
      })
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    parent_sign: Yup.mixed()
      .required("Parent Signature is required")
      .test("fileSize", "File too large", (value) => {
        return value && value.size <= FILE_SIZE * 30;
      })
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    documents: Yup.array().of(
      Yup.object().shape({
        doc_name: Yup.string(),
        doc_min_size: Yup.number(),
        doc_max_size: Yup.number(),
        file: Yup.mixed()
          .required("Select Document")
          .test("fileSize", "File too large", (value) => {
            return value && value.size <= FILE_SIZE * 30;
          })
          .test(
            "fileFormat",
            "Unsupported Format",
            (value) => value && SUPPORTED_FORMATS.includes(value.type)
          ),
      })
    ),
  });
  const changeForm = (formId) => {
    const id = "phd-form-" + formId;
    let el = document.getElementById(id);
    if (el) {
      el.classList.add("show");
    }
    for (const page of pageList) {
      if (page.id !== formId) {
        el = document.getElementById("phd-form-" + page.id);
        if (el) {
          el.classList.remove("show");
        }
      }
    }
  };
  const onSubmit = async (values) => {
    const payload = new FormData();
    for (const key in values) {
      // remove properties which is not required for backend
      if (key !== "captcha" && key !== "sameaspresent") {
        if (Array.isArray(values[key])) {
          payload.append(
            "Registration[" + key + "]",
            JSON.stringify(values[key])
          );
        } else {
          payload.append("Registration[" + key + "]", values[key]);
        }
      }
    }
    setLoader(true);
    const data = await ApiService.postData("/registration/registers", payload);
    if (data.error) {
      setError({
        type: "Error",
        data: [data.message],
      });
    } else if (data) {
      setInfo({
        type: "Info",
        data: ["Student Data Saved Successfully"],
      });
    }
    setLoader(false);
  };
  const view = (
    <div className="et-new-reg-forms">
      <ContentLayout
        headerText={`PhD Student Registration ${new Date().getFullYear()}`}
        icon={faUser}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={registrationSchema}
          onSubmit={onSubmit}
        >
          {(location.pathname.indexOf("phD-all") > -1 ||
            (location.pathname.indexOf("edit-details") > -1 && student)) && (
            <Form>
              <div id="phd-form-1" className="phd-form show">
                <PhdForm1 />
              </div>
              <div id="phd-form-2" className="phd-form">
                <PhdForm2 />
              </div>
              <div id="phd-form-3" className="phd-form">
                <PhdForm3 />
              </div>
              <div id="phd-form-4" className="phd-form">
                <PhdForm4 />
              </div>
              <div id="phd-form-5" className="phd-form">
                <PhdForm5 />
              </div>
              <div id="phd-form-6" className="phd-form">
                <PhdForm6 />
              </div>
              <div id="phd-form-7" className="phd-form">
                <PhdForm7 />
              </div>
              <div id="phd-form-8" className="phd-form">
                <PhdForm8 />
              </div>
              <div id="phd-form-9" className="phd-form">
                <PhdForm9 />
              </div>
              <div id="phd-form-10" className="phd-form">
                <PhdForm10 />
              </div>
              <div id="phd-form-11" className="phd-form">
                <PhdForm11 />
              </div>
              <SwitchPage
                pageList={pageList}
                switchHandler={changeForm}
                registerHandler={onSubmit}
              />
            </Form>
          )}
        </Formik>
      </ContentLayout>
    </div>
  );
  return view;
};
export default PhdAll;
