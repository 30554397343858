import React from 'react';

import './SubHeading.scss';

export const SubHeading = ({heading, note})=>{
    const view = <div className="row">
                    <div className="col-lg-12">
                        <div className="et-sub-heading" id={heading.replace(/ /g,'')}>
                            {heading}
                            {note && <span>({note})</span>}
                        </div>
                    </div>
                </div>;
    return view;
}