import React from 'react';

import './TextInput.scss';
import { getIn } from 'formik';

export const TextInput = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    placeholder,
    disabled,
    required,
    type = "text",
    arrayItem,
    ...props
    })=>{
    
    const view = <div className="input-container">
                    {label && <label id={field.name}>{label}</label>}
                    {required && <span className="pl-1 asterisk">*</span>}
                    <input 
                        type={type}
                        id={`input-${field.name}`}
                        className= {`form-control ${touched[field.name] && errors[field.name]? 'border-red' : ''}`}
                        placeholder={placeholder}
                        disabled={disabled}
                        {...field}
                        {...props} />
                    {
                        !arrayItem && touched[field.name] && errors[field.name] ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors[field.name]}</div>
                        </div>
                        ) : null
                    }
                    {
                        arrayItem && getIn(touched, field.name) && getIn(errors, field.name) ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{getIn(errors, field.name)}</div>
                        </div>
                        ) : null
                    }
                </div>
    return view;
};