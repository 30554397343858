import React from "react";

import "./Accordion.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";

export const Accordion = ({children, title, expandHandler, expanded, index}) => {
  const view = (
    <div className="et-accordion pb-2">
      <div
        className="accordion-header border-bottom pl-6 pr-6 pt-3 pb-3"
        onClick={() => {
          if(expanded){
            expandHandler(-1)
          } else {
            expandHandler(index)
          }
        }}>
        <span>{title}</span>
        <span>
          {expanded && <FontAwesomeIcon icon={faAngleUp} />}
          {!expanded && <FontAwesomeIcon icon={faAngleDown} />}
        </span>
      </div>
      {
          expanded && 
          <div className="accordion-body border p-8">
            {children}
          </div>
      }
    </div>
  );
  return view;
};
