import React, { useContext, useState } from 'react';
import { faList } from '@fortawesome/free-solid-svg-icons';

import './Manage.scss';
import { ContentLayout, Table, UtilityService, ApiService } from '../../shared';
import { Switch, Route, useHistory } from "react-router-dom";
import AppConstants from '../../../AppContants';
import { GlobalContext } from '../../../../global-context/GlobalContext';
import { RegContext } from '../RegContext';
import ManageButtonDrop from './ManageButtonDrop';
const headerData = [
  { "text": "Reference No", "sort": true, "filter": true, "filterKey": "refference_no", "filterValue": "" },
  { "text": "Admission No", "sort": true, "filter": true, "filterKey": "admission_no", "filterValue": "" },
  { "text": "Full Name", "sort": true, "filter": true, "filterKey": "first_name", "filterValue": "" },
  { "text": "Course Opted", "sort": true, "filter": true, "filterKey": "course_name", "filterValue": "" },
  { "text": "Student Phone No", "sort": true, "filter": true, "filterKey": "phone_no", "filterValue": "" },
  { "text": "Academic Year", "sort": true, "filter": true, "filterKey": "academic_year", "filterValue": "" },
  { "text": "Admitted Reservation Category", "sort": true, "filter": true, "filterKey": "first_name", "filterValue": "" },
  { "text": "Admission Quota", "sort": true, "filter": true, "filterKey": "quota", "filterValue": "" },
  { "text": "Registration Time", "sort": true, "filter": true, "filterKey": "time", "filterValue": "" },
  { "text": "Status", "sort": true, "filter": true, "filterKey": "status", "filterValue": "" },
  { "text": "Is Archived", "sort": true, "filter": true, "filterKey": "is_archived", "filterValue": "" }
];

const Manage = () => {
  const { setStudent } = useContext(RegContext);
  const { setSpinner, setError } = useContext(GlobalContext);
  const history = useHistory();
 
  const initialTableData = {
    list: [],
    totalCount: 0,
    currentPage: 1,
    pageSize: 10
  }
  const [tableData, setTableData] = useState(initialTableData);
  // fetch all student registers form backend based on query
  const fetchRegisters = async (query) => {
    setSpinner(true);
    let data = await ApiService.getData("/registration/registers" + query);
    if (data.error) {
      setError({
        type: "Error",
        data: [data.message],
      });
    } else if (data && data.grid && data._meta) {
      // data = dummyData;
      tableData.list = UtilityService.concatProperties(data.grid, ['first_name', 'middle_name', 'last_name'], 'full_name');
      tableData.totalCount = data._meta.totalCount;
      setTableData(tableData);
    }
    setSpinner(false);
  };

  /**
   * pagination event from table
   * @param query 
   */
  const pageChange = (query) => {
    fetchRegisters(query);
  }
  
  const clickView = (student) => {
    setStudent(student);
    history.push('/registration/' + student.refference_no);
  };
  const clickEdit = (student) => {
    setStudent(student);
    switch(student.reg_type){
      case "BTECH": history.push("/registration/" + student.refference_no + "/btech-edit-details");
      break;
      case "BTECH(Lateral Entry)": history.push("/registration/" + student.refference_no + "/btech-let-edit-details");
      break;
      case "MTECH": history.push("/registration/" + student.refference_no + "/mtech-edit-details");
      break;
      case "PhD": history.push("/registration/" + student.refference_no + "/phD-edit-details");
      break;
      default: break;
    }
  }
  const clickDelete = (student) => {
    alert("Write logic for delete click")
  }
  const view = <div className="et-manage card">
    <Switch>
      <Route exact path="/registration/manage">
        <ContentLayout
          headerText="View Registrations"
          icon={faList}
          buttonDrop={<ManageButtonDrop />}
        >
          {
            tableData && tableData.list &&
            <Table
              headerData={headerData}
              tableData={tableData}
              links={AppConstants.registration.manage.links}
              linkUrlKey={AppConstants.registration.manage.linkUrlKey}
              baseUrl={AppConstants.registration.manage.baseUrl}
              keyList={AppConstants.registration.manage.keyList}
              studentHandler={(student) => {
                setStudent(student)
              }}
              pageHandler={pageChange}
              action={{ view: true, edit: true, delete: true }}
              viewHandler={clickView}
              editHandler={clickEdit}
              deleteHandler={clickDelete}
            />
          }
        </ContentLayout>
      </Route>
      
    </Switch>
  </div>


  return view;
}
export default Manage;
