import React from 'react';
import { Link } from 'react-router-dom';

import './Widget.scss';

export const Widget = (props)=>{
    const view = <div className="widget">
        <Link to={props.link}>
            <img height="40px" width="40px" src={props.icon} alt={props.name} />
            <div className="widget-name">{props.name}</div>
        </Link>
    </div>;
    return view;
}