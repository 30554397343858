import React from "react";
import { Field } from "formik";

import {TextInput, SubHeading} from '../../../../shared';

export const BtechLetForm3 = () => {
  const view = (
    <React.Fragment>
      <SubHeading heading="Diploma Details" />
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="diploma_university"
            component={TextInput}
            label="BOARD"
            type="text"
            required={true}
            placeholder="BOARD"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="diploma_year"
            component={TextInput}
            label="YEAR"
            type="number"
            required={true}
            placeholder="YEAR"
          />
        </div>

        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="diploma_percentage"
            component={TextInput}
            label="PERCENTAGE"
            type="number"
            required={true}
            placeholder="00.00"
          />
        </div>
      </div>
    </React.Fragment>
  );
  return view;
};