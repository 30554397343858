import React from 'react';

import { toAbsoluteUrl } from '../../../../_helpers';

export function NotifyDrpDown ({node}){
    const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
    return (
        <>
            <div className="d-flex flex-column pt-5 pl-6 pr-6 pb-5 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}>
                <h4 className="d-flex flex-center rounded-top">
                    <span className="text-white">Notifications</span>
                    <span className="btn-text btn-success btn-sm font-weight-bold btn-font-md ml-3 text-nowrap">
                    23 new
                    </span>
                </h4>
            </div>
            <div className="d-flex flex-column pt-6 pb-8 rounded-bottom header-drop-content">           
                <a target="_self" href="https://mea.etlab.in/user/inbox">
                    <div className="pl-6 pr-6 pb-4">Inbox</div>
                </a>
                <a target="_self" href="https://mea.etlab.in/user/sentitems">
                    <div className="pl-6 pr-6">Sent Items</div>
                </a>
            </div>
        </>
    );
}