import React from 'react';
import { FieldArray, useFormikContext, Field } from 'formik';

import './BoardSettings.scss';
import { TextInput } from '../../../shared';

const Subjects = (props)=>{
    const { values } = useFormikContext();
    const view = <>
        <div className="form-sub-heading board-course-heading">{props.course}</div>
        <div className="row">
            <div className="col-5 board-subject-heading">Subject</div>
            <div className="col-5 board-subject-heading">Max Mark</div>
        </div>
        <FieldArray
            name={props.subjectArrayModel}
            render={(arrayHelpers) => (
              <>
                {Array.isArray(values[props.subjectArrayModel]) && values[props.subjectArrayModel].map((subject, index) => (
                  <div key={index} className="row">
                    <div className="col-5">
                        <Field
                            name={`${props.subjectArrayModel}[${index}].subject_name`}
                            component={TextInput}
                            placeholder="Subject Name"
                            arrayItem={true} />
                    </div>
                    <div className="col-5">
                        <Field 
                            name={`${props.subjectArrayModel}.${index}.subject_max_mark`}
                            component={TextInput}
                            type="number"
                            placeholder="Subject Max Mark"
                            arrayItem={true} />
                    </div>
                    <div className="col-2">
                        <button
                        type="button"
                        disabled = {values[props.subjectArrayModel].length === 1}
                        className="form-control btn btn-secondary"
                        onClick={() => arrayHelpers.remove(index)}>
                            -
                        </button>
                    </div>
                  </div>
                ))}
                <div className="row add-subject-btn">
                    <div className="col-2">
                        <button
                            type="button"
                            className="form-control btn btn-primary"
                            onClick={() => arrayHelpers.push({ subject_name: "", subject_max_mark: "" })}>
                            +
                        </button>
                    </div>
                </div>
              </>
            )}
          />
    </>
    return view;
}
export default Subjects;