import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faEye, faEdit, faTrash, faDownload } from "@fortawesome/free-solid-svg-icons";
import Pagination from '@material-ui/lab/Pagination';

import "./Table.scss";
import {Link} from "react-router-dom";

export const Table = (props) => {
  const [filterRequired, setFilterRequired] = useState(false);
  const [headerData, setHeaderdata]= useState(props.headerData);

  const [pageSize, setPageSize] = useState(10);
  const [activePage, setActivePage] = useState(1);

  const [sortIndex, setSortIndex] = useState(0);
  const [sortAsc, setSortAsc] = useState(true);
  let sortIcon = (
    <span className="sort-icon">
      <FontAwesomeIcon icon={faCaretUp} />
    </span>
  );
  if (!sortAsc) {
    sortIcon = (
      <span className="sort-icon">
        <FontAwesomeIcon icon={faCaretDown} />
      </span>
    );
  }

  useEffect(() => {
    // if atleast one column have filter then show filter row
    for (const header of props.headerData) {
      if (header.filter) {
        setFilterRequired(true);
        break;
      }
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    changePageData();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [pageSize, activePage, sortAsc, sortIndex]);

  const handleSort = (index, header) => {
    if (index !== sortIndex) {
      setSortIndex(index);
      setSortAsc(true);
    } else {
      setSortAsc(!sortAsc);
    }
  };

  const handleFilter = (e,header) => {
    header.filterValue = e.target.value;
    setHeaderdata(JSON.parse(JSON.stringify(headerData)));
  };

  /**
   * on press of enter key on any filter
   * @param e 
   */
  const confirmFilter = (e) => {
    if (e.key === "Enter") {
      if(activePage === 1){
        changePageData();
      } else {
        setActivePage(1);
      }
    }
  };

  /**
   * form query url using filters and sort
   * send data to parant
   */
  const changePageData = ()=>{
    let query = "?sort=";
      if(sortAsc){
        query = query + headerData[sortIndex].filterKey;
      } else {
        query = query + "-" + headerData[sortIndex].filterKey;
      }
      for(const head of headerData){
        if(head.filterValue !== ""){
          // if(head.filterKey === "full_name"){
          //   query = query + "&first_name=" + head.filterValue + "&middle_name=" + head.filterValue + "&last_name=" + head.filterValue; 
          // } else {
          //   query = query + "&" + head.filterKey + "=" + head.filterValue
          // }
          query = query + "&" + head.filterKey + "=" + head.filterValue
        } 
      }
      query = query + "&per-page=" + parseInt(pageSize) + "&page=" + activePage;
      props.pageHandler(query);
  }

  /**
   * pagination page change event
   */
  const handlePageChange = (event, pageNumber)=>{
    console.log(`active page is ${pageNumber}`);
    setActivePage(pageNumber);
  }

  const isLink = (key)=>{
    let islink = false;
    if(props.links && props.linkUrlKey){
      for(const item of props.links){
        if(key === item){
          islink = true;
        }
      }
    }
    return islink;
  }

  const view = (
    <>
      <div className="et-table table-responsive">
      <table className="table">
        <thead>
          <tr>
            {headerData.map((header, index) => {
              if (typeof header === "object") {
                return (
                  <th
                    key={index}
                    onClick={() => handleSort(index,header)}
                    className={header.sort ? "sortable-header" : ""}
                  >
                    {header && <span>{header.text}</span>}
                    {index === sortIndex ? sortIcon : ""}
                  </th>
                );
              }
              return <th key={index}>{header}</th>;
            })}
            {
              props.action && 
              <th>
                Action
              </th>
            }
            
          </tr>
        </thead>

        <tbody>
          {filterRequired && (
            <tr>
              {headerData.map((header, index) => {
                return (
                  <td key={index}>
                    {header.filter && (
                      <input
                        type="text"
                        value={header.filterValue}
                        onChange={(e) => handleFilter(e,header)}
                        onKeyDown={confirmFilter}
                      />
                    )}
                  </td>
                );
              })}
            </tr>
          )}
          {/* iterate through rows */}
          {
          props.tableData && props.tableData.list && 
            Array.isArray(props.tableData.list) && 
              props.tableData.list.map((rowObj, index) => {
            const row = (
              <tr key={index}>
                  {/* iterate through column values */}
                    {props.keyList.map((key, i) => {                     
                        return (
                          <td key={i}>
                              {
                                isLink(key) &&
                                <Link onClick={()=>props.studentHandler(rowObj)} to={props.baseUrl + rowObj[props.linkUrlKey]}>
                                  {rowObj[key]}
                                </Link>
                              }
                              {
                                !isLink(key) &&
                                rowObj[key]
                              }
                          </td>
                        );
                  })}
                  {
                    props.action && 
                    <td>
                      <span className="d-flex">
                        {
                          props.action.view &&
                          <FontAwesomeIcon 
                              className="ml-2 mr-2 btn-link"
                              icon={faEye}
                              onClick={()=>{
                                  props.viewHandler(rowObj)
                              }} 
                          />
                        }
                        {
                          props.action.edit &&
                          <FontAwesomeIcon 
                              className="ml-2 mr-2 btn-link"
                              icon={faEdit}
                              onClick={()=>{
                                  props.editHandler(rowObj)
                              }} 
                          />
                        }
                        {
                          props.action.delete &&
                          <FontAwesomeIcon 
                              className="ml-2 mr-2 btn-link"
                              icon={faTrash}
                              onClick={()=>{
                                  props.deleteHandler(rowObj)
                              }} 
                          />
                        }
                        {
                          props.action.download &&
                          <FontAwesomeIcon 
                              className="ml-2 mr-2 btn-link"
                              icon={faDownload}
                              onClick={()=>{
                                  props.downloadHandler(rowObj)
                              }} 
                          />
                        }
                      </span>
                    </td>
                  }
              </tr>
            );
            return row;
          })}
        </tbody>
      </table>

      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="table-pagination">
          <Pagination 
            count={parseInt(props.tableData.totalCount/pageSize)+1 }
            color="secondary"
            onChange={handlePageChange}
            showFirstButton
            showLastButton />
        </div>
        <div className="table-page-size pl-4">
          Page size
          <select
            value={pageSize}
            className="ml-2 mr-2"
            onChange={(e)=>{
              setPageSize(e.target.value);
            }}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
    </>
  );
  return view;
};

