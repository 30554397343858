
/* eslint-disable  no-unused-vars */

export const StudentDetailsService = {
    summaryMapping: [
        { "label":"Reference No", "key": "refference_no" },
        { "label":"First Name", "key": "first_name" },
        { "label":"Middle Name", "key": "middle_name" },
        { "label":"Last Name", "key": "last_name" },
        { "label":"Course Type", "key": "reg_type" },
        { "label":"Course Opted", "key": "course_id" },
        { "label":"Admission No", "key": "admission_no", "class": "bg-success" },
        { "label":"Status", "key":"status" },
        { "label":"Token No", "key":"token_no" },
    ],
    personalDetailsMapping: [
        { "label":"First Name", "key": "first_name" },
        { "label":"Middle Name", "key": "middle_name" },
        { "label":"Last Name", "key": "last_name" },
        { "label":"Admission No", "key": "token_no" },
        { "label":"Gender", "key": "gender" },
        { "label":"Blood Group", "key": "blood_group" },
        { "label":"Mother Tounge", "key": "mother_tounge" },
        { "label":"Nationality", "key": "nationality" },
        { "label":"Date of Birth", "key": "dob" },
        { "label":"Place of Birth", "key": "pob" },
        { "label":"Religion", "key": "religion" },
        { "label":"Caste", "key": "caste" },
        { "label":"Adhar No", "key":"adharno" },
        { "label":"Differently Abled", "key": "differently_abled" },
        { "label":"Economically Backward", "key":"eco_backward" },
        { "label":"Eligible Reservation Category", "key": "eligible_reservation_category" },
    ],
    admissionDetailsMapping: [
        { "label":"Course Name", "key": "course_id" },
        { "label":"Academic Year", "key": "academic_year" },
        { "label":"Quota", "key": "admission_type" },
        { "label":"Admitted Reservation Category", "key": "admission_category" },
        { "label":"Type", "key": "adm_type" },
        { "label":"Nativity", "key": "nativity" },
        { "label":"Community", "key": "community" },
        { "label":"Income Category", "key": "income_category" },
        { "label":"Fee Conces Eligibility", "key": "fee_conces_eligibility" },
        { "label":"Qualification", "key": "qualification" },
        { "label":"Entrance Type", "key": "entrance_type" },
        { "label":"Entrance Roll No", "key": "entrance_regno" },
        { "label":"Entrance Rank", "key": "entrance_rank" },
        { "label":"NATA Score", "key": "nata_score" },
    ],
    diplomaAdmissionDetailsMapping: [
        { "label":"Course Name", "key": "course_id" },
        { "label":"Academic Year", "key": "academic_year" },
        { "label":"Quota", "key": "admission_type" },
        { "label":"Admitted Reservation Category", "key": "admission_category" },
        { "label":"Type", "key": "adm_type" },
        { "label":"Nativity", "key": "nativity" },
        { "label":"Community", "key": "community" },
        { "label":"Income Category", "key": "income_category" },
        { "label":"Fee Conces Eligibility", "key": "fee_conces_eligibility" },
        { "label":"Qualification", "key": "qualification" },
        { "label":"LET Rank", "key": "let_rank" },
        { "label":"LET Roll No", "key": "let_no" },
    ],
    metchAdmissionDetailsMapping: [
        { "label":"Course Name", "key": "course_id" },
        { "label":"Academic Year", "key": "academic_year" },
        { "label":"Quota", "key": "admission_type" },
        { "label":"Admitted Reservation Category", "key": "admission_category" },
        { "label":"Type", "key": "adm_type" },
        { "label":"Nativity", "key": "nativity" },
        { "label":"Community", "key": "community" },
        { "label":"Income Category", "key": "income_category" },
        { "label":"Fee Conces Eligibility", "key": "fee_conces_eligibility" },
        { "label":"Qualification", "key": "qualification" },
        { "label":"Entrance Type", "key": "entrance_type" },
        { "label":"Gate Rank", "key": "btech_gate_rank" },
        { "label":"Gate Score", "key": "btech_gate_score" },
    ],
    phdAdmissionDetailsMapping: [
        { "label":"Course Name", "key": "course_id" },
        { "label":"Academic Year", "key": "academic_year" },
        { "label":"Quota", "key": "admission_type" },
        { "label":"Admitted Reservation Category", "key": "admission_category" },
        { "label":"Type", "key": "adm_type" },
        { "label":"Nativity", "key": "nativity" },
        { "label":"Community", "key": "community" },
        { "label":"Income Category", "key": "income_category" },
        { "label":"Fee Conces Eligibility", "key": "fee_conces_eligibility" },
        { "label":"Qualification", "key": "qualification" },
    ],
    bankDetailsMapping:[
        { "label":"Bank Name", "key": "bank_name" },
        { "label":"Account No", "key": "bank_accno" },
        { "label":"Name of Branch", "key": "bank_branch" },
        { "label":"IFCS Code", "key": "bank_ifsc_code" },
    ],
    hseDetailMapping:[
        { "label":"Board", "key": "highersecondary_board" },
        { "label":"Reg No", "key": "highersecondary_regno" },
        { "label":"Year", "key": "highersecondary_year" },
    ],
    seDetailMapping:[
        { "label":"Board", "key": "secondary_board" },
        { "label":"Reg No", "key": "secondary_regno" },
        { "label":"Year", "key": "secondary_year" },
    ],
    hsePercentageDetailMapping: [
        { "label":"Merit Subject Percentage", "key": "merit_subjects_pct" },
        { "label":"Overall Plus One Mark", "key": "overall_plusone_mark" },
        { "label":"Overall Plus One Percentage", "key": "overall_plusone_pct" },
        { "label":"Overall Plus Two Mark", "key": "overall_plustwo_mark" },
        { "label":"Overall Plus Two Percentage", "key": "overall_plustwo_pct" },
        { "label":"Overall Mark", "key": "overall_mark" },
        { "label":"Overall Percentage", "key": "overall_pct" }
    ],
    tcDetailsMapping: [
        { "label":"Last Institute (As per TC)", "key": "last_school" },
        { "label":"TC No", "key": "tc_no" },
        { "label":"TC Date", "key": "tc_date" },
    ],
    diplomaTcDetailsMapping: [
        { "label":"Last College","key":"diploma_college" },
        { "label":"TC No", "key": "diploma_tc_no" },
        { "label":"TC Date", "key": "diploma_tc_date" },
    ],
    btechTcDetailsMapping: [
        { "label":"Last College","key" : "btech_college" },
        { "label":"TC No", "key": "btech_tc_no" },
        { "label":"TC Date", "key": "btech_tc_date" },
    ],
    mtechTcDetailsMapping: [
        { "label":"Last College","key" : "mtech_college" },
        { "label":"TC No", "key": "mtech_tc_no" },
        { "label":"TC Date", "key": "mtech_tc_date" },
    ],
    presentAddressDetailsMapping:[
        { "label":"House Name", "key": "present_address_1" },
        { "label":"Street", "key": "present_address_2" },
        { "label":"Distirct", "key": "present_address_3" },
        { "label":"Post/Street 2", "key": "present_address_4" },
        { "label":"Pin", "key": "present_address_5" },
        { "label":"State", "key": "present_state" },
    ],
    permanentAddressDetailsMapping:[
        { "label":"House Name", "key": "permanent_address_1" },
        { "label":"Street", "key": "permanent_address_2" },
        { "label":"Distirct", "key": "permanent_address_3" },
        { "label":"Post/Street 2", "key": "permanent_address_4" },
        { "label":"Pin", "key": "permanent_address_5" },
        { "label":"State", "key": "state" },
        { "label":"Student Mobile No", "key": "phone_no" },
        { "label":"Email", "key": "email" },
        { "label":"Land Phone No", "key": "phone_home" },
    ],
    personalIdentificationDetailsMapping:[
        { "label":"Personal Marks 1", "key": "personal_marks_1" },
        { "label":"Personal Marks 2", "key": "personal_marks_2" },
    ],
    parentDetailsMapping:[
        { "label":"Father's Name", "key": "father_name" },
        { "label":"Father's Occupation", "key": "father_occupation" },
        { "label":"Father's / Guardian's Mobile No", "key": "phone_father" },
        { "label":"Mother's Name", "key": "mother_name" },
        { "label":"Mother's Occupation", "key": "mother_occupation" },
        { "label":"Mother's Mobile No", "key": "phone_mother" },
        { "label":"Parent Email", "key": "parent_email" },
        { "label":"Phone Office", "key": "phone_office" },
        { "label":"Annual Income", "key": "annual_income" },
    ],
    hostelDetailsMapping:[
        { "label":"Do you need Hostel Facilities ? ", "key": "is_hostler" },
    ],
    graduationDetailsMapping:[
        { "label":"University", "key": "btech_university" },
        { "label":"Year", "key": "btech_year" },
        { "label":"Specialisation", "key": "mtech_specialisation" },
        { "label":"S1 Mark", "key": "s1_mark" },
        { "label":"S1 Total Mark", "key": "s1_totalmark" },
        { "label":"S1 Percentage", "key": "s1_percentage" },
        { "label":"S2 Mark", "key": "s12_mark" },
        { "label":"S2 Total Mark", "key": "s2_totalmark" },
        { "label":"S2 Percentage", "key": "s2_percentage" },
        { "label":"S3 Mark", "key": "s3_mark" },
        { "label":"S3 Total Mark", "key": "s3_totalmark" },
        { "label":"S3 Percentage", "key": "s3_percentage" },
        { "label":"S4 Mark", "key": "s4_mark" },
        { "label":"S4 Total Mark", "key": "s4_totalmark" },
        { "label":"S4 Percentage", "key": "s4_percentage" },
        { "label":"S5 Mark", "key": "s5_mark" },
        { "label":"S5 Total Mark", "key": "s5_totalmark" },
        { "label":"S5 Percentage", "key": "s5_percentage" },
        { "label":"S6 Mark", "key": "s6_mark" },
        { "label":"S6 Total Mark", "key": "s6_totalmark" },
        { "label":"S6 Percentage", "key": "s6_percentage" },
        { "label":"S7 Mark", "key": "s7_mark" },
        { "label":"S7 Total Mark", "key": "s7_totalmark" },
        { "label":"S7 Percentage", "key": "s7_percentage" },
        { "label":"S8 Mark", "key": "s8_mark" },
        { "label":"S8 Total Mark", "key": "s8_totalmark" },
        { "label":"S8 Percentage", "key": "s8_percentage" },
        { "label":"S9 Mark", "key": "s9_mark" },
        { "label":"S9 Total Mark", "key": "s9_totalmark" },
        { "label":"S9 Percentage", "key": "s9_percentage" },
        { "label":"S10 Mark", "key": "s10_mark" },
        { "label":"S10 Total Mark", "key": "s10_totalmark" },
        { "label":"S10 Percentage", "key": "s10_percentage" },
        { "label":"Btech Mark (Upto 6th Semester)", "key": "btech_mark" },
        { "label":"Btech Total Mark (Upto 6th Semester)", "key": "btech_totalmark" },
        { "label":"Percentage", "key": "btech_percentage" },
    ],
    postGraduationDetailsMapping:[
        { "label":"University", "key": "mtech_university" },
        { "label":"Year", "key": "mtech_year" },
        { "label":"Percentage", "key": "mtech_percentage" },
        { "label":"Specialisation", "key": "mtech_specialisation" },
    ],
    underGraducationDetailsMapping:[
        { "label":"University", "key": "btech_university" },
        { "label":"Year", "key": "btech_year" },
        { "label":"Percentage", "key": "btech_percentage" },
    ],
    diplomaDetailsMapping:[
        { "label":"University", "key": "diploma_university" },
        { "label":"Year", "key": "diploma_year" },
        { "label":"Percentage", "key": "diploma_percentage" },
    ],
};