import React from 'react';
import './DetailGrid.scss';

import { DetailsGridItem } from '../details-grid-item/DetailsGridItem';

export const DetailGrid = ({data})=>{
    const view = (
        <div className="row">
            {
                data.map((gridItem, index) => {
                    const item = (
                        <div key={index} className={`col-6 col-md-3 col-lg-3 border ${gridItem.class ? gridItem.class: ''}`} >
                                <DetailsGridItem 
                                    label={gridItem.label}
                                    value={gridItem.value}
                                />
                            </div>
                    );
                    return (gridItem.value || gridItem.value=== 0)  && item;
                })
            }
        </div>
    );
    return view;
}