import React, { useContext } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { Dashboard } from "./pages/dashboard/Dashboard";
import Registration from "./pages/registration/Registration";
import { GlobalContext } from "../global-context/GlobalContext";
import { LinearProgress, CircularProgress } from "@material-ui/core";

export default function Routes() {
  const { loader, spinner } = useContext(GlobalContext);
  return (
    <div className="et-router">
      {loader && (
        <div className="et-loader">
          <LinearProgress />
        </div>
      )}
      {
        spinner && (
          <div className="et-spinner">
            <CircularProgress />
          </div>
      )}
      <Switch>
        {
          /* Redirect from root URL to /home. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/registration" component={Registration} />
        {/* <Redirect to="error/error-v1" /> */}
      </Switch>
    </div>
  );
}
