import React from 'react';
import {Field } from 'formik';

import {TextInput, SelectInput, DateInput, SubHeading} from '../../../../shared';

export const BtechRegForm4 = () =>{

    const view = <React.Fragment>
        <SubHeading heading="Secondary School Details"/>
        <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <Field name="secondary_board"
                       component={TextInput}
                       label="BOARD"
                       type="text"
                       required={true}
                       placeholder = "BOARD" />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <Field name="secondary_year"
                       component={SelectInput}
                       label="YEAR"
                       required={true}
                       options= {["2000", "2001", "2002", "2003", "2004", "2005"]} />

            </div>

            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <Field name="secondary_regno"
                       component={TextInput}
                       label="REG NO"
                       type="text"
                       required={true}
                       placeholder = "REG NO" />
            </div>
        </div><hr/>
        <SubHeading heading="TC Details"/>
        <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <Field name="last_school"
                       component={TextInput}
                       label="LAST INSTITUTE (AS PER TC)"
                       type="text"
                       required={true}
                       placeholder = "LAST INSTITUTE" />


            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <Field name="tc_no"
                       component={TextInput}
                       label="TC NO"
                       type="text"
                       required={true}
                       placeholder = "TC NO" />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <Field name="tc_date"
                       component={DateInput}
                       label="TC DATE"
                       required={true} />

            </div>


        </div>
    </React.Fragment>
    return view;
}