const AppConstants = {
    endPoint: "https://etlab.in/etlab_core/api/web",
    etLab1Url: "",
    publicUrl: "https://api.postalpincode.in/pincode/",
    sitekey: "6LehnMIZAAAAAJSVGmXxmcjDyjKvlWs9OoY8vPoE",
    fileSize: 1000,
    registration: {
        status:{ 
            studentRegisteredInternal: "Student Registered Internal",
            studentJustRegistered: "Student Just Registered",
            studentVerified: "Student Verified",
            studentBatchAssigned: "Student Batch Assigned",
            studentAdmitted: "Student Admitted",
            studentFeePaid: "Student Fee Paid",
            studentActive: "Student Active",
            studentDeactivated: "Student Deactivated",
            studentCousrseCompleted: "Student Course Completed",
            studentTcIssued: "Student TC Issued",
            studentCollegeTransferred:"Student College Transferred",
            studentNotPromoted: "Student Not Promoted",
            studentYearOut: "Student Year Out",
            studentDiscontinued: "Student Discontinued"
        },
        verify:{
            links: ['refference_no', 'full_name'],
            linkUrlKey: "refference_no",
            baseUrl: "/registration/",
            keyList: ["refference_no", "full_name", "course_id", "status"]
        },
        assign:{
            links: ['refference_no', 'full_name'],
            linkUrlKey: "refference_no",
            baseUrl: "/registration/",
            keyList: ["refference_no", "full_name", "batch_id", "status"]
        },
        admission:{
            links: ['refference_no', 'full_name'],
            linkUrlKey: "refference_no",
            baseUrl: "/registration/",
            keyList: ["refference_no", "token_no", "full_name", "course_id", "status"]
        },
        adslip:{
            links: ['refference_no', 'admission_no', 'full_name'],
            linkUrlKey: "refference_no",
            baseUrl: "/registration/",
            keyList: ["refference_no", "admission_no","full_name", "batch_id", "status", "create_time"]
        },
        approve:{
            links: ['refference_no', 'admission_no', 'full_name'],
            linkUrlKey: "refference_no",
            baseUrl: "/registration/",
            keyList: ["refference_no", "admission_no", "full_name","batch_id", "status","create_time"]
        },
        archive:{
            links: ['refference_no', 'admission_no', 'full_name'],
            linkUrlKey: "refference_no",
            baseUrl: "/registration/",
            keyList: ["refference_no", "admission_no", "full_name", "course_id", "academic_year", "status","is_archived"]
        },
        docSubmit:{
            links: ['refference_no','admission_no', 'full_name'],
            linkUrlKey: "refference_no",
            baseUrl: "/registration/document-submission/",
            keyList: ["refference_no", "admission_no", "full_name", "course_id", "status","docSub"]
        },
        manage:{
            links: ['refference_no','admission_no', 'full_name'],
            linkUrlKey: "refference_no",
            baseUrl: "/registration/",
            keyList: ["refference_no", "admission_no", "full_name", "course_id", "phone_no", "academic_year", "admission_category", "admission_type", "create_time", "status","is_archived"]
        }
    }
};
export default AppConstants;