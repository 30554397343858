import React, { useEffect, useState } from "react";
import { Field, useFormikContext } from "formik";

import {TextInput, SelectInput, SubHeading} from '../../../../shared';

export const MtechForm3 = () => {
  const { values, setFieldValue } = useFormikContext();
  const [disables9mark, setDisables9mark] = useState(false);
  const [disables10mark, setDisables10mark] = useState(false);
  const [disables9total, setDisables9total] = useState(false);
  const [disables10total, setDisables10total] = useState(false);
  const [disables9percentage, setDisables9percentage] = useState(false);
  const [disables10percentage, setDisables10percentage] = useState(false);
  const [disables1mark, setDisables1mark] = useState(false);
  const [disables2mark, setDisables2mark] = useState(false);
  const [disables1total, setDisables1total] = useState(false);
  const [disables2total, setDisables2total] = useState(false);
  const [disables1percentage, setDisables1percentage] = useState(false);
  const [disables2percentage, setDisables2percentage] = useState(false);
  const [disableyear, setDisablesyear] = useState(false);
  useEffect(() => {
    if (values.barch_check === "No") {
      setDisables9mark(true);
      setDisables10mark(true);
      setDisables9total(true);
      setDisables10total(true);
      setDisables9percentage(true);
      setDisables10percentage(true);
      setDisablesyear(true);
    } else {
      setDisables9mark(false);
      setDisables10mark(false);
      setDisables9total(false);
      setDisables10total(false);
      setDisables9percentage(false);
      setDisables10percentage(false);
      setDisablesyear(false);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [values.barch_check]);
  useEffect(() => {
    if (values.btech_type !== "Regular") {
      setDisables1mark(true);
      setDisables2mark(true);
      setDisables1total(true);
      setDisables2total(true);
      setDisables1percentage(true);
      setDisables2percentage(true);
      setDisablesyear(true);
      setFieldValue("first_year_type", "", true);
    } else {
      setDisables1mark(false);
      setDisables2mark(false);
      setDisables1total(false);
      setDisables2total(false);
      setDisables1percentage(false);
      setDisables2percentage(false);
      setDisablesyear(false);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [values.btech_type, values.first_year_type]);
  useEffect(() => {
    if (values.first_year_type === "Combined") {
      setDisables1mark(true);
      setDisables1total(true);
      setDisables1percentage(true);
    } else if (values.first_year_type === "Seperate") {
      setDisables1mark(false);
      setDisables1total(false);
      setDisables1percentage(false);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [values.first_year_type]);
  const view = (
    <React.Fragment>
      <SubHeading heading="Graduation Details" />
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="btech_university"
            component={TextInput}
            label="University"
            required={true}
            placeholder="UNIVERISTY"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="btech_year"
            component={TextInput}
            label="Year"
            required={true}
            placeholder="YEAR"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="mtech_specialisation"
            component={TextInput}
            label="Specialisation"
            required={true}
            placeholder="SPECIALISATION"
          />
        </div>
      </div>
      <hr></hr>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="btech_type"
            component={SelectInput}
            label="Type of Admission"
            options={["Regular", "Lateral Entry"]}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="first_year_type"
            component={SelectInput}
            label="Type of First Year"
            options={["Combined", "Seperate"]}
            disabled={disableyear}
          />
        </div>
      </div>
      <hr></hr>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s1_mark"
            component={TextInput}
            label="S1 Mark"
            required={true}
            disabled={disables1mark}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s1_totalmark"
            component={TextInput}
            label="S1 Total Mark"
            required={true}
            disabled={disables1total}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s1_percentage"
            component={TextInput}
            label="S1 Percentage"
            required={true}
            disabled={disables1percentage}
            type="number"
            placeholder="00.00"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s2_mark"
            component={TextInput}
            label="S2 Mark"
            required={true}
            disabled={disables2mark}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s2_totalmark"
            component={TextInput}
            label="S2 Total Mark"
            required={true}
            disabled={disables2total}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s2_percentage"
            component={TextInput}
            label="S2 Percentage"
            required={true}
            disabled={disables2percentage}
            type="number"
            placeholder="00.00"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s3_mark"
            component={TextInput}
            label="S3 Mark"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s3_totalmark"
            component={TextInput}
            label="S3 Total Mark"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s3_percentage"
            component={TextInput}
            label="S3 Percentage"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s4_mark"
            component={TextInput}
            label="S4 Mark"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s4_totalmark"
            component={TextInput}
            label="S4 Total Mark"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s4_percentage"
            component={TextInput}
            label="S4 Percentage"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s5_mark"
            component={TextInput}
            label="S5 Mark"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s5_totalmark"
            component={TextInput}
            label="S5 Total Mark"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s5_percentage"
            component={TextInput}
            label="S5 Percentage"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s6_mark"
            component={TextInput}
            label="S6 Mark"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s6_totalmark"
            component={TextInput}
            label="S6 Total Mark"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s6_percentage"
            component={TextInput}
            label="S6 Percentage"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s7_mark"
            component={TextInput}
            label="S7 Mark"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s7_totalmark"
            component={TextInput}
            label="S7 Total Mark"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s7_percentage"
            component={TextInput}
            label="S7 Percentage"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s8_mark"
            component={TextInput}
            label="S8 Mark"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s8_totalmark"
            component={TextInput}
            label="S8 Total Mark"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="s8_percentage"
            component={TextInput}
            label="S8 Percentage"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="barch_check"
            component={SelectInput}
            label="Whether Graduated in B.Arch ?"
            options={["Yes", "No"]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
        <Field
            name="s9_mark"
            component={TextInput}
            label="S9 Mark"
            required={true}
            disabled={disables9mark}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
        <Field
            name="s9_totalmark"
            component={TextInput}
            label="S9 Total Mark"
            required={true}
            disabled={disables9total}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
        <Field
            name="s9_percentage"
            component={TextInput}
            label="S9 Percentage"
            required={true}
            disabled={disables9percentage}
            type="number"
            placeholder="00.00"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
        <Field
            name="s10_mark"
            component={TextInput}
            label="S10 Mark"
            required={true}
            disabled={disables10mark}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">

        <Field
            name="s10_totalmark"
            component={TextInput}
            label="S10 Total Mark"
            required={true}
            disabled={disables10total}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
        <Field
            name="s10_percentage"
            component={TextInput}
            label="S10 Percentage"
            required={true}
            disabled={disables10percentage}
            type="number"
            placeholder="00.00"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="btech_mark"
            component={TextInput}
            label="Btech Mark (Upto 6th Semester)"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="btech_totalmark"
            component={TextInput}
            label="Btech Total Mark (Upto 6th Semester)"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="btech_percentage"
            component={TextInput}
            label="Percentage"
            required={true}
            type="number"
            placeholder="00.00"
          />
        </div>
      </div>
    </React.Fragment>
  );
  return view;
};