import React from 'react';

import './SelectInput.scss';

export const SelectInput = ({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        label,
        disabled,
        options,
        optionKey,
        required,
        ...props
    })=>{
    const view = <div className="input-container">
                    {label && <label id={field.name}>{label}</label>}
                    {required && <span className="pl-1 asterisk">*</span>}
                    <select
                        id={`input-${field.name}`}
                        className= {`form-control ${touched[field.name] && errors[field.name]? 'border-red' : ''}`}
                        disabled={disabled}
                        value = {field.value}
                        {...field}
                        {...props}>
                            <option value="">Select</option>
                            {
                                options.map((value,i)=>{
                                    const optionEl = optionKey ? 
                                    <option key={i} 
                                            value={value.id}>
                                            {value[optionKey]}
                                    </option>:
                                    <option key={i} 
                                            value={value}>
                                            {value}
                                    </option>;
                                    return optionEl;
                                })
                            }

                    </select>
                    {
                        touched[field.name] && errors[field.name] ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors[field.name]}</div>
                        </div>
                        ) : null
                    }
                </div>
    return view;
};