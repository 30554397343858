import React, { useEffect, useContext } from 'react';
import { faList } from '@fortawesome/free-solid-svg-icons';

import {ContentLayout, ApiService, DetailGrid} from '../../shared';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../../../../global-context/GlobalContext';
import { RegContext } from '../RegContext';

const StudentDocumentDetails = () => {
    const [student, setStudent] = useContext(RegContext);
    const {setSpinner, setError} = useContext(GlobalContext);
    const {refNo} = useParams();

    useEffect(()=>{
        const fetchStudent = ()=>{
            setSpinner(true);
            const data = ApiService.getData('/registration/register?refference_no=' + refNo);
            if (data.error) {
                setError({
                  type: "Error",
                  data: [data.message],
                });
            } else if (data) {
                setStudent(data);
            }
            setSpinner(false);
        }
        if(!student){
            fetchStudent();
        }
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    },[]);

   
    const view = <div className="et-student-document">
        <ContentLayout headerText="Document" icon={faList}>
            <DetailGrid 
             data = {[
                { "label":"Admission No", "key": "adm_no" },
                { "label":"Name", "key": "full_name" },
                { "label":"Class Name", "key": "class_no" },
                
            ]}
            />
            <hr/>
            <StudentDocumentTable />
        </ContentLayout>
    </div>;
    return view;
}
export default StudentDocumentDetails;

// Student Document List shown here 

const StudentDocumentTable = () => {
 
const view = (
    <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="table-responsive">
                <table className="table table-stripped">
                    <thead>
                        <tr>
                            <th>S.no</th>
                            <th>Document Name</th>
                            <th>Details</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Allotment Memo</td>
                            <td>Not Submitted</td>
                            <td>Not Submitted</td>
                            <td><button 
                                type="button"
                                className="btn btn-primary">
                                Add
                            </button></td> 
                        </tr>  

                        <tr>
                            <td>2</td>
                            <td>Admitt Card</td>
                            <td>Not Submitted</td>
                            <td>Not Submitted</td>
                            <td><button 
                                    type="button"
                                    className="btn btn-primary">
                                    Add
                                </button></td> 
                        </tr> 
                        <tr>
                            <td>3</td>
                            <td>T.C</td>
                            <td>Not Submitted</td>
                            <td>Not Submitted</td>
                            <td><button 
                                    type="button"
                                    className="btn btn-primary">
                                    Add
                            </button></td> 
                        </tr> 
                        <tr>
                            <td>4</td>
                            <td>Physical Certificate</td>
                            <td>Not Submitted</td>
                            <td>Not Submitted</td>
                            <td><button 
                                    type="button"
                                    className="btn btn-primary">
                                    Add </button></td> 
                        </tr>                      
                    </tbody>
                </table>
            </div>
        </div>
        
    </div>
    );

return view;
};
