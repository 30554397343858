import React from 'react';


export const TextAreaInput = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    placeholder,
    disabled,
    rows = "5",
    required,
    ...props
    })=>{
    const view = <div className="input-container">
                    {label && <label id={field.name}>{label}</label>}
                    {required && <span className="pl-1 asterisk">*</span>}
                    <textarea
                        id={`input-${field.name}`}
                        className= {`form-control ${touched[field.name] && errors[field.name]? 'border-red' : ''}`}
                        disabled={disabled}
                        value = {field.value}
                        rows = {rows}
                        {...field}
                        {...props}>
                    </textarea> 
                    {
                        touched[field.name] && errors[field.name] ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors[field.name]}</div>
                        </div>
                        ) : null
                    }
                </div>
    return view;
};