import React, { useState } from 'react';
import "./RegistrationSettings.scss";

import {TabLayout} from '../../shared';
import DocumentSettings from './document-settings/DocumentSettings';
import BoardSettings from './board-settings/BoardSettings';

const RegistrationSettings = () => {
    
    
        const [tab, setTab] = useState(0);
        const view = <div className="et-manage card">
       
        <TabLayout tabChangeHandler = {(tab)=>{setTab(tab)}}>
            <div className="">
                {
                    tab === 0 && <BoardSettings/>
                }
                {
                    tab === 1 && <DocumentSettings/>
                }
          </div>
        </TabLayout>
          
          
        
    </div>;
    return view;
                
 
};
export default RegistrationSettings;