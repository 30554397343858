import React, { useContext, useState, useEffect } from "react";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";

import "./BoardSettings.scss";
import {
  TextInput,
  SelectInput,
  ApiService,
  Accordion,
} from "../../../shared";
import BoardSubjects from "./BoardSubjects";
import { GlobalContext } from "../../../../../global-context/GlobalContext";

const initialValues = {
  id: null,
  board: "",
  course_option: "Plus Two Only",
  course_combination: "",
  plusone_subjects: [
    {
      subject_name: "",
      subject_max_mark: "",
    },
  ],
  plusone_max_mark: "",
  plustwo_subjects: [],
  plustwo_max_mark: "",
};

const BoardSettings = () => {
  const { setError,
    setSpinner,
    setInfo,
    spinner } = useContext(GlobalContext);
  const [boards, setBoards] = useState([]);
  const [selectedAccIndex, setSelectedAccIndex] = useState(0);
  const [mode, setMode] = useState("read");

  useEffect(() => {
    fetchBoardData();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  // fetch saved board data from backend
  const fetchBoardData = async () => {
    setSpinner(true);
    const data = await ApiService.getData("/registration/boards");
    if (data.error) {
      setError({
        type: "Error",
        data: [data.message],
      });
    } else if (data) {
      setBoards(JSON.parse(JSON.stringify(data)));
    }
    setSpinner(false);
  };

  const boardSchema = Yup.object().shape({
    id: Yup.mixed(),
    board: Yup.string().required("Board is required"),
    course_option: Yup.string().required("Course option is required"),
    course_combination: Yup.string().when("course_option", {
      is: "Plus One & Plus Two",
      then: Yup.string().nullable(true).required("Course Combination is required"),
      otherwise: Yup.string().nullable(true).notRequired(),
    }),
    plusone_subjects: Yup.array().nullable(true).of(
      Yup.object().shape({
        subject_name: Yup.string().required("Subject name is required"),
        subject_max_mark: Yup.number().required(
          "Subject maximum mark is required"
        ),
      })
    ),
    plusone_max_mark: Yup.number().when("course_option", {
      is: "Plus One & Plus Two",
      then: Yup.number().required("Plus one max is required"),
      otherwise: Yup.number().nullable(true).notRequired(),
    }),
    plustwo_subjects: Yup.array().of(
      Yup.object().shape({
        subject_name: Yup.string().required("Subject name is required"),
        subject_max_mark: Yup.number().required(
          "Subject maximum mark is required"
        ),
      })
    ),
    plustwo_max_mark: Yup.number().required("Total max mark is required"),
  });

  /**
   * on click of submit button in form
   * execute only if all filed validations are success
   * @param values 
   */
  const onSubmit = async (values) => {
    setSpinner(true);
    const method = mode === "create" ? "postData" : "putData";
    const url  = mode === "create" ? "/registration/boards" : "/registration/boards/" + values.id;
    if(values.course_option === 'Plus Two Only'){
      values.course_combination = "";
      values.plusone_max_mark = null;
      values.plusone_subjects = [];
    }
    const data = await ApiService[method](url, values);
    if (data.error) {
      setError({
        type: "Error",
        data: [data.message],
      });
    } else if (data) {
      fetchBoardData();
      setMode('read');
      setInfo({
        type: "Info",
        data: ["Board Saved Successfully"]
      });
    }
    setSpinner(false);
  };

  /**
   * on click of create board button
   * change mode to create and push blank object to board array
   */
  const createBoard = () => {
    boards.push(initialValues);
    setBoards(boards);
    setSelectedAccIndex(boards.length - 1);
    setMode("create");
  };

  /**
   * cancel edit/create
   * on click of delete from paratially filled board in read mode
   */
  const cancel = () => {
    if (mode === "create" || mode === 'read') {
      boards.splice(boards.length - 1, 1);
      setBoards(boards);
      setSelectedAccIndex(null);
    }
    setMode("read");
  };

  // component view
  const view = (
    <div className="et-registration-settings">
      <div className="pb-2 text-bold text-uppercase">Boards</div>
      <Formik
        initialValues={initialValues}
        validationSchema={boardSchema}
        onSubmit={onSubmit}
      >
        { ({resetForm})=> 
          <Form>
          {Array.isArray(boards) && boards.map((item, index) => {
            return (
              <Accordion
                key={index}
                title={item.board}
                expandHandler={(i) => {
                  setSelectedAccIndex(i);
                  setMode('read');
                }}
                expanded={index === selectedAccIndex}
                index={index}
              >
                <>
                  {mode === "read" && (
                    <ReadOnlyBoardData
                      item={item}
                      modeHandler={() => {
                        setMode("edit");
                      }}
                      deleteHandler = {()=>{
                        setSelectedAccIndex(null);
                        fetchBoardData()
                      }}
                      cancelHandler = {cancel}
                    />
                  )}
                  {(mode === "edit" || mode === "create") && (
                    <>
                      <Course />
                      <hr />
                      <BoardSubjects cancelHandler={cancel} />
                    </>
                  )}
                </>
              </Accordion>
            );
          })}
          {
            mode !== 'create' &&
            <div className="pt-2">
              <button
                type="button"
                className="btn btn-primary"
                disabled = {spinner}
                onClick={()=>{
                  resetForm()
                  createBoard()
                }}
              >
                Create Board
              </button>
            </div>
          }
        </Form>
        }
      </Formik>
    </div>
  );
  return view;
};
export default BoardSettings;

/**
 * display board on read mode
 * @param item 
 * @param modeHandler 
 * @param deleteHandler 
 * */
const ReadOnlyBoardData = ({ item, modeHandler, deleteHandler, cancelHandler }) => {
  const { setValues } = useFormikContext();
  const { setSpinner, setError, setInfo } = useContext(GlobalContext);
  
  /**
   * on click of edit button
   * @param item 
   */
  const editBoard = (item) => {
    setValues(item, true);
    modeHandler();
  };

  /**
   * on click of delete button
   * @param board 
   */
  const deleteBoard = async(board)=>{
    if(board.id && window.confirm("Are you sure that you want to delete --> " + board.board)){
      setSpinner(true);
      const data = await ApiService.deleteData('/registration/boards/' + board.id);
      if (data.error) {
        setError({
          type: "Error",
          data: [data.message],
        });
      } else if (data) {
        deleteHandler();
        setInfo({
          type: "Info",
          data: ["Board Deleted Successfully"]
        });
      }
      setSpinner(false);
    } else if(!board.id){
      cancelHandler()
    }
  }

  // component view
  const view = (
    <div className="">
      <div className="row">
        <div className="col-4 d-flex flex-column pb-2">
          <span className="text-bold pb-2">Board Name</span>
          <span>{item.board}</span>
        </div>
        <div className="col-4 d-flex flex-column pb-2">
          <span className="text-bold pb-2">Course Option</span>
          <span>{item.course_option}</span>
        </div>
        <div className="col-4 d-flex flex-column pb-2">
          <span className="text-bold pb-2">Course Combination</span>
          <span>{item.course_combination}</span>
        </div>
      </div>
      <hr />
      <div className="row">
        {item.course_option &&
          item.course_option !== "Plus Two Only" &&
          item.plusone_subjects &&
          Array.isArray(item.plusone_subjects) && (
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex flex-column border-right">
              <div className="text-bold pb-2">Plus One Subjects</div>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Subject Name</th>
                      <th>Max Mark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item && Array.isArray(item.plusone_subjects) && item.plusone_subjects.map((subject, index) => {
                      return (
                        <tr key={index}>
                          <td>{subject.subject_name}</td>
                          <td>{subject.subject_max_mark}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <hr />
              <div className="d-flex pb-4">
                <span className="">
                  Grand Total :&nbsp;&nbsp;
                </span>
                <span>{item.plusone_max_mark}</span>
              </div>
            </div>
          )}

        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex flex-column">
          <div className="text-bold pb-2">Plus Two Subjects</div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Subject Name</th>
                  <th>Max Mark</th>
                </tr>
              </thead>
              <tbody>
                {item && Array.isArray(item.plustwo_subjects) && item.plustwo_subjects.map((subject, index) => {
                  return (
                    <tr key={index}>
                      <td>{subject.subject_name}</td>
                      <td>{subject.subject_max_mark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <hr />
          <div className="d-flex pb-4">
            <span className="">
              Grand Total :&nbsp;&nbsp;
            </span>
            <span>{item.plustwo_max_mark}</span>
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-center">
        <button
          type="button"
          className="btn btn-primary mr-2"
          onClick={() => editBoard(item)}
        >
          Edit
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={()=>{deleteBoard(item)}}>
          Delete
        </button>
      </div>
    </div>
  );
  return view;
};

/**
 * display first row course options section
 */
const Course = () => {
  const { values } = useFormikContext();
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
        <Field
          name="board"
          component={TextInput}
          label="Board"
          required={true}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
        <Field
          name="course_option"
          component={SelectInput}
          label="Course Option"
          required={true}
          options={["Plus Two Only", "Plus One & Plus Two"]}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
        <Field
          name="course_combination"
          component={SelectInput}
          label="Course Combination"
          required={true}
          disabled={values.course_option === "Plus Two Only"}
          options={["Separate", "Combined"]}
        />
      </div>
    </div>
  );
};
