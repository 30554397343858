import React, { useContext }  from 'react';
import { Field } from 'formik';

import {TextInput, SelectInput, SubHeading} from '../../../../shared';
import { GlobalContext } from "../../../../../../global-context/GlobalContext";

export const BtechLetForm2 = () => {
  const { sharedData } = useContext(GlobalContext);
  const view = (
    <React.Fragment>
      <SubHeading heading="Admission Details" />
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="reg_type"
            component={TextInput}
            label="ADMISSION FOR"
            disabled={true}
            type="text"
            required={true}
            placeholder="ADMISSION FOR"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="academic_year"
            component={SelectInput}
            label="ACADEMIC YEAR"
            type="number"
            required={true}
            options={sharedData && sharedData.academic_year? sharedData.academic_year : []}
            optionKey="name"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="course_id"
            component={SelectInput}
            label="COURSE OPTED"
            required={true}
            options={sharedData && sharedData.department_list? sharedData.department_list : []}
            optionKey="name"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="admission_type"
            component={TextInput}
            label="QUOTA"
            required={true}
            disabled={true}
            type="text"
            placeholder="QUOTA"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="adm_type"
            component={SelectInput}
            label="TYPE"
            options={["Regular", "Readmission"]}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="admission_category"
            component={SelectInput}
            label="ADMITTED RESERVATION CATEGORY"
            options={sharedData && sharedData.admission_category? sharedData.admission_category : []}
            optionKey="value"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="fee_conces_eligibility"
            component={SelectInput}
            label="FEE CONCESSION ELIGIBILITY"
            required={true}
            options={sharedData && sharedData.fee_conces_eligibility? sharedData.fee_conces_eligibility : []}
            optionKey="value"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="income_category"
            component={SelectInput}
            label="INCOME CATEGORY"
            required={true}
            options={["APL", "BPL"]}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="let_rank"
            component={TextInput}
            label="LET RANK"
            type="number"
            required={true}
            placeholder="LET RANK"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="let_no"
            component={TextInput}
            label="LET ROLLNO"
            type="text"
            required={true}
            placeholder="LET ROLLNO"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="community"
            component={SelectInput}
            label="COMMUNITY"
            required={true}
            options={sharedData && sharedData.community? sharedData.community : []}
            optionKey="value"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="nativity"
            component={SelectInput}
            label="NATIVITY"
            options={["Keralite", "Non-Keralite"]}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="qualification"
            component={SelectInput}
            label="QUALIFICATION"
            options={sharedData && sharedData.qualification? sharedData.qualification : []}
            optionKey="value"
          />
        </div>
      </div>
    </React.Fragment>
  );
  return view;
};