import React, { useContext, useState, useEffect } from "react";
import { Field, useFormikContext } from "formik";

import {
  TextInput,
  SelectInput,
  DateInput,
  SubHeading,
  UtilityService,
} from "../../../../shared";
import { GlobalContext } from "../../../../../../global-context/GlobalContext";
import { RegContext } from "../../../RegContext";
import { useLocation } from "react-router-dom";

export const PhdForm1 = () => {
  const { sharedData } = useContext(GlobalContext);
  const { student } = useContext(RegContext);
  const { values, setValues } = useFormikContext();
  const location = useLocation();
  const [casteList, setCasteList] = useState([]);
  useEffect(() => {
    if (location.pathname.indexOf("edit-details") > -1) {
      setValues(student);
    }
    if (sharedData && sharedData.caste_list) {
      const filterdCaste = UtilityService.filterById(
        sharedData.caste_list,
        values.religion_id,
        "religion_id"
      );
      setCasteList(filterdCaste);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.religion_id]);
  const view = (
    <React.Fragment>
      <SubHeading heading="Personal Details" />
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="first_name"
            component={TextInput}
            label="First Name"
            required={true}
            placeholder="FIRST NAME"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="middle_name"
            component={TextInput}
            label="Middle Name"
            required={true}
            placeholder="MIDDLE NAME"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="last_name"
            component={TextInput}
            label="Last Name"
            required={true}
            placeholder="LAST NAME"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="gender"
            component={SelectInput}
            label="Gender"
            required={true}
            options={sharedData && sharedData.gender ? sharedData.gender : []}
            optionKey="value"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="blood_group"
            component={SelectInput}
            label="Blood Group"
            options={
              sharedData && sharedData.blood_group ? sharedData.blood_group : []
            }
            optionKey="value"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="dob"
            component={DateInput}
            label="Date of Birth"
            required={true}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="mother_tongue"
            component={TextInput}
            label="Mother Tounge"
            placeholder="MOTHER TOUNGE"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="pob"
            component={TextInput}
            label="Place of Birth"
            required={true}
            placeholder="PLACE OF BIRTH"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="nationality"
            component={TextInput}
            label="Nationality"
            placeholder="NATIONALITY"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="religion_id"
            component={SelectInput}
            label="Religion"
            required={true}
            options={
              sharedData && sharedData.religion_list
                ? sharedData.religion_list
                : []
            }
            optionKey="name"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="caste_id"
            component={SelectInput}
            label="Caste"
            required={true}
            options={casteList}
            optionKey="name"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="eligible_reservation_category"
            component={SelectInput}
            label="ELIGIBLE RESERVATION CATEGORY"
            options={
              sharedData && sharedData.eligible_reservation_category
                ? sharedData.eligible_reservation_category
                : []
            }
            optionKey="value"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="eco_backward"
            component={SelectInput}
            label="Economicalty Backward"
            options={["No", "Yes"]}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="adharno"
            component={TextInput}
            label="Aadhar Number"
            type="number"
            required={true}
            placeholder="AADHAR NUMBER"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="differenty_abled"
            component={SelectInput}
            label="Differrently Abled"
            options={["No", "Yes"]}
          />
        </div>
      </div>
    </React.Fragment>
  );
  return view;
};
