/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import { checkIsActive} from "../../../../_helpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBriefcase,
  faDiagnoses,
  faLayerGroup,
  faGraduationCap,
  faEnvelopeOpenText,
  faCommentDots,
  faChartBar,
  faFile } from '@fortawesome/free-solid-svg-icons';
export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
        ? " menu-item-active menu-item-open "
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/dashboard")}`}
              aria-haspopup="true">
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <FontAwesomeIcon className="side-nav-icon" icon={faLayerGroup} />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>

          {/* Modules */}
          <li className="menu-section ">
            <h4 className="menu-text">MODULES</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/staff")}`}
              aria-haspopup="true">
              <a target="_self" href="https://mea.etlab.in/user/admin/1" className="menu-link">
              <span className="svg-icon menu-icon">
                  <FontAwesomeIcon className="side-nav-icon" icon={faBriefcase} />
                </span>
                <span className="menu-text">Staff</span>
              </a>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/student")}`}
              aria-haspopup="true">
            
            <a target="_self" href="https://mea.etlab.in/user/admin/0" className="menu-link">
              <span className="svg-icon menu-icon">
                <FontAwesomeIcon className="side-nav-icon" icon={faDiagnoses} />
              </span>
              <span className="menu-text">Student</span>
            </a>
          </li>
          <li 
              className={`menu-item ${getMenuItemActive("/batches")}`}
              aria-haspopup="true">
            
            <a target="_self" href="https://mea.etlab.in/batch/index" className="menu-link">
              <span className="svg-icon menu-icon">
                <FontAwesomeIcon className="side-nav-icon" icon={faGraduationCap} />
              </span>
              <span className="menu-text">Batches</span>
            </a>
          </li>

          {/* Components */}
          <li className="menu-section ">
            <h4 className="menu-text">COMPONENTS</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/email")}`}
              aria-haspopup="true">
            <a target="_self" href="https://mea.etlab.in/message" className="menu-link">
              <span className="svg-icon menu-icon">
                <FontAwesomeIcon className="side-nav-icon" icon={faEnvelopeOpenText} />
              </span>
              <span className="menu-text">Email</span>
            </a>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/sms")}`}
              aria-haspopup="true">
            
            <a target="_self" href="https://mea.etlab.in/sms" className="menu-link">
              <span className="svg-icon menu-icon">
                <FontAwesomeIcon className="side-nav-icon" icon={faCommentDots} />
              </span>
              <span className="menu-text">SMS</span>
            </a>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/user-stats")}`}
              aria-haspopup="true">
            
            <a target="_self" href="https://mea.etlab.in/user/stats" className="menu-link">
              <span className="svg-icon menu-icon">
                <FontAwesomeIcon className="side-nav-icon" icon={faChartBar} />
              </span>
              <span className="menu-text">User Stats</span>
            </a>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/reports")}`}
              aria-haspopup="true">
            
            <a target="_self" href="https://mea.etlab.in/report" className="menu-link">
              <span className="svg-icon menu-icon">
                <FontAwesomeIcon className="side-nav-icon" icon={faFile} />
              </span>
              <span className="menu-text">Reports</span>
            </a>
          </li>
          {/*end::1 Level*/}
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
