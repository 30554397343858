import React, { useEffect, useContext } from "react";
import { SubHeading, ApiService, FileInput } from "../../../../shared";
import { Field, FieldArray, useFormikContext } from "formik";
import { GlobalContext } from "../../../../../../global-context/GlobalContext";


export const BtechLetForm10 = () => {
  const { setError, setSpinner } = useContext(GlobalContext);
  const { values, setFieldValue } = useFormikContext();
  useEffect(() => {
    fetchDocsData();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  // fetch saved documents data from backend
  const fetchDocsData = async () => {
    setSpinner(true);
    const data = await ApiService.getData("/registration/documents");
    if (data.error) {
      setError({
        type: "Error",
        data: [data.message],
      });
    } else if (data && Array.isArray(data)) {
      for(const item of data){ 
          if(item.course_type === values.reg_type){
            for(const doc of item.documents){
              values.documents.push({
                doc_name: doc.doc_name,
                doc_max_size: doc.doc_max_size,
                doc_min_size: doc.doc_min_size,
                file: null
              })
            }
            setFieldValue("documents", values.documents)
          }
      }
    }
    setSpinner(false);
  };

  const view = (
    <React.Fragment>
      <SubHeading heading="Additional Documents Needed" />

      {values.documents &&
          Array.isArray(values.documents) && (
      <FieldArray
        name="documents"
        render={(arrayHelpers) => (
          <>
            <div className="table-responsive">
              <table className="table table-bordred">
                <thead>
                  <tr>
                    <th>Document Name</th>
                    
                    <th>Document Upload</th>
                  </tr>
                </thead>
                <tbody>

                  {values.documents.map((doc, index) => {
                    return (

                      <tr key={index}>
                        <td>{doc.doc_name}</td>
                        
                        <td>
                          <Field
                            name={`documents[${index}].file`}
                            component={FileInput}
                            note={"Maximum size " + doc.doc_max_size + ", Min size " + doc.doc_min_size }
                            arrayItem={true}
                          />
                        </td>
                      </tr>
                    );

                  })}
                </tbody>
              </table>
            </div>
          </>
        )}

      /> )}
     
    </React.Fragment>
  );
  return view;
};