import React, { useState, useEffect, useContext } from "react";
import { faList } from "@fortawesome/free-solid-svg-icons";

import "./StudentDetails.scss";
import {
  ContentLayout,
  Accordion,
  DetailGrid,
  ApiService,
  UtilityService,
} from "../../shared";

import { GlobalContext } from "../../../../global-context/GlobalContext";
import { useParams, Route, Switch, useLocation } from "react-router-dom";
import { StudentDetailsService } from "./StudentDetailsService";
import { RegContext } from "../RegContext";
import StudentDetailsButtonDrop from "./StudentDetailsButtonDrop";
import { StatusBar } from "../../shared/status-bar/StatusBar";
import sign from "../../../asset/images/sign.png";
import image from "../../../asset/images/image.png";

const StudentDetails = () => {
  const [selectedAccIndex, setSelectedAccIndex] = useState(0);
  const { student, setStudent } = useContext(RegContext); // context data
  const { setSpinner, setError } = useContext(GlobalContext);
  const { refNo } = useParams();
  const url = useLocation().pathname;
  /**
   * execute onload
   * fetch student data by selected ref no if it is not available in context
   */
  useEffect(() => {
    const fetchStudent = async () => {
      setSpinner(true);
      const data = await ApiService.getData(
        "/registration/registers?refference_no=" + refNo
      );
      if (data.error) {
        setError({
          type: "Error",
          data: [data.message],
        });
      } else if (
        data &&
        data.grid &&
        Array.isArray(data.grid) &&
        data.grid.length > 0
      ) {
        setStudent(data.grid[0]); // set reg context
      }
      setSpinner(false);
    };
    if (!student) {
      fetchStudent();
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  const view = (
    <div className="et-reg-student-detail">
      <Switch>
        <Route path={url}>
          {student && (
            <ContentLayout
              headerText="Registration Ref No:19R2033"
              icon={faList}
              buttonDrop={<StudentDetailsButtonDrop />}
            >
              <StatusBar value={student.status} />
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 row">
                  <div className="col-6 border d-flex align-items-center">
                    <img className="w-100" src={image} alt={image.name} />
                  </div>
                  <div className="col-6 border d-flex align-items-center">
                    <img className="w-100 mr-2" src={sign} alt={sign.name} />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 d-flex align-items-center">
                  <DetailGrid
                    data={UtilityService.getAccItems(
                      student,
                      StudentDetailsService.summaryMapping
                    )}
                  />
                </div>
              </div>
            </ContentLayout>
          )}
        </Route>
      </Switch>
      {student && (
        <ContentLayout headerText="Ref No:19R2033 Details" icon={faList}>
          <Accordion
            title="Documents Submitted"
            expandHandler={(index) => {
              setSelectedAccIndex(index);
            }}
            expanded={selectedAccIndex === 0}
            index={0}
          >
            <div className="row">
              {student.document_submitted &&
                student.document_submitted.map((doc, i) => {
                  return (
                    <div
                      key={i}
                      className="col-xs-12 col-sm-6 col-md-6 col-lg-4"
                    >
                      <input value={doc.submitted} type="checkbox" disabled />
                      <label className="pl-2">{doc.doc_name}</label>
                    </div>
                  );
                })}
            </div>
          </Accordion>
          <Accordion
            title="Personal Details"
            expandHandler={(index) => {
              setSelectedAccIndex(index);
            }}
            expanded={selectedAccIndex === 1}
            index={1}
          >
            <DetailGrid
              data={UtilityService.getAccItems(
                student,
                StudentDetailsService.personalDetailsMapping
              )}
            />
          </Accordion>
          <Accordion
            title="Admission Details"
            expandHandler={(index) => {
              setSelectedAccIndex(index);
            }}
            expanded={selectedAccIndex === 2}
            index={2}
          >
            {student.reg_type && student.reg_type === "BTECH" && (
              <DetailGrid
                data={UtilityService.getAccItems(
                  student,
                  StudentDetailsService.admissionDetailsMapping
                )}
              />
            )}
            {student.reg_type &&
              student.reg_type === "BTECH(Lateral Entry)" && (
                <DetailGrid
                  data={UtilityService.getAccItems(
                    student,
                    StudentDetailsService.diplomaAdmissionDetailsMapping
                  )}
                />
              )}
            {student.reg_type && student.reg_type === "MTECH" && (
              <DetailGrid
                data={UtilityService.getAccItems(
                  student,
                  StudentDetailsService.metchAdmissionDetailsMapping
                )}
              />
            )}
            {student.reg_type && student.reg_type === "PhD" && (
              <DetailGrid
                data={UtilityService.getAccItems(
                  student,
                  StudentDetailsService.phdAdmissionDetailsMapping
                )}
              />
            )}
          </Accordion>
          <Accordion
            title="Bank Details"
            expandHandler={(index) => {
              setSelectedAccIndex(index);
            }}
            expanded={selectedAccIndex === 3}
            index={3}
          >
            <DetailGrid
              data={UtilityService.getAccItems(
                student,
                StudentDetailsService.bankDetailsMapping
              )}
            />
          </Accordion>

          {student.reg_type && student.reg_type === "BTECH(Lateral Entry)" && (
            <Accordion
              title="Diploma Details"
              expandHandler={(index) => {
                setSelectedAccIndex(index);
              }}
              expanded={selectedAccIndex === 4}
              index={4}
            >
              <DetailGrid
                data={UtilityService.getAccItems(
                  student,
                  StudentDetailsService.diplomaDetailsMapping
                )}
              />
            </Accordion>
          )}

          {student.qualification && student.reg_type === "PhD" && (
            <Accordion
              title="Post Graduation Details"
              expandHandler={(index) => {
                setSelectedAccIndex(index);
              }}
              expanded={selectedAccIndex === 4}
              index={4}
            >
              <DetailGrid
                data={UtilityService.getAccItems(
                  student,
                  StudentDetailsService.postGraduationDetailsMapping
                )}
              />
            </Accordion>
          )}

          {student.qualification && student.reg_type === "MTECH" && (
            <Accordion
              title="Graduation Details"
              expandHandler={(index) => {
                setSelectedAccIndex(index);
              }}
              expanded={selectedAccIndex === 13}
              index={13}
            >
              <DetailGrid
                layout="3"
                data={UtilityService.getAccItems(
                  student,
                  StudentDetailsService.graduationDetailsMapping
                )}
              />
            </Accordion>
          )}

          {student.reg_type && student.reg_type === "BTECH" && (
            <Accordion
              title="Higher Secondary School Details"
              expandHandler={(index) => {
                setSelectedAccIndex(index);
              }}
              expanded={selectedAccIndex === 5}
              index={5}
            >
              <DetailGrid
                data={UtilityService.getAccItems(
                  student,
                  StudentDetailsService.hseDetailMapping
                )}
              />
            </Accordion>
          )}

          {student.reg_type && student.reg_type === "BTECH" && (
            <Accordion
              title="Secondary School Details"
              expandHandler={(index) => {
                setSelectedAccIndex(index);
              }}
              expanded={selectedAccIndex === 6}
              index={6}
            >
              <DetailGrid
                data={UtilityService.getAccItems(
                  student,
                  StudentDetailsService.seDetailMapping
                )}
              />
            </Accordion>
          )}

          {student.reg_type && student.reg_type === "BTECH" && (
            <Accordion
              title="Higher Secondary Percentage Obtained"
              expandHandler={(index) => {
                setSelectedAccIndex(index);
              }}
              expanded={selectedAccIndex === 7}
              index={7}
            >
              <>
                <div className="row border pt-2">
                  {student.plusone_subjects && (
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="text-center">Plus One Subjects</div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Subject Name</th>
                              <th>Subject Mark</th>
                              <th>Subject Percentage</th>
                            </tr>
                          </thead>
                          <tbody>
                            {student.plusone_subjects.map((subject, i) => {
                              return (
                                <tr key={i}>
                                  <td>{subject.subject_name}</td>
                                  <td>{subject.subject_mark}</td>
                                  <td>{subject.subject_percentage}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  {student.plustwo_subjects && (
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="text-center">Plus Two Subjects</div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Subject Name</th>
                              <th>Subject Mark</th>
                              <th>Subject Percentage</th>
                            </tr>
                          </thead>
                          <tbody>
                            {student.plustwo_subjects.map((subject, i) => {
                              return (
                                <tr key={i}>
                                  <td>{subject.subject_name}</td>
                                  <td>{subject.subject_mark}</td>
                                  <td>{subject.subject_percentage}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
                <DetailGrid
                  data={UtilityService.getAccItems(
                    student,
                    StudentDetailsService.hsePercentageDetailMapping
                  )}
                />
              </>
            </Accordion>
          )}

          <Accordion
            title="TC Details"
            expandHandler={(index) => {
              setSelectedAccIndex(index);
            }}
            expanded={selectedAccIndex === 8}
            index={8}
          >
            {student.reg_type &&
              student.reg_type === "BTECH(Lateral Entry)" && (
                <DetailGrid
                  data={UtilityService.getAccItems(
                    student,
                    StudentDetailsService.diplomaTcDetailsMapping
                  )}
                />
              )}
            {student.reg_type && student.reg_type === "MTECH" && (
              <DetailGrid
                data={UtilityService.getAccItems(
                  student,
                  StudentDetailsService.btechTcDetailsMapping
                )}
              />
            )}
            {student.reg_type && student.reg_type === "PhD" && (
              <DetailGrid
                data={UtilityService.getAccItems(
                  student,
                  StudentDetailsService.mtechTcDetailsMapping
                )}
              />
            )}
            {student.reg_type && student.reg_type === "BTECH" && (
              <DetailGrid
                data={UtilityService.getAccItems(
                  student,
                  StudentDetailsService.tcDetailsMapping
                )}
              />
            )}
          </Accordion>

          <Accordion
            title="Contact Details"
            expandHandler={(index) => {
              setSelectedAccIndex(index);
            }}
            expanded={selectedAccIndex === 9}
            index={9}
          >
            <span className="pb-2 pt-3 text-bold text-success">
              PRESENT ADDRESS
            </span>
            <DetailGrid
              data={UtilityService.getAccItems(
                student,
                StudentDetailsService.presentAddressDetailsMapping
              )}
            />
            <br />
            <span className="pb-2 pt-3 text-bold text-success">
              PERMAMANENT ADDRESS
            </span>
            <DetailGrid
              data={UtilityService.getAccItems(
                student,
                StudentDetailsService.permanentAddressDetailsMapping
              )}
            />
          </Accordion>
          <Accordion
            title="Personal Marks for Identification"
            expandHandler={(index) => {
              setSelectedAccIndex(index);
            }}
            expanded={selectedAccIndex === 10}
            index={10}
          >
            <DetailGrid
              data={UtilityService.getAccItems(
                student,
                StudentDetailsService.personalIdentificationDetailsMapping
              )}
            />
          </Accordion>
          <Accordion
            title="Parent Details"
            expandHandler={(index) => {
              setSelectedAccIndex(index);
            }}
            expanded={selectedAccIndex === 11}
            index={11}
          >
            <DetailGrid
              data={UtilityService.getAccItems(
                student,
                StudentDetailsService.parentDetailsMapping
              )}
            />
          </Accordion>
          <Accordion
            title="Hostel Details"
            expandHandler={(index) => {
              setSelectedAccIndex(index);
            }}
            expanded={selectedAccIndex === 12}
            index={12}
          >
            <DetailGrid
              data={UtilityService.getAccItems(
                student,
                StudentDetailsService.hostelDetailsMapping
              )}
            />
          </Accordion>
        </ContentLayout>
      )}
    </div>
  );
  return view;
};
export default StudentDetails;
