import React, { useState } from 'react';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

export const TabLayout = ({children, tabChangeHandler})=>{
    const [tab, setTab] = useState(0);
   
    const view = <div className="et-tab-layout">
        
        <Tabs
            value={tab}
            onChange={(event, newValue) => {
                setTab(newValue);
                tabChangeHandler(newValue);
            }}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Board Settings" />
            <Tab  label="Document Settings" />
            
          </Tabs>
          <div className="tab-body p-8">
            {
              children
            }
          </div>
    </div>;
    return view;
};