import React, { createContext, useReducer } from 'react';

import globalReducer from './GlobalReducer';

const initialState = {
    user:{
        id: null,
        name: null,
        role: null
    },
    loader: false,
    spinner: false,
    error: null, // error will have values null and error data
    info: null,
    sharedData: null // will contains castlist, religion list, department list etc
};

export const GlobalContext = createContext(initialState);
export const GlobalProvider = ({children})=>{
    const [state, dispatch] = useReducer(globalReducer, initialState);
    const setUser = (user)=>{
        dispatch({
            type: 'SET_USER',
            payload: user
        })
    };
    const setLoader = (loader)=>{
        dispatch({
            type: 'SET_LOADER',
            payload: loader
        })
    };
    const setSpinner = (spinner)=>{
        dispatch({
            type: 'SET_SPINNER',
            payload: spinner
        })
    };
    const setError = (error)=>{
        dispatch({
            type: 'SET_ERROR',
            payload: error
        })
    };
    const setInfo = (info)=>{
        dispatch({
            type: 'SET_INFO',
            payload: info
        })
    };
    const setSharedData = (data)=>{
        dispatch({
            type: 'SET_SHARED_DATA',
            payload: data
        })
    };
    const context = {
        user: state.user,
        error: state.error,
        info: state.info,
        loader: state.loader,
        spinner: state.spinner,
        sharedData: state.sharedData,
        setUser,
        setError,
        setInfo,
        setLoader,
        setSpinner,
        setSharedData
    };
    return (
        <GlobalContext.Provider value = {context}>
            {children}
        </GlobalContext.Provider>
    )
}
