
/* eslint-disable  no-unused-vars */

export const UtilityService = {
    calculatePct: (actual, total)=>{
        return parseFloat(((actual/total)*100).toFixed(2));
    },
    getFormsList: (length)=>{
        const pageList = [];
        for(let i=1;i<=length;i++){
            pageList.push({
                id: i,
                name: 'form'+i
            })
        }
        return pageList;
    },
    filterById: (list, refId, refKey)=>{
       const filterdList = [];
       for(const item of list) {
           // eslint-disable-next-line eqeqeq
           if(item[refKey] == refId){
               filterdList.push(item);
           }
           
       }
       return filterdList;
    },
    getAccItems: (backendData, mappingInfo)=>{
        let accItems= [];
        if(backendData && mappingInfo && Array.isArray(mappingInfo)){
            accItems= JSON.parse(JSON.stringify(mappingInfo));
            for(const item of accItems){
               if(typeof backendData[item.key] !== undefined){
                   item.value = backendData[item.key];
               }
            }
        }
        return accItems;
    },
    /**
     * concatenate one or more properties pof an object to a destination property
     */
    concatProperties: (list, sourceKeys, destKey)=>{
        for(const item of list){
            let concatenatedValue = "";
            for(const key of sourceKeys){
                concatenatedValue = concatenatedValue + " " +item[key];
            }
            item[destKey] = concatenatedValue;
        }
        return list;
    }, 
    getStatusCode: (status)=> {
        switch(status){
            case "Student Registered Internal": return 2;   
            case "Student Just Registered": return 1;
            case "Student Verified": return 3;  
            case "Student Batch Assigned": return 4; 
            case "Student Admitted": return 5; 
            case "Student Dee Paid": return 6; 
            case "Student Active": return 13; 
            case "Student Deactivated": return ;
            case "Student Course Completed": return ;
            case "Student TC Issued": return ;
            case "Student College Transferred": return ;
            case "Student Not Promoted": return ;
            case "Student Year Out": return ;
            case "Student Discontinued": return ;
            default: break;
            // code block
        }
    }
};