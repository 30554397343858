import React, { useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import './Modal.scss';
import { GlobalContext } from '../../../../global-context/GlobalContext';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export function SimpleModal() {
  const [open, setOpen] = React.useState(false);
  const {error, info, setError, setInfo} = useContext(GlobalContext);
  useEffect(()=>{
    const handleClickOpen = () => {
        setOpen(true);
    };
    handleClickOpen();
  }, [setOpen])

  const handleClose = () => {
    setOpen(false);
    setError(null);
    setInfo(null);
  };
  let colorClass = "info";
  if(error){
      colorClass = "error";
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            <span className={colorClass}>
                {error && "Error"}
                {info && "Message"}
            </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
                error && error.data && Array.isArray(error.data) ?
                error.data.map((data, i)=>{
                    return <span className="modal-item" key={i}>{data}</span>;
                }): ''
            }
            {
                info && info.data && Array.isArray(info.data) &&
                info.data.map((data, i)=>{
                    return <span className="modal-item" key={i}>{data}</span>;
                })
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}