import React from "react";

import {SubHeading, CropImage} from '../../../../shared';

export const MtechForm9 = () => {
  const view = (
    <React.Fragment>
      <SubHeading heading="Upload Your Photo"/>
        <div className="row">
            <div className="col-12">
            <CropImage  
                name="image"
                label="PASSPORT SIZE PHOTO "
                required={true}
                note="Maximum size 100KB, dimensions: 150x200px"
                height= {200} // in px
                width= {150} // in px
                fileName = "profile-photo" />
            </div>
        </div>
        <SubHeading heading="Upload Your Signature"/>
        <div className="row">
            <div className="col-12">
            <CropImage  
                name="sign"
                label="SIGNATURE"
                required={true}
                note="Maximum size 30KB, dimensions: 150x100px"
                height= {100} // in px
                width= {150} // in px
                fileName = "student-sign" />
                
            </div>
        </div>
        <SubHeading heading="Upload Your Parent's Signature"/>
        <div className="row">
            <div className="col-12">
            <CropImage  
                name="parent_sign"
                label="PARENT'S SIGNATURE"
                required={true}
                note="Maximum size 30KB, dimensions: 150x100px"
                height= {100} // in px
                width= {150} // in px
                fileName = "parent-sign" />    
            </div>
        </div>
    </React.Fragment>
  );
  return view;
};
