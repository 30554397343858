import React, { useRef, useState } from 'react';
import { getIn } from 'formik';

export const FileInput = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors, setFieldValue, setFieldTouched, setFieldError }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    placeholder,
    disabled,
    note,
    required,
    arrayItem,
    ...props
    })=>{
    const node = useRef();
    const [firstClick, setFirstClick] = useState(true);
    const onFileChange = (e)=>{
        setFieldValue(field.name, node.current.files[0], true);
    }
    const onFileBlur = ()=>{
        if(!firstClick){
            setFieldTouched(field.name, true, true);
        }
        setFirstClick(false);
    }
    
    const view = <div className="input-container">
                    {label && <label id={field.name}>{label}</label>}
                    {required && <span className="pl-1 asterisk">*</span>}
                    <input 
                        type="file"
                        ref={node}
                        id={`input-${field.name}`}
                        className= {`form-control ${touched[field.name] && errors[field.name]? 'border-red' : ''}`}
                        onChange = {onFileChange}
                        onBlur = {onFileBlur} />
                    {
                        !arrayItem && touched[field.name] && errors[field.name] ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors[field.name]}</div>
                        </div>
                        ) : null
                    }
                    {
                        arrayItem && getIn(touched, field.name) && getIn(errors, field.name) ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{getIn(errors, field.name)}</div>
                        </div>
                        ) : null
                    }
                    {note}
                </div>
    return view;
};