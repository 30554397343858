import React from 'react';

import './Dashboard.scss';
import registration from '../../asset/icons/registration.png';
import {Widget} from '../shared';

export const Dashboard = ()=>{
    const view = <div className="et-dashboard">
        <div className="row">
            {/* widget section */}
            <div className="col-12 col-md-8 col-lg-8 row widgets">
                {/* <div className="col-6 col-lg-3 widget-container">
                    <Widget 
                        name="Settings"
                        icon = {registration}
                        link="/" />
                </div>
                <div className="col-6 col-lg-3 widget-container">
                    <Widget 
                        name="Batches"
                        icon = {registration}
                        link="/" />
                </div>
                <div className="col-6 col-lg-3 widget-container">
                    <Widget 
                        name="Students"
                        icon = {registration}
                        link="/" />
                </div>
                <div className="col-6 col-lg-3 widget-container">
                    <Widget 
                        name="Staff"
                        icon = {registration}
                        link="/" />
                </div> */}
                <div className="col-6 col-lg-3 widget-container">
                    <Widget 
                        name="Registration"
                        icon = {registration}
                        link="registration" />
                </div>
                {/* other widgets comes here */}
            </div>
            <div className="col-12 col-md-4">
                {/* TODO:: Calendar and recent activicties */}
            </div>
        </div>
    </div>;
    return view;
}
