import React, { useContext, useState, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';

import {TextInput, SelectInput, DateInput, SubHeading, UtilityService} from '../../../../shared';
import { GlobalContext } from "../../../../../../global-context/GlobalContext";
import { useLocation } from 'react-router-dom';
import { RegContext } from '../../../RegContext';

export const BtechLetForm1 = () => {
  const { sharedData } = useContext(GlobalContext);
 
  const {values, setValues} = useFormikContext();
  const { student } = useContext(RegContext);
  const [casteList, setCasteList] = useState([]);
  const location = useLocation();
useEffect(() => {
  if(location.pathname.indexOf('edit-details') > -1){
    setValues(student);
  }
  if(sharedData && sharedData.caste_list){
    const filterdCaste = UtilityService.filterById(sharedData.caste_list, values.religion_id, 'religion_id');
    setCasteList(filterdCaste);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[values.religion_id]

);
  const view = (
    <React.Fragment>
      <SubHeading heading="Personal Details" />
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="first_name"
            component={TextInput}
            label="FIRST NAME"
            type="text"
            required={true}
            placeholder="FIRST NAME"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="middle_name"
            component={TextInput}
            label="MIDDLE NAME"
            type="text"
            required={true}
            placeholder="MIDDLE NAME"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="last_name"
            component={TextInput}
            label="LAST NAME"
            type="text"
            required={true}
            placeholder="LAST NAME"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="gender"
            component={SelectInput}
            label="GENDER"
            required={true}
            options={sharedData && sharedData.gender? sharedData.gender : []}
            optionKey="value"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="dob"
            component={DateInput}
            label="DATE OF BIRTH"
            required={true}
            endDate={new Date()}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="mother_tongue"
            component={TextInput}
            label="MOTHER TONGUE"
            type="text"
            placeholder="MOTHER TONGUE"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="pob"
            component={TextInput}
            label="PLACE OF BIRTH"
            type="text"
            required={true}
            placeholder="PLACE OF BIRTH"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="nationality"
            component={TextInput}
            label="NATIONALITY"
            type="text"
            placeholder="NATIONALITY"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="religion_id"
            component={SelectInput}
            label="RELIGION"
            required={true}
            options={sharedData && sharedData.religion_list? sharedData.religion_list : []}
            optionKey="name"
           
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="caste_id"
            component={SelectInput}
            label="CASTE"
            required={true}
            //options={sharedData && sharedData.caste_list? sharedData.caste_list : []}
            optionKey="name"
            options={casteList}
           
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="eligible_reservation_category"
            component={SelectInput}
            label="ELIGIBLE RESERVATION CATEGORY"
            options={sharedData && sharedData.eligible_reservation_category? sharedData.eligible_reservation_category : []}
            optionKey="value" />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="eco_backward"
            component={SelectInput}
            label="ECONOMICALY BACKWARD"
            options={["No", "Yes"]}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="blood_group"
            component={SelectInput}
            label="BLOOD GROUP"
            options={sharedData && sharedData.blood_group? sharedData.blood_group : []}
            optionKey="value" />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="adharno"
            component={TextInput}
            label="AADHAR NO"
            type="number"
            required={true}
            placeholder="AADHAR NO"
            
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="differently_abled"
            component={SelectInput}
            label="DIFFERENTLY ABLED"
            options={["No", "Yes"]}
          />
        </div>
      </div>
    </React.Fragment>
  );
  return view;
};