import React from "react";
import { Field } from "formik";

import {TextInput, SelectInput, SubHeading} from '../../../../shared';

export const MtechForm7 = () => {
  const view = (
    <React.Fragment>
      <SubHeading heading="Personal Marks for identification" />
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="personal_marks_1"
            component={TextInput}
            label="PERSONAL MARKS 1"
            required={true}
            placeholder="PERSONAL MARK 1"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="personal_marks_2"
            component={TextInput}
            label="PERSONAL MARKS 2"
            required={true}
            placeholder="PERSONAL MARKS 2"
          />
        </div>
      </div>
      <SubHeading heading="Bank Details" />
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="bank_name"
            component={TextInput}
            label="NAME OF BANK"
            required={true}
            placeholder="NAME OF BANK"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="bank_accno"
            component={TextInput}
            label="ACCOUNT NO"
            type="text"
            required={true}
            placeholder="ACCOUNT NO"
          />
        </div>

        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="bank_branch"
            component={TextInput}
            label="NAME OF BRANCH"
            required={true}
            placeholder="NAME OF BRANCH"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="bank_ifsc_code"
            component={TextInput}
            label="IFSC CODE"
            required={true}
            placeholder="IFSC CODE"
          />
        </div>
      </div>
      <SubHeading heading="Hostel Facilities" />
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="is_hostler"
            component={SelectInput}
            label="DO YOU NEED HOSTEL FACILITIES"
            options={[{value: "Yes", id: 1}, {value: "No", id:0}]}
            optionKey="value"
          />
        </div>
      </div>
    </React.Fragment>
  );
  return view;
};
