import React from "react";
import { Field } from "formik";

import {TextInput, SelectInput, DateInput, SubHeading} from '../../../../shared';

export const PhdForm5 = () => {
  const view = (
    <React.Fragment>
        <SubHeading heading="Secondary School Details" />
        <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <Field name="secondary_board"
                    component={TextInput}
                    label="Board" 
                    required={true}
                    placeholder="BOARD"
                    />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <Field name="secondary_regno"
                    component={TextInput}
                    label="Registration No"
                    type="text"
                    required={true} 
                    placeholder="REG NO"/>  
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <Field name="secondary_year"
                    component={SelectInput}
                    label="Year"
                    required={true}
                    options= {["2001", "2002","2003"]} />
            </div>
        </div>
        <SubHeading heading="TC Details" />
        <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <Field name="mtech_college"
                    component={TextInput}
                    label="Last College" 
                    required={true} 
                    placeholder="LAST COLLEGE"/> 
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <Field name="mtech_tc_no"
                    component={TextInput}
                    label="TC No"
                    type="text"
                    required={true} 
                    placeholder="TC NO"/>  
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <Field name="mtech_tc_date"
                    component={DateInput}
                    label="TC Date"
                    required={true} />
            </div>
        </div>
      </React.Fragment>
  );
  return view;
};
