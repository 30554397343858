import React, { useEffect, useContext } from "react";
import { Switch, Route } from "react-router-dom";

import "./Registration.scss";
import btechReg from "../../asset/icons/btech-reg.png";
import btechLat from "../../asset/icons/btech-lat.png";
import mtechAll from "../../asset/icons/mtech-all.png";
import phdAll from "../../asset/icons/phd-all.png";
import admission from "../../asset/icons/admission.png";
import approve from "../../asset/icons/approve.png";
import archive from "../../asset/icons/archive.png";
import slip from "../../asset/icons/slip.png";
import submit from "../../asset/icons/submit.png";
import verify from "../../asset/icons/verify.png";
import manage from "../../asset/icons/manage.png";
import { GlobalContext } from "../../../global-context/GlobalContext";
import BtechReg from "./new-registration/btech-reg/BtechReg";
import BtechLet from "./new-registration/btech-let/BtechLet";
import MtechAll from "./new-registration/mtech-all/MtechAll";
import PhdAll from "./new-registration/phd-all/PhdAll";
import VerifyAssign from "./verify-assign/VerifyAssign";
import Admission from "./admission/Admission";
import ApproveAdmission from "./approve-admission/ApproveAdmissoion";
import DocSubmit from "./doc-submit/DocSubmit";
import Manage from "./manage/Manage";
import Archive from "./archive/Archive";
import AdSlip from "./ad-slip/AdSlip";
import { ApiService, Widget } from "../shared";
import RegistrationSettings from "./registration-settings/RegistrationSettings";
import { RegProvider } from "./RegContext";
import StudentDetails from "./student-details/StudentDetails";
import Assign from "./batch-assign/Assign";

const Registration = () => {
  const { sharedData, setSharedData, setLoader, setError } = useContext(
    GlobalContext
  );
  useEffect(() => {
    // fetch shared data from backend
    const fetchSharedData = async () => {
      setLoader(true);
      const data = await ApiService.getData("/commons/form-data");
      if (data.error) {
        setError({
          type: "Error",
          data: [data.message],
        });
      } else if (data) {
        setSharedData(data);
      }
      setLoader(false);
    };
    if (!sharedData) {
      fetchSharedData();
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  const view = (
    <div className="et-registration">
      <RegProvider>
        <Switch>
          <Route exact path="/registration">
            <div className="row et-registration-main">
              <div className="col-12 col-md-6 col-lg-5 card">
                <div className="card-header">New Registration</div>
                <div className="card-body row">
                  <div className="col-6 widget-container">
                    <Widget
                      name="B-Tech(REGULAR)"
                      icon={btechReg}
                      link="/registration/btech-regular"
                    />
                  </div>
                  <div className="col-6 widget-container">
                    <Widget
                      name="B-Tech(LATERAL ENTRY)"
                      icon={btechLat}
                      link="/registration/btech-let"
                    />
                  </div>
                  <div className="col-6 widget-container">
                    <Widget
                      name="M-Tech(ALL)"
                      icon={mtechAll}
                      link="/registration/mtech-all"
                    />
                  </div>
                  <div className="col-6 widget-container">
                    <Widget
                      name="PhD(ALL)"
                      icon={phdAll}
                      link="/registration/phD-all"
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-7 card">
                <div className="card-header">Post Registration</div>
                <div className="card-body row widgets">
                  <div className="col-6 col-lg-4 widget-container">
                    <Widget
                      name="Verification & Batch Assignation"
                      icon={verify}
                      link="/registration/verification"
                    />
                  </div>
                  <div className="col-6 col-lg-4 widget-container">
                    <Widget
                      name="Admission"
                      icon={admission}
                      link="/registration/admission"
                    />
                  </div>
                  <div className="col-6 col-lg-4 widget-container">
                    <Widget
                      name="Approve Admission"
                      icon={approve}
                      link="/registration/approval"
                    />
                  </div>
                  <div className="col-6 col-lg-4 widget-container">
                    <Widget
                      name="Document Submission"
                      icon={submit}
                      link="/registration/document-submission"
                    />
                  </div>
                  <div className="col-6 col-lg-4 widget-container">
                    <Widget
                      name="Manage"
                      icon={manage}
                      link="/registration/manage"
                    />
                  </div>
                  <div className="col-6 col-lg-4 widget-container">
                    <Widget
                      name="Archive"
                      icon={archive}
                      link="/registration/archive"
                    />
                  </div>
                  <div className="col-6 col-lg-4 widget-container">
                    <Widget
                      name="Admission Slip"
                      icon={slip}
                      link="/registration/admission-slip"
                    />
                  </div>
                  <div className="col-6 col-lg-4 widget-container">
                    <Widget
                      name="Registration Settings"
                      icon={slip}
                      link="/registration/registration-settings"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Route>
          <Route path="/registration/btech-regular" component={BtechReg} exact />
          <Route path="/registration/:refNo/btech-edit-details" component={BtechReg} exact />
          <Route path="/registration/btech-let" component={BtechLet} exact />
          <Route path="/registration/:refNo/btech-let-edit-details" component={BtechLet} exact />
          <Route path="/registration/mtech-all" component={MtechAll} exact />
          <Route path="/registration/:refNo/mtech-edit-details" component={MtechAll} exact />
          <Route path="/registration/phD-all" component={PhdAll} exact />
          <Route path="/registration/:refNo/phD-edit-details" component={PhdAll} exact />
          <Route path="/registration/verification" component={VerifyAssign} />
          <Route path="/registration/admission" component={Admission} exact />
          <Route path="/registration/approval" component={ApproveAdmission} />
          <Route
            path="/registration/document-submission"
            component={DocSubmit}
          />
          <Route path="/registration/manage" component={Manage} />
          <Route path="/registration/archive" component={Archive} />
          <Route path="/registration/admission-slip" component={AdSlip} />
          <Route
            path="/registration/registration-settings"
            component={RegistrationSettings}
          />
          <Route path="/registration/:refNo" exact>
              <StudentDetails />
          </Route>
          <Route path="/registration/:refNo/assign">
              <Assign />
          </Route>
        </Switch>
      </RegProvider>
    </div>
  );
  return view;
};
export default Registration;
