import React from "react";
import Recaptcha from 'react-recaptcha';

import {SubHeading} from "../../../../shared";
import { useFormikContext } from "formik";
import AppConstants from "../../../../../AppContants";

export const PhdForm11 = () => {
  const {setFieldValue }  = useFormikContext();
  const sitekey = AppConstants.sitekey;

  const onCaptchaVerify = ()=>{
    setFieldValue('captcha', 'verified', true);
  }
  const onCaptchaExpire= ()=>{
    setFieldValue('captcha', '', true);
  }
  const view = (
    <React.Fragment>
      <SubHeading heading="Please verify that you are not robot" />
      <div className="d-flex pt-16 pb-8 align-items-center justify-content-center">
        <Recaptcha
          sitekey={sitekey}
          render="explicit"
          verifyCallback={onCaptchaVerify}
          expiredCallback = {onCaptchaExpire}
        />
      </div>
    </React.Fragment>
  );
  return view;
};