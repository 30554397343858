import React, { useContext } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { GlobalContext } from "../../../../global-context/GlobalContext";
import { RegContext } from "../RegContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag, faPrint, faEdit, faDownload } from "@fortawesome/free-solid-svg-icons";
import { ApiService } from "../../shared";
import AppConstants from "../../../AppContants";

const status = AppConstants.registration.status;
const StudentDetailsButtonDrop = () => {
  const url = useLocation().pathname;
  const history = useHistory();
  const { setSpinner,setError, setInfo } = useContext(GlobalContext);
  const { student, setStudent } = useContext(RegContext);

  /**
   * on click of verify link
   */
  const verify = async () => {
    if(window.confirm("Are you sure that you want to verify?")){
        setSpinner(true);
        const data = await ApiService.getData("/registration/registers/verify?refference_no="+student.refference_no);
        if (data.error) {
          setError({
            type: "Error",
            data: [data.message],
          });
        } else if (data && data.status === status.studentVerified) {
            setStudent(data); // set reg context
            history.push(url + '/assign');
        }
        setSpinner(false);
    }
  };

  const admit = async () => {
    if(window.confirm("Are you sure that you want to admit?")){
        setSpinner(true);
        const data = await ApiService.getData("/registration/registers/student-admit?refference_no="+student.refference_no);
        if (data.error) {
          setError({
            type: "Error",
            data: [data.message],
          });
        } else if (data && data.status === status.studentAdmitted) {
          setInfo({
            type: "Info",
            data: ["Student Admitted. Amission number is " + data.admission_no],
          });
            setStudent(data); // set reg context
        }
        setSpinner(false);
    }
  };

  const approve = async () => {
    if(window.confirm("Are you sure that you want to approve?")){
        setSpinner(true);
        const data = await ApiService.getData("/registration/registers/student-approve?refference_no="+student.refference_no);
        if (data.error) {
          setError({
            type: "Error",
            data: [data.message],
          });
        } else if (data && data.status === status.studentActive) {
          setInfo({
            type: "Info",
            data: ["Student Approved"],
          });
            setStudent(data); // set reg context
        }
        setSpinner(false);
    }
  };

  const editStudent = ()=>{
    switch(student.reg_type){
      case "BTECH": history.push("/registration/" + student.refference_no + "/btech-edit-details");
      break;
      case "BTECH(Lateral Entry)": history.push("/registration/" + student.refference_no + "/btech-let-edit-details");
      break;
      case "MTECH": history.push("/registration/" + student.refference_no + "/mtech-edit-details");
      break;
      case "PhD": history.push("/registration/" + student.refference_no + "/phD-edit-details");
      break;
      default: break;
    }
  }

  const view = (
    <div className="d-flex flex-column p-2 content-layout-btn-drop">
      {
        student && (student.status === status.studentJustRegistered ||
          student.status === status.studentRegisteredInternal) && (
        <button type="button" className="btn btn-link" onClick={verify}>
          <span className="section-header-icon pr-1">
            <FontAwesomeIcon icon={faTag} />
          </span>
          Verify
        </button>
      )}
      {
        student && student.status === status.studentVerified && (
        <button type="button" className="btn btn-link">
          <Link to={url+"/assign"}>
            <span className="section-header-icon pr-1">
              <FontAwesomeIcon icon={faTag} />
            </span>
            Assign
          </Link>
        </button>
      )}
      {
        student && student.status === status.studentBatchAssigned && (
        <button type="button" className="btn btn-link" onClick={admit}>
          <span className="section-header-icon pr-1">
            <FontAwesomeIcon icon={faTag} />
          </span>
          Admit
        </button>
      )}
      {
        (student && (student.status === status.studentBatchAssigned ||
          student.status === status.studentActive)) && (
        <button type="button" className="btn btn-link" >
          <Link to={url+"/assign"}>
            <span className="section-header-icon pr-1">
              <FontAwesomeIcon icon={faEdit} />
            </span>
            Update Batch
          </Link>
        </button>
      )}
      {
        student && student.status === status.studentAdmitted && (
        <button type="button" className="btn btn-link" onClick={approve}>
          <span className="section-header-icon pr-1">
            <FontAwesomeIcon icon={faTag} />
          </span>
          Approve
        </button>
      )}
      {
        student && student.status === status.studentAdmitted && (
        <button type="button" className="btn btn-link" >
          <span className="section-header-icon pr-1">
            <FontAwesomeIcon icon={faDownload} />
          </span>
          Admission Slip
        </button>
      )}
      {
        student && student.status !== status.studentAdmitted &&
          student.status !== status.studentActive &&(
          <button type="button" className="btn btn-link" onClick={editStudent}>
            <span className="section-header-icon pr-1">
              <FontAwesomeIcon icon={faTag} />
            </span>
            Edit Details
          </button>
        )
      }
      {
        student && (
        <button type="button" className="btn btn-link" >
          <span className="section-header-icon pr-1">
            <FontAwesomeIcon icon={faPrint} />
          </span>
          Print Details
        </button>
      )}
    </div>
  );
  return view;
};
export default StudentDetailsButtonDrop;
