import React from 'react';

export const DetailsGridItem = ({label, value})=>{
    const view = (
        <div className="d-flex flex-column">
            <div className="pb-2 pt-3 text-bold">
                {label}
            </div>
            <div className="pb-3">
                {value}
            </div>
        </div>
    )
    return view;
}