import React, { useContext, useState } from 'react';
import { faFileArchive, faList } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

import './Archive.scss';import {
    ContentLayout,
    Table,
    UtilityService,
    ApiService,
  } from "../../shared";

import { Route,Switch } from 'react-router-dom';
import { RegContext } from "../RegContext";
import { GlobalContext } from "../../../../global-context/GlobalContext";
import AppConstants from "../../../AppContants";

const headerData = [
    {"text": "Reference No","sort": true, "filter": true, "filterKey": "ref_no", "filterValue": ""},
    {"text": "Admission No","sort": true,"filter": true, "filterKey": "admission_no", "filterValue": ""},
    {"text": "Full Name","sort": true,"filter": true, "filterKey": "first_name", "filterValue": ""},
    {"text": "Course Opted","sort": true,"filter": true, "filterKey": "course_id", "filterValue": ""},
    {"text": "Academic Year","sort": true,"filter": true, "filterKey": "academic_year", "filterValue": ""},
    {"text": "Status","sort": true,"filter": true, "filterKey": "status", "filterValue": ""},
    {"text": "Is Archived","sort": true,"filter": true, "filterKey": "is_archived", "filterValue": ""}
];

const Archive = () => {
    //const url = useLocation().pathname;
    const { setStudent } = useContext(RegContext);
    const { setError, setSpinner } = useContext(GlobalContext);

    const initialTableData = {
        list: [],
        totalCount: 0,
        currentPage: 1,
        pageSize: 10
    }
    const [tableData, setTableData] = useState(initialTableData);
    // fetch all student registers form backend based on query
    const fetchRegisters = async (query) => {
        setSpinner(true);
        let data = await ApiService.getData("/registration/registers" + query);
        if (data.error) {
        setError({
            type: "Error",
            data: [data.message],
        });
        } else if (data && data.grid && data._meta) {
        // data = dummyData;
        tableData.list = UtilityService.concatProperties(data.grid, ['first_name', 'middle_name', 'last_name'], 'full_name');
        tableData.totalCount  =data._meta.totalCount;
        setTableData(tableData);
        }
        setSpinner(false);
    };

    /**
     * pagination event from table
     * @param query 
     */
    const pageChange = (query)=>{
        fetchRegisters(query);
    }
    const view = <div className="et-archive ">
        <Switch>
            <Route exact path="">
                <ContentLayout
                    headerText="Archive"
                    icon={faFileArchive}
                >
                    <div className="row ">
                        <div className="col-xs-12 col-sm-8 col-md-6 col-lg-7 d-flex align-items-center">
                            <span className="text-bold pr-7">Select Academic Year </span>
                            <select
                                className="form-control"
                            >
                                <option value="">Select Academic Year</option>
                            </select>
                        </div>
                    </div>
                    <div className="row pt-6">
                        <div className="col-xs-12 col-sm-8 col-md-6 col-lg-7 d-flex align-items-center">
                            <span className="text-bold">Select Registration Status</span>
                            <select
                                className="form-control"
                            >
                                <option value="">Select Registration Status</option>
                            </select>
                        </div>
                    </div>
                    <Button type="submit" className="btn-success mt-6" >Archive</Button>
                </ContentLayout>

                <ContentLayout
                    headerText="Student List"
                    icon={faList}
                >
                {
                    tableData && tableData.list &&
                    <Table 
                        headerData = {headerData}
                        tableData = {tableData}
                        links = {AppConstants.registration.archive.links}
                        linkUrlKey = {AppConstants.registration.archive.linkUrlKey}
                        baseUrl = {AppConstants.registration.archive.baseUrl}
                        keyList = {AppConstants.registration.archive.keyList}
                        studentHandler = {(student)=>{
                            setStudent(student)
                        }}
                        pageHandler = {pageChange}
                    />
                }
                </ContentLayout>
            </Route>
        </Switch>
    </div>;
    return view;
}
export default Archive;
