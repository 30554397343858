import React, { createContext, useReducer } from 'react';

import regReducer from './RegReducer';

const initialState = {
    student: null
};

export const RegContext = createContext(initialState);
export const RegProvider = ({children})=>{
    const [state, dispatch] = useReducer(regReducer, initialState);
    const setStudent = (student)=>{
        dispatch({
            type: 'SET_STUDENT',
            payload: student
        })
    };
    
    const context = {
        student: state.student,
        setStudent
    };
    return (
        <RegContext.Provider value = {context}>
            {children}
        </RegContext.Provider>
    )
}
