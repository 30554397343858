import React, { useState, useContext } from "react";
import { useFormikContext } from "formik";

import "./SwitchPage.scss";
import { GlobalContext } from "../../../../global-context/GlobalContext";

export const SwitchPage = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { errors, values } = useFormikContext();
  const {setError} = useContext(GlobalContext);

  const pageSize = props.pageList.length;
  const handleNext = () => {
    setCurrentIndex(currentIndex + 1);
    props.switchHandler(currentIndex + 2);
  };
  const handlePrevious = () => {
    setCurrentIndex(currentIndex - 1);
    props.switchHandler(currentIndex);
  };
  const goToPage = (index)=>{
    setCurrentIndex(index);
    props.switchHandler(index + 1);
  }
  const register = () => {
    // props.registerHandler(values);
    if (Object.entries(errors).length === 0) {
      // props.registerHandler(values);
    } else {
      //console.log(errors)
      console.log(values)
      const validationMsgs = [];
      for(const key in errors){
        if(Array.isArray(errors[key])){
          validationMsgs.push(key + " are required");
        } else {
          validationMsgs.push(errors[key]);
        }
      }
      //console.log(values);
      setError({
        type: "Error",
        data: validationMsgs
      });
    }
  };
  let nextButton = (
    <div>
      <button type="button" className="btn btn-success" onClick={handleNext}>
        Next
      </button>
    </div>
  );
  if (currentIndex === pageSize - 1) {
    nextButton = (
      <div>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={() => register()}
        >
          Register
        </button>
      </div>
    );
  }

  let prevButton = (
    <div>
      <button
        type="button"
        className="btn btn-success"
        onClick={handlePrevious}
      >
        Previous
      </button>
    </div>
  );
  if (currentIndex === 0) {
    prevButton = "";
  }

  const view = (
    <div className="switch-page">
      <div className="switch-icons">
        {props.pageList.map((page, index) => {
          let visitedStyle = "";
          if (index < currentIndex) {
            visitedStyle = "visited";
          } else if (index === currentIndex) {
            visitedStyle = "current";
          }
          return (
            <span key={page.id}
              className={`page-circl-icon ${visitedStyle}`}
              onClick={()=>goToPage(index)}>
              {index + 1}
            </span>
          );
        })}
      </div>
      <div className="switch-btns">
        {prevButton}
        {nextButton}
      </div>
    </div>
  );
  return view;
};
