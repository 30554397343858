import React, { useEffect, useState, useContext } from "react";
import { Field, useFormikContext, FieldArray } from "formik";

import {
  TextInput,
  SelectInput,
  SubHeading,
  ApiService,
} from "../../../../shared";
import { GlobalContext } from "../../../../../../global-context/GlobalContext";
import { RegContext } from "../../../RegContext";
import { useLocation } from "react-router-dom";

export const BtechRegForm3 = () => {
  const [selectedBoard, setSelectedBoard] = useState(null);
  const { setError, setSpinner } = useContext(GlobalContext);
  const { values, setFieldValue } = useFormikContext();
  const [boards, setBoards] = useState([]);
  const {student} = useContext(RegContext);
  const location = useLocation();
  useEffect(() => {
    fetchBoardData();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  // fetch saved board data from backend
  const fetchBoardData = async () => {
    setSpinner(true);
    const data = await ApiService.getData("/registration/boards");
    if (data.error) {
      setError({
        type: "Error",
        data: [data.message],
      });
    } else if (data) {
      setBoards(JSON.parse(JSON.stringify(data)));
    }
    setSpinner(false);
  };

  /**
   * execute only once when board are fetched
   */
  useEffect(() => {
    for (const board of boards) {
      if (board.id === parseInt(values.highersecondary_board)) {
        setSelectedBoard(board);
      }
    }

    if (location.pathname.indexOf("edit-details") > -1 && student) {
      if(student.plusone_subjects){
        setFieldValue("plusone_subjects", student.plusone_subjects, true);
      }
      if(student.plustwo_subjects){
        setFieldValue("plustwo_subjects", student.plustwo_subjects, true);
      }
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [boards]);

  useEffect(() => {
    if (selectedBoard) {
      let subjectsTot = 0;
      let subjectsMaxTot = 0;
      // calculate plustwo individual subject pct &
      // calculate plus two merit subjects pct
      if (selectedBoard.plustwo_subjects) {
        for (const subject of selectedBoard.plustwo_subjects) {
          for (const sub of values.plustwo_subjects) {
            if (subject.id === sub.id) {
              sub.subject_percentage = (
                (sub.subject_mark * 100) /
                subject.subject_max_mark
              ).toFixed(2);
              subjectsMaxTot = subjectsMaxTot + subject.subject_max_mark;
              if(sub.subject_mark !== ""){
                subjectsTot = subjectsTot + sub.subject_mark;
              }
            }
          }
        }
      }

      // calculate plusone individual subject pct &
      // calculate plusone merit subjects pct
      if (selectedBoard.plusone_subjects) {
        // let subjectsTot = 0;
        // let subjectsMaxTot = 0;
        for (const subject of selectedBoard.plusone_subjects) {
          for (const sub of values.plusone_subjects) {
            if (subject.subject_name === sub.subject_name) {
              sub.subject_percentage = (
                (sub.subject_mark * 100) /
                subject.subject_max_mark
              ).toFixed(2);
              subjectsMaxTot = subjectsMaxTot + subject.subject_max_mark;
              if(sub.subject_mark !== ""){
                subjectsTot = subjectsTot + sub.subject_mark;
              }
            }
          }
        }
      }
      const subjectsPct = ((subjectsTot * 100) / subjectsMaxTot).toFixed(2);
      setFieldValue("merit_subjects_pct", subjectsPct, true);

      setFieldValue("plustwo_subjects", values.plustwo_subjects, true);
      setFieldValue("plusone_subjects", values.plusone_subjects, true);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [values.plustwo_subjects, values.plusone_subjects]);

  /**
   * on changing the board
   * populate formik model values with selected board data
   */
  useEffect(() => {
    boardChange();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [values.highersecondary_board]);

  /**
   * on board change. called on load on edit details and on changing the board always
   */
  const boardChange = ()=>{
    for (const board of boards) {
      if (board.id === parseInt(values.highersecondary_board)) {
        setSelectedBoard(board);
        values.plustwo_subjects = [];
        if (board.plustwo_subjects && Array.isArray(board.plustwo_subjects)) {
          for (const subject of board.plustwo_subjects) {
            values.plustwo_subjects.push({
              id: subject.id,
              subject_name: subject.subject_name,
              subject_mark: "",
              subject_percentage: "",
            });
          }
        }
        values.plusone_subjects = [];
        if (board.plusone_subjects && Array.isArray(board.plusone_subjects)) {
          for (const subject of board.plusone_subjects) {
            values.plusone_subjects.push({
              id: subject.id,
              subject_name: subject.subject_name,
              subject_mark: "",
              subject_percentage: "",
            });
          }
        }
        setFieldValue("course_option", board.course_option, true);
        setFieldValue("course_combination", board.course_combination, true);
      }
    }
    setFieldValue("plustwo_subjects", values.plustwo_subjects, true);
    setFieldValue("plusone_subjects", values.plusone_subjects, true);
  }

  /**
   * on changing the overall mark(combined scenario)
   * on changing the plus two overall mark(combined/separate scenario)
   * on changing plus one overall mark(separate scenario)
   */
  useEffect(() => {
    if (selectedBoard) {
      if (selectedBoard.course_combination === "Combined") {
        // calculating overall percentage
        const overallTot =
          selectedBoard.plusone_max_mark + selectedBoard.plustwo_max_mark;
        const overallPct = ((values.overall_mark * 100) / overallTot).toFixed(
          2
        );
        setFieldValue("overall_pct", overallPct, true);
      } else if (selectedBoard.course_combination === "Separate") {
        // calculating plutwo overall percentage
        const overall_plustwo_tot = selectedBoard.plustwo_max_mark;
        const overall_plustwo_pct = (
          (values.overall_plustwo_mark * 100) /
          overall_plustwo_tot
        ).toFixed(2);
        setFieldValue("overall_plustwo_pct", overall_plustwo_pct, true);

        // calculating plusone overall percentage
        const overall_plusone_tot = selectedBoard.plusone_max_mark;
        const overall_plusone_pct = (
          (values.overall_plusone_mark * 100) /
          overall_plusone_tot
        ).toFixed(2);
        setFieldValue("overall_plusone_pct", overall_plusone_pct, true);
      } else if (values.course_option === "Plus Two Only") {
        // calculating plutwo overall percentage
        const overall_plustwo_tot = selectedBoard.plustwo_max_mark;
        const overall_plustwo_pct = (
          (values.overall_plustwo_mark * 100) /
          overall_plustwo_tot
        ).toFixed(2);
        setFieldValue("overall_plustwo_pct", overall_plustwo_pct, true);
      }
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [
    values.overall_mark,
    values.overall_plusone_mark,
    values.overall_plustwo_mark,
  ]);
  const view = (
    // boards.map((item, index) => {
    <React.Fragment>
      <SubHeading heading="Higher Secondary School Details" />
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="highersecondary_board"
            component={SelectInput}
            label="BOARD"
            required={true}
            options={boards}
            optionKey="board"
          />
          {/* {item.board} */}
        </div>

        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="highersecondary_year"
            component={SelectInput}
            label="YEAR"
            required={true}
            options={["2000", "2001", "2002", "2003", "2004", "2005"]}
          />
        </div>

        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="highersecondary_regno"
            component={TextInput}
            label="REG NO"
            required={true}
            placeholder=" REG NO"
          />
        </div>
      </div>
      {selectedBoard && (
        <SubHeading heading="Higher Secondary Percentage Obtained" />
      )}
      {/* course option plus & plus two */}
      <div className="row">
        {/* plus two subject section */}
        {selectedBoard &&
          selectedBoard.course_option &&
          selectedBoard.course_option === "Plus One & Plus Two" &&
          selectedBoard.plusone_subjects &&
          Array.isArray(selectedBoard.plusone_subjects) && (
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 border-right">
              <div className="text-bold pb-2 text-center">
                Plus One Subjects
              </div>
              <SubjectsTable
                subjects={selectedBoard.plusone_subjects}
                arrayModelName="plusone_subjects"
              />
              {selectedBoard.course_combination &&
                selectedBoard.course_combination !== "Combined" && (
                  <>
                    <hr />
                    <div className="row">
                      <div className="col-xs-12 col-6">
                        <Field
                          label="Overall Mark"
                          name="overall_plusone_mark"
                          type="number"
                          component={TextInput}
                        />
                      </div>
                      <div className="col-xs-12 col-6">
                        <Field
                          label="Overall Percentage"
                          name="overall_plusone_pct"
                          component={TextInput}
                          type="number"
                          disabled={true}
                        />
                      </div>
                    </div>
                  </>
                )}
            </div>
          )}
        {/* plus two subject section */}
        {selectedBoard &&
          selectedBoard.plustwo_subjects &&
          Array.isArray(selectedBoard.plustwo_subjects) && (
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <div className="text-bold pb-2 text-center">
                Plus Two Subjects
              </div>
              <SubjectsTable
                subjects={selectedBoard.plustwo_subjects}
                arrayModelName="plustwo_subjects"
              />
              {selectedBoard &&
                selectedBoard.plustwo_subjects &&
                Array.isArray(selectedBoard.plustwo_subjects) && (
                  <>
                    {selectedBoard.course_combination !== "Combined" && (
                      <>
                        <hr />
                        <div className="row">
                          <div className="col-xs-12 col-6">
                            <Field
                              label="Overall Mark"
                              name="overall_plustwo_mark"
                              type="number"
                              component={TextInput}
                            />
                          </div>
                          <div className="col-xs-12 col-6">
                            <Field
                              label="Overall Percentage"
                              name="overall_plustwo_pct"
                              component={TextInput}
                              type="number"
                              disabled={true}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
            </div>
          )}
      </div>
      <hr />
      {selectedBoard &&
        selectedBoard.course_option === "Plus One & Plus Two" &&
        selectedBoard.course_combination &&
        selectedBoard.course_combination === "Combined" && (
          <div className="row">
            <div className="col-4">
              <Field
                label="Overall Mark"
                name="overall_mark"
                type="number"
                component={TextInput}
              />
            </div>
            <div className="col-4">
              <Field
                label="Overall Percentage"
                name="overall_pct"
                component={TextInput}
                type="number"
                disabled={true}
              />
            </div>
          </div>
        )}
        {
          selectedBoard && 
          <>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <Field
                  label="Merit Subjects Percentage"
                  name="merit_subjects_pct"
                  component={TextInput}
                  type="number"
                  disabled={true}
                />
              </div>
            </div>
          </>
        }
    </React.Fragment>
  );

  return view;
};

const SubjectsTable = ({ subjects, arrayModelName }) => {
  const view = (
    <FieldArray
      name="plusone_subjects"
      render={(arrayHelpers) => (
        <div className="table-responsive">
          <table className="table table-borderd">
            <thead>
              <tr>
                <th className="text-uppercase">Subject&nbsp;Name</th>
                <th className="text-uppercase">Subject Mark</th>
                <th className="text-uppercase">Percentage</th>
              </tr>
            </thead>
            <tbody>
              {subjects &&
                Array.isArray(subjects) &&
                subjects.map((subject, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-uppercase">{subject.subject_name}</td>
                      <td className="">
                        <Field
                          name={`${arrayModelName}[${index}].subject_mark`}
                          component={TextInput}
                          placeholder="Subject Mark"
                          type="number"
                          arrayItem={true}
                        />
                      </td>
                      <td>
                        <Field
                          name={`${arrayModelName}[${index}].subject_percentage`}
                          component={TextInput}
                          type="number"
                          disabled={true}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    />
  );
  return view;
};
