import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCircle } from '@fortawesome/free-solid-svg-icons';

const Breadcrumb = () => {
    const location = useLocation();
    let [breadcrumb, setBreadcrumb] = useState([]);

    React.useEffect(() => {
       if(location){       
        let urlItems = location.pathname.split('/');
        if(urlItems[1] === '' || urlItems[1] === 'dashboard'){
          urlItems.shift();
        }
        urlItems[0] = 'dashboard';
        const brdcrmb = [];
        for(const item of urlItems){
          const dispValue = item.replace(/-/g, " ");
          brdcrmb.push({
            dispValue: dispValue,
            link: item
          })
        }
        setBreadcrumb(brdcrmb);
       }
    }, [location]);

    let link = '';
    const view = <div className="et-breadcrumb">
      <span className="bread-home-icon">
        <FontAwesomeIcon icon={faHome}  />
      </span>
      {
        breadcrumb.map((bread, i)=>{
          if(bread.link === ""){
            return '';
          }
          let disabledBreadClass = ''; 
          let seperator = <FontAwesomeIcon className="bread-crumb-seperator" icon={faCircle} />;
          if(i === breadcrumb.length-1){
            seperator = '';
            disabledBreadClass = 'text-dark-50 font-weight-bolder font-size-base';
          }
          if(i === 0){
            link = 'dashboard';
          } else if(i===1){
            link = bread.link;
          } else {
            link = link + '/' + bread.link;
          }
          return (
            <React.Fragment key={i}>
              <Link to={`/${link}`}>
                <span className={`breadcrumb-item ${disabledBreadClass}`}>{bread.dispValue}</span>
              </Link>
              {seperator}
            </React.Fragment>
          )
        })
      }
    </div>;
    return view;
}
export default Breadcrumb;