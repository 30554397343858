import React, { useEffect, useState, useContext } from "react";
import { Field, useFormikContext } from "formik";

import { TextInput, SelectInput, SubHeading } from '../../../../shared';
import { GlobalContext } from "../../../../../../global-context/GlobalContext";

export const MtechForm2 = () => {
  const { values } = useFormikContext();
  const { sharedData } = useContext(GlobalContext);
  const [disbaleGateRank, setDisableGateRank] = useState(false);
  const [disableGateScore, setDisableGateScore] = useState(false);
  useEffect(() => {
    if (values.entrance_type !== "GATE") {
      setDisableGateRank(true);
      setDisableGateScore(true);
    } else {
      setDisableGateRank(false);
      setDisableGateScore(false);
    }
    console.log();
  }, [values.entrance_type]);
  const view = (
    <React.Fragment>
      <SubHeading heading="Admission Details" />
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="reg_type"
            component={TextInput}
            label="Admission For"
            type="text"
            disabled={true}
            required={true}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field name="academic_year"
            component={SelectInput}
            label="ACADEMIC YEAR"
            required={true}
            options={sharedData && sharedData.academic_year ? sharedData.academic_year : []}
            optionKey="name" />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="course_id"
            component={SelectInput}
            label="Course Opted"
            required={true}
            options={sharedData && sharedData.department_list ? sharedData.department_list : []}
            optionKey="name" />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="admission_type"
            component={SelectInput}
            label="Quota"
            required={true}
            options={sharedData && sharedData.admission_type ? sharedData.admission_type : []}
            optionKey="value" />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="adm_type"
            component={SelectInput}
            label="Type"
            options={["Regular", "Readmission"]}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="admission_category"
            component={SelectInput}
            label="Admitted Reservation Category"
            options={sharedData && sharedData.admission_category ? sharedData.admission_category : []}
            optionKey="value" />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="fee_conces_eligibility"
            component={SelectInput}
            label="Fee Concesion Eligibility"
            required={true}
            options={sharedData && sharedData.fee_conces_eligibility ? sharedData.fee_conces_eligibility : []}
            optionKey="value" />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="income_category"
            component={SelectInput}
            label="Income Category"
            required={true}
            options={["APL", "BPL"]}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="entrance_type"
            component={SelectInput}
            label="Entrance Type"
            required={true}
            options={["GATE", "Non GATE", "Sponsered"]}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="btech_gate_rank"
            component={TextInput}
            label="Gate Rank"
            required={true}
            type="number"
            disabled={disbaleGateRank}
            placeholder="GATE RANK"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="btech_gate_score"
            component={TextInput}
            label="Gate Score"
            type="number"
            required={true}
            disabled={disableGateScore}
            placeholder="GATE SCORE"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="community"
            component={SelectInput}
            label="Community"
            required={true}
            options={sharedData && sharedData.community ? sharedData.community : []}
            optionKey="value" />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="nativity"
            component={SelectInput}
            label="Nativity"
            options={["Keralite", "Non-Keralite"]}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <Field
            name="qualification"
            component={SelectInput}
            label="Qualification"
            options={sharedData && sharedData.qualification ? sharedData.qualification : []}
            optionKey="value" />
        </div>
      </div>
    </React.Fragment>
  );
  return view;
};
