import React from 'react';

import './StatusBar.scss';
import ProgressBar from 'react-bootstrap/ProgressBar';
import AppConstants from "../../../AppContants";

const status = AppConstants.registration.status;

export const StatusBar = ({value})=>{
    const view = (
        <div className="et-data-bar pb-3" >
                    {value && value === status.studentJustRegistered && (
                        <ProgressBar animated variant="danger" now={17} label={value}/>
                    )}
                    {value && value === status.studentVerified && (
                        <ProgressBar animated variant="warning" now={34} label={value}/>
                    )}
                    {value && value === status.studentBatchAssigned && (
                        <ProgressBar animated variant="warning" now={51} label={value}/>
                    )}
                    {value && value === status.studentAdmitted && (
                        <ProgressBar animated variant="info" now={68} label={value}/>
                    )}
                    {value && value === status.studentFeePaid && (
                        <ProgressBar animated variant="info" now={85} label={value}/>
                    )}
                    {value && value === status.studentActive && (
                        <ProgressBar variant="success" now={100} label={value}/>
                    )}
        </div>

    );    
    return view;
};